export const normalizeCountryName = (country: string) => {
  const countryMap: any = {
    '419': 'Latin America',
    'AE': 'United Arab Emirates',
    'AT': 'Austria',
    'AU': 'Australia',
    'AZ': 'Azerbaijan',
    'AR': 'Argentina',
    'BE': 'Belgium',
    'BR': 'Brazil',
    'CA': 'Canada',
    'CH': 'Switzerland',
    'CL': 'Chile',
    'CO': 'Colombia',
    'DK': 'Denmark',
    'EC': 'Ecuador',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'HN': 'Honduras',
    'IN': 'India',
    'IT': 'Italy',
    'JO': 'Jordan',
    'JP': 'Japan',
    'MX': 'Mexico',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'PE': 'Peru',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PY': 'Paraguay',
    'RU': 'Russia',
    'ES': 'Spain',
    'EN': 'English',
    'SE': 'Sweden',
    'TR': 'Turkey',
    'UK': 'United Kingdom',
    'US': 'United States',
    'UY': 'Uruguay',
    'VE': 'Venezuela',
    'ZA': 'South Africa',
    'Buenos Aires': 'Argentina',
    'Buenos Aires Province': 'Argentina',
    'Córdoba Province': 'Argentina',
    'Chaco Province': 'Argentina',
    'San Luis Province': 'Argentina',
    'Capital Department': 'Argentina',
    'County Louth': 'Ireland',
    'Turkey': 'Turkey',
    'Türkiye': 'Turkey',
    'Kumkısık': 'Turkey',
    'Democratic Republic of the Congo': 'DR Congo',
    'GA': 'United States',
    'BC': 'Canada',
    'NSW': 'Australia',
    'NC': 'United States',
    'MO': 'Macau',
    'Yemen': 'Yemen',
    'null': 'Unknown'
  };

  return countryMap[country] || country;
};

export const countryNameMap = {
  ar: ['Argentina', 'argentina', 'san luis province', 'córdoba province', 'chaco province', 'capital department', 'buenos aires', 'buenos aires province'],
  es: ['Spain', 'spain', 'eses', 'España', 'españa', 'es'],
  fr: ['France', 'france', 'francefrance'],
  mx: ['Mexico', 'mexico', 'Ver.'],
  de: ['Germany', 'germany', 'germanygermany'],
  us: ['United States', 'USA'],
  tr: ['Turkey', 'Türkiye', 'Turkiye', 'turkey', 'TR', 'türkiye', 'tr', 'Kumkısık'],
  gb: ['United Kingdom', 'UK', 'united kingdom'],
  it: ['Italy', 'itit'],
  nl: ['Netherlands', 'nlnl'],
  nz: ['New Zealand', 'nznz'],
  ca: ['Canada', 'caca'],
  fi: ['Finland', 'fifi'],
  pl: ['Poland', 'plpl'],
  pt: ['Portugal', 'portugal'],
  br: ['Brazil', 'brbr', 'brazil'],
  au: ['Australia', 'auau'],
  se: ['Sweden', 'sese'],
  cl: ['Chile', 'clcl'],
  ve: ['Venezuela', 'veve'],
  be: ['Belgium', 'bebe'],
  ae: ['United Arab Emirates', 'uae', 'aeae'],
  uy: ['Uruguay', 'uyuy'],
  dk: ['Denmark', 'dkdk'],
  ru: ['Russia', 'russia', 'ruru'],
  no: ['Norway', 'nono'],
  jp: ['Japan', 'japan', 'jpjp'],
  ie: ['Ireland', 'ieie'],
  py: ['Paraguay', 'pypy'],
  ch: ['Switzerland', 'chch'],
  in: ['India', 'inin'],
  ph: ['Philippines', 'phph'],
  cn: ['China', 'china', 'ncnc'],
  jo: ['Jordan', 'jojo'],
  cd: ['Democratic Republic of the Congo', 'congo', 'democratic republic of the congo'],
  hn: ['Honduras', 'hnhn'],
  za: ['South Africa', 'south africa', 'zaza'],
  pe: ['Peru', 'pepe'],
  az: ['Azerbaijan', 'azaz'],
  bc: ['British Columbia', 'bcbc'],
  ga: ['Gabon', 'gaga'],
  drc: ['Democratic Republic of the Congo', 'drc'],
  mo: ['Morocco', 'momo'],
  ec: ['Ecuador', 'ecec'],
  co: ['Colombia', 'coco', 'caldas province'],
  ye: ['Yemen', 'yemen'],
  unknown: ['Unknown'],
  bb: ['Barbados'],
  en: ['English', 'english', 'en'],
};