import { appLanguages } from '../../common/languages';
import { getUsers } from './users';
import { getVoicesList } from './voices';

// Helper function to add a new voice entry
const addNewVoice = (voices, user, voiceLanguage) => {
  voices.push({
    name: user.voice,
    amount: 1,
    ratio: 0,
    language: voiceLanguage,
    male: user.gender === 'male' ? 1 : 0,
    female: user.gender === 'female' ? 1 : 0,
  });
};

// Helper function to update an existing voice entry
const sumToExistingVoice = (existingVoice, user) => {
  existingVoice.amount++;
  if (user.gender === 'male') {
    existingVoice.male++;
  } else if (user.gender === 'female') {
    existingVoice.female++;
  }
};

export const getVoicesStats = async () => {
  const limit = 10000;
  const { data: usersData } = await getUsers({
    filters: [],
    limit,
    size: limit,
    pageSize: limit,
  });

  const voicesData = await getVoicesList();
  const voices = [];

  usersData.forEach((user) => {
    if (user.voice) {
      let voiceLanguage = user.language;
      let found = false;

      // Check if the user's voice exists in their language
      if (voicesData[user.language]?.some((voice) => voice.name === user.voice)) {
        voiceLanguage = user.language;
        found = true;
      }

      // If not found, find the language where the voice actually exists
      if (!found) {
        for (const lang of appLanguages) {
          if (voicesData[lang]?.some((voice) => voice.name === user.voice)) {
            voiceLanguage = lang;
            found = true;
            break;
          }
        }
      }

      if (!found) {
        console.error(`Voice ${user.voice} not found in any language`);
        return;
      }

      let existingVoice = voices.find((voice) => voice.name === user.voice && voice.language === voiceLanguage);

      // if (user.voice.toLowerCase().includes('onyx')) {
      //   console.log('user.voice', user.voice, user.language, voiceLanguage, existingVoice, found);
      // }

      if (!existingVoice) {
        addNewVoice(voices, user, voiceLanguage);
      } else {
        // Check if voiceLanguage is user.language, if not, try to find voice in user language
        if (voiceLanguage !== user.language) {
          const voiceInUserLang = voicesData[user.language]?.find((voice) => voice.name.toLowerCase() === user.voice.toLowerCase());
          const specialVoice = user.voice.toLowerCase().includes('onyx');
          const voiceIsStored = voices.filter((voice) => voice.language === user.language && voice.name === user.voice).length > 0;

          if (voiceInUserLang || (specialVoice && !voiceIsStored)) {
            addNewVoice(voices, user, !specialVoice ? voiceLanguage : user.language);
          } else {
            specialVoice && console.log('Voice not found in user language', user.voice, user.language, voiceLanguage, voiceInUserLang);
            sumToExistingVoice(existingVoice, user);
          }
        } else {
          sumToExistingVoice(existingVoice, user);
        }
      }
    }
  });

  // Group voices by language and sort each group by amount in descending order
  const languageGroups = voices.reduce((acc, voice) => {
    if (!acc[voice.language]) {
      acc[voice.language] = [];
    }
    acc[voice.language].push(voice);
    return acc;
  }, {});

  // Sort each language group by amount in descending order and update the ratio
  Object.values(languageGroups).forEach((group) => {
    group.sort((a, b) => b.amount - a.amount);
    const totalVoicesInLanguage = group.reduce((acc, voice) => acc + voice.amount, 0);
    group.forEach((voice) => {
      voice.ratio = voice.amount / totalVoicesInLanguage;
    });
  });

  return languageGroups;
};
