import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_USERS,
  GET_USER_BY_ID
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  toggleUsersLoading,
  getUserSuccess,
  getUserFail
} from "./actions"
import { getUsers, getUserData } from "../../helpers/API/users";

function* fetchUsers({ payload }) {
  yield put(toggleUsersLoading())
  try {
    const response = yield call(getUsers, payload)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
  yield put(toggleUsersLoading())
}

function* fetchUserById({ payload }) {
  yield put(toggleUsersLoading())
  try {
    const response = yield call(getUserData, payload)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
  yield put(toggleUsersLoading())
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_BY_ID, fetchUserById)
}

export default usersSaga;
