import axios from 'axios';
import Config from '../../../config';
import { convertAudioToMP3 } from '../../API/content/googleFunctions';
import { VoiceTypeEnum } from '../../../types/voices';
import { EXERCISE_TYPE } from '../../../types/exercises';
import { getLanguageName } from '../../../common/languages';

export const processExerciseTextToAudio = async (
  exerciseText,
  language,
  exerciseId,
  useSSML = false,
  voiceName = "en-US-SteffanNeural",
  exerciseType = EXERCISE_TYPE.THEORY,
  audioDocRef,
  voiceType
) => {
  try {
    const result = await axios.post('https://europe-west3-nevilize-dev.cloudfunctions.net/exerciseTextToAudio', {
      exerciseText,
      language,
      exerciseId,
      voiceName,
      useSSML,
      exerciseType,
      audioDocRef,
      voiceType,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return result.data?.success;
  } catch (error) {
    console.error("Error processing exercise text to audio:", error);
    throw error;
  }
};

// For voice recording
export const fromAudioToMP3 = async (audioURL, userId) => {
  try {
    if (!audioURL || !userId) {
      console.error(`audioURL or userId is undefined. audioURL: ${audioURL}, userId: ${userId}`);
      return;
    }

    const result = await convertAudioToMP3({ audioUrl: audioURL, userId });
    const mp3URL = result?.data?.url;

    return mp3URL;
  } catch (error) {
    console.error('Error converting audio to MP3:', error);
    throw error;
  }
};

export const regenerateExercise = async (
  exerciseType,
  exerciseText,
  language,
  exerciseId,
  voice
) => {
  try {
    const voiceName = voice.name;
    const voiceLocale = voice.locale;
    const voiceType = voice.type;
    const audioStyle = (voiceType === VoiceTypeEnum.ChatGPT || exerciseType === EXERCISE_TYPE.CRAFTED) ? 'meditation' : 'read';
    const response = await axios.post('https://europe-west3-nevilize-dev.cloudfunctions.net/ttsGenerate', {data: {
      inputText: [exerciseText],
      storeAudioPath: `${exerciseType}/${voiceLocale}/${exerciseId}/${voiceName}/`,
      storeAudioURLPath: `content/${exerciseType}/${language}/${exerciseId}/audios/${voiceName}`,
      voiceName,
      type: 'exercise',
      language,
      audioStyle,
      voiceType,
    }}, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error regenerating exercise:", error);
    throw error;
  }
};

export const generateAudioChatGPT = async (text, languageCode, name) => {
  const apiKey = Config.chatGPTToken;
  const url = 'https://api.openai.com/v1/audio/speech';
  const headers = {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
  };
  const language = getLanguageName(languageCode);
  const data = {
    model: 'tts-1',
    input: text,
    voice: name || 'onyx',
    response_format: 'mp3',
    language,
  };

  try {
    const response = await axios.post(url, data, { headers, responseType: 'blob' });
    if (response.status === 200) {
      return response.data;  // Return the blob
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error generating audio:', error);
    throw new Error('Failed to generate audio');
  }
};
