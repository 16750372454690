import { db } from '../firebase';
import { doc, getDoc, collection, where, getDocs, query } from 'firebase/firestore';
import axios from 'axios';
import Config from "../../config";
// import * as admin from 'firebase-admin';
// import { Expo } from 'expo-server-sdk';

export const getUserByEmailOrId = async ({ email, id }) => {
  let userQuery;

  if (id) {
    userQuery = doc(db, 'users', id);
    const userSnapshot = await getDoc(userQuery);
    if (userSnapshot.exists()) {
      return { id: userSnapshot.id, ...userSnapshot.data() };
    }
  } else if (email) {
    userQuery = query(collection(db, 'users'), where('email', '==', email));
    const querySnapshot = await getDocs(userQuery);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { id: userDoc.id, ...userDoc.data() };
    }
  }

  return null;
};

// Function to generate notification content using ChatGPT
export async function fulfillNotificationWithChatGPT(prompt) {
  const openaiUrl = 'https://api.openai.com/v1/chat/completions';
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Config.chatGPTToken}`,
  };

  const messages = [
    {
      role: "system",
      content: "You are a helpful assistant. Generate a notification with a title and body based on the following prompt:",
    },
    {
      role: "user",
      content: prompt,
    }
  ];

  const payload = {
    model: Config.chatGPTModel,
    messages: messages,
  };

  try {
    const response = await axios.post(openaiUrl, payload, { headers });
    const chatContent = response.data.choices[0].message.content;

    const { title, body } = JSON.parse(chatContent);

    return { title, body };
  } catch (error) {
    console.error('Error fulfilling notification with ChatGPT:', error);
    throw new Error('ChatGPT communication failed');
  }
}

// Function to translate the content to the target language
export async function translateNotificationContent(title, body, targetLanguage) {
  const openaiUrl = 'https://api.openai.com/v1/chat/completions';
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Config.chatGPTToken}`,
  };

  const messages = [
    {
      role: "system",
      content: `Translate the following title and body to ${targetLanguage.toUpperCase()} and return them as JSON.`,
    },
    {
      role: "user",
      content: JSON.stringify({ title, body }),
    }
  ];

  const payload = {
    model: "gpt-4",
    messages: messages,
  };

  try {
    const response = await axios.post(openaiUrl, payload, { headers });
    const chatContent = response.data.choices[0].message.content;

    // Parse the response to get both translated title and body
    const { title: translatedTitle, body: translatedBody } = JSON.parse(chatContent);

    return { title: translatedTitle, text: translatedBody }; // Return both title and text
  } catch (error) {
    console.error('Error translating notification content:', error);
    throw new Error('Translation via ChatGPT failed');
  }
}

export const sendNotification = async (userId, tokens, notificationContent) => {
  console.log('Sending notification to:', userId, tokens, notificationContent);
  // call to client-side new function to send user notifications
};
