import React, { useEffect, useState, useCallback } from 'react';
import { getContentWithAudios } from '../../helpers/API/content';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import { appLanguages } from '../../common/languages';
import { langFlags } from '../../common/langFlags';
import RefreshButton from '../../components/Common/RefreshButton';
import LocalButton from '../../components/Common/Button';
import NewContentForm from './NewContentForm';
import { regenerateExercise } from '../../helpers/API/content/audio';
import { EXERCISE_TYPE } from '../../types/exercises';

const ContentBlock = ({ voices }) => {
  const [content, setContent] = useState({ theory: {}, training: {} });
  const [isLoading, setIsLoading] = useState({});
  const [activeTab, setActiveTab] = useState('theory');
  const [activeSubTab, setActiveSubTab] = useState('en');
  const [collapse, setCollapse] = useState([]);
  const [addingContent, setAddingContent] = useState(false);
  const [generatingAudio, setGeneratingAudio] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchContent = useCallback(async (type, language) => {
    setIsLoading(prev => ({ ...prev, [`${type}-${language}`]: true }));
    const data = await getContentWithAudios(type, language);
    setContent(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [language]: data.length ? data : [], // Ensure we return an empty array if no data
      },
    }));
    setIsLoading(prev => ({ ...prev, [`${type}-${language}`]: false }));
  }, []);

  useEffect(() => {
    if (!content[activeTab][activeSubTab]) {
      fetchContent(activeTab, activeSubTab);
    }
  }, [activeTab, activeSubTab, fetchContent, content]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleSubTab = (subTab) => {
    if (activeSubTab !== subTab) setActiveSubTab(subTab);
  };

  const toggleCollapse = (index) => {
    setCollapse(collapse => {
      const newCollapse = [...collapse];
      newCollapse[index] = !newCollapse[index];
      return newCollapse;
    });
  };

  const handleRegenerateAudio = async (item, voice) => {
    try {
      setGeneratingAudio(voice.name);
      const text = item.text.join(' ');
      const idOFExercise = item.type === EXERCISE_TYPE.THEORY ? item.day : item.id;
      await regenerateExercise(activeTab, text, activeSubTab, idOFExercise, voice);
      fetchContent(activeTab, activeSubTab);
    } catch (error) {
      console.error('Error regenerating audio sample:', error);
      setErrorMessage('Error regenerating audio sample.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setGeneratingAudio(null);
    }
  };

  const currentContent = content[activeTab]?.[activeSubTab] || [];

  return (
    <div className="white-block flex-2">
      <h2>Content</h2>
      <LocalButton
        text="Add content"
        icon="bx bx-plus"
        extraClass={addingContent ? 'active' : ''}
        onClick={() => setAddingContent(!addingContent)}
      />
      <RefreshButton
        isLoading={isLoading[`${activeTab}-${activeSubTab}`]}
        onClick={() => fetchContent(activeTab, activeSubTab)}
      />
      {addingContent ? (
        <NewContentForm content={content} />
      ) : (
        <>
          <Nav tabs>
            {['theory', 'training'].map(tab => (
              <NavItem key={tab}>
                <NavLink
                  className={classnames({ active: activeTab === tab })}
                  onClick={() => toggleTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {['theory', 'training'].map(tab => (
              <TabPane key={tab} tabId={tab}>
                <Nav tabs>
                  {appLanguages.map(lang => (
                    <NavItem key={lang}>
                      <NavLink
                        className={classnames({ active: activeSubTab === lang })}
                        onClick={() => toggleSubTab(lang)}
                      >
                        <img
                          src={langFlags[lang]}
                          alt={`${lang} flag`}
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '5px'
                          }}
                        />
                        {lang.charAt(0).toUpperCase() + lang.slice(1)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeSubTab}>
                  {appLanguages.map(lang => (
                    <TabPane key={lang} tabId={lang} className="scrollable-tabpane">
                      {isLoading[`${activeTab}-${lang}`] ? (
                        <div className="loading-container">
                          <div className="spinner-border text-primary"></div>
                        </div>
                      ) : (
                        content[activeTab]?.[lang] && content[activeTab][lang].length > 0 ? content[activeTab][lang].map((item, index) => (
                          <Card key={index}>
                            <CardHeader onClick={() => toggleCollapse(index)} className={collapse[index] ? 'active' : ''}>
                              <span className="bold">{item.day || item.id}. </span>
                              {item.title}
                            </CardHeader>
                            <Collapse isOpen={collapse[index]}>
                              <CardBody>
                                {item.text.map((paragraph, i) => (
                                  <p key={i}>{paragraph}</p>
                                ))}
                                <div className="audio-options">
                                  {voices[activeSubTab]?.map((voice, voiceIndex) => {
                                    const audio = item.audios.find(a => a.id === voice.name);
                                    return (
                                      <div key={voiceIndex} className="audio-item">
                                        <p><strong>Voice:</strong> {voice.name}</p>
                                        {audio ? (
                                          <>
                                            <p><strong>Duration:</strong> {audio.audioDuration ? `${audio.audioDuration.toFixed(2)} s.` : 'N/A'}</p>
                                            <audio controls src={audio.audioURL}></audio>
                                          </>
                                        ) : (
                                          <p>No audio available</p>
                                        )}
                                        <Button onClick={() => {
                                          console.log('Regenerating audio for:', item, index);
                                          handleRegenerateAudio(item, voice);
                                        }} color="primary">
                                          {generatingAudio === voice.name ? <div className="spinner-border spinner-border-sm text-light"></div> : (audio ? 'Regenerate' : 'Generate')}
                                        </Button>
                                        {errorMessage && (
                                          <div className="error-message">{errorMessage}</div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card>
                        )) : (
                          <div className="loading-container">
                            <p>No content available.</p>
                          </div>
                        )
                      )}
                    </TabPane>
                  ))}
                </TabContent>
              </TabPane>
            ))}
          </TabContent>
        </>
      )}
    </div>
  );
};

export default ContentBlock;
