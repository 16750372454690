import React, { useMemo, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import LoadingIndicator from "./Loading";
import GlobalFilter from "./GlobalFilter";
import RefreshButton from '../../components/Common/RefreshButton';
import { formatNumber } from "../../utils/numbers";

const TableContainer = ({
  columns,
  data,
  refreshData,
  isLoading,
  isGlobalFilter,
  isAddUserList,
  handleUserClick,
  className,
  customPageSizeOptions,
  sortBy,
  filters,
  pageSize,
  pageIndex,
  totalPages,
  totalItems,
  setPageSize,
  setPageIndex,
  setSortBy,
  setFilters,
  onRowClick,
  }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setAllFilters,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    state: { filters: stateFilters, sortBy: stateSortBy },
  } = useTable(
    {
      columns,
      data,
      isLoading,
      defaultColumn: { Filter: DefaultColumnFilter },
      pageCount: totalPages,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        filters: filters || [],
        pageIndex: pageIndex || 0,
        pageSize: pageSize || 100,
        sortBy: sortBy && sortBy[0] ? [{
          id: sortBy[0] && Object.keys(sortBy[0])[0],
          desc: sortBy[0] && sortBy[0][Object.keys(sortBy[0])[0]] === -1,
        }] : [],
      },
      getRowProps: (rowInfo) => {
          console.log('rowInfo 111', rowInfo);
          if (onRowClick) {
          console.log('rowInfo', rowInfo.row.original);
          return {
            onClick: () => {
              onRowClick(rowInfo.row.original);
            },
            style: {
              cursor: 'pointer',
            },
          };
        } else {
          return {};
        }
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const thePageIndex = pageIndex + 1;
  const canPreviousPage = thePageIndex > 1;
  const canNextPage = pageIndex < totalPages - 1;

  const resetFilters = () => {
    setAllFilters([]);

    allColumns.forEach(column => {
      column.setFilter(undefined)
    });

    setGlobalFilter(undefined);
    refreshData();
  };

  const isFilterActive = useMemo(() => {
    return stateFilters && stateFilters.length > 0;
  }, [stateFilters]);

  useEffect(() => {
    const fetchSortedData = async () => {
      if (!sortBy || !sortBy.length || !stateSortBy[0]) return;

      const prevSortId = Object.keys(sortBy[0])[0];
      const prevSortDesc = Object.values(sortBy[0])[0];
      const sortBy2 = [{
        id: prevSortId,
        desc: prevSortDesc === -1,
      }];

      if (JSON.stringify(sortBy2) !== JSON.stringify(stateSortBy)) {
        setSortBy(stateSortBy);
      }
    };

    fetchSortedData();
  }, [stateSortBy]);

  useEffect(() => {
    const fetchFilteredData = async () => {
      if (JSON.stringify(filters) !== JSON.stringify(stateFilters)) {
        setFilters(stateFilters);
      }
    };

    fetchFilteredData();
  }, [state.filters]);

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const newIndex = event.target.value ? Number(event.target.value) : 0;
    setPageIndex(newIndex);
  };

  const tableKey = `table-${data ? data.length : 111}-${pageIndex}`;

  return (
    <Fragment key={tableKey}>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 4}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[100, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <Col md="4">
          <div className="text-sm-end">
            {totalItems ? (
              <span className="me-2 total-items-badge">
                Rows: <span>{formatNumber(totalItems)}</span>
              </span>
            ) : null}
            <RefreshButton onClick={refreshData} extraClass="me-2 gap-2" isLoading={isLoading} />
            {isFilterActive && (
              <Button
                color="secondary"
                className="white-button"
                onClick={resetFilters}
                disabled={!isFilterActive}
              >
                <i className="mdi mdi-filter-remove me-1" />
                Reset Filters
              </Button>
            )}
          </div>
        </Col>
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    className="cursor-pointer row-hover"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row.original.id);
                      }
                    }}>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            }))}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setPageIndex(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {thePageIndex} of {totalPages}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={totalPages}
            defaultValue={thePageIndex || 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={() => setPageIndex(pageIndex + 1)} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => setPageIndex(totalPages - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
