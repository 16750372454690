import React from 'react';

const Button = ({
  onClick,
  text = 'Refresh',
  extraClass = '',
  icon = 'bx bx-refresh',
  isLoading = false
}) => {
  return (
    <button
      className={`white-button ${extraClass} localButton`}
      onClick={onClick}>
      {isLoading ? <div className="spinner"></div> : (
        <>
          <i className={`${icon} me-1`}></i>
          {text}
        </>
      )}
    </button>
  );
}

export default Button;