import React, { useState } from 'react';
import LocalButton from '../../components/Common/Button';
import AddSentenceBlock from './NewSentence';
import CurrentSentences from './CurrentSentences';

const Assistant = () => {
  const [addingSentence, setAddingSentence] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshSentences = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div className='page-content'>
      <div className="button-row">
        <LocalButton
          text="Add a Sentence"
          icon="bx bx-plus"
          extraClass={`addSentence ${addingSentence ? 'active' : ''}`}
          onClick={() => setAddingSentence(!addingSentence)}
        />
      </div>
      <div className="content-row">
        <CurrentSentences key={refreshKey} />
        {addingSentence && <AddSentenceBlock refreshSentences={refreshSentences} />}
      </div>
    </div>
  );
};

export default Assistant;
