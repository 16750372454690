import React, { useEffect, useState } from "react";
import { getVoicesStats } from "../../helpers/API/statsVoices";
import { langFlags } from "../../common/langFlags";
import RefreshButton from "../../components/Common/RefreshButton";

const formatVoiceName = (name) => {
  name = name.replace("Neural", "");

  // Remove everything before the last hyphen
  const lastHyphenIndex = name.lastIndexOf('-');
  if (lastHyphenIndex !== -1) {
    name = name.substring(lastHyphenIndex + 1);
  }

  return name.trim();
};

const VoiceStats = () => {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getVoicesStats();
    setStats(data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading || Object.keys(stats).length === 0) {
    return (
      <div className="page-content">
        <div className="spinner-border text-primary"></div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="stats-container voices">
        <RefreshButton onClick={fetchData} extraClass="refresh" isLoading={isLoading} />
        {Object.keys(stats).map((language, langIndex) => (
          <div
            key={langIndex}
            className="stats-block"
            style={{ minWidth: 350 }}
          >
            {/* <div
            className="stats-block-bg"
              style={{ backgroundImage: `url(${langFlags[language]})` }}
            ></div> */}
            <img
              src={langFlags[language]}
              alt={`${language} flag`}
              style={{ width: "30px", height: "30px" }}
            />
            {stats[language].map((stat, index) => (
              <div key={index} className="stats-item">
                <label className="stats-label">
                  {index + 1}. {formatVoiceName(stat.name)}
                </label>
                <p className="stats-value">
                  {stat.amount}
                  {stat.ratio && (
                    <span className="stats-ratio"> ({(stat.ratio * 100).toFixed(2)}%)</span>
                  )}
                  <span className="stats-value pink">
                    <i className="bx bx-female" style={{ marginLeft: "10px" }}></i>
                    {stat.female}
                  </span>
                  <span className="stats-value blue">
                    <i className="bx bx-male" style={{ marginLeft: "10px" }}></i>
                    {stat.male}
                  </span>
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default VoiceStats;