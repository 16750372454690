export const getTotalSpent = (items: any[]) => {
  const total = items.reduce((sum, item) => {
    const priceStr = item.price.replace(/[^\d,.-]/g, '');
    const price = parseFloat(priceStr.replace(',', '.'));
    return sum + price;
  }, 0);

  // Format total with commas for thousands and keep two decimal places
  const formattedTotal = total.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Get the currency symbol from the first item
  const currencySymbol = items.length > 0 ? items[0].price.replace(/[\d,.-]/g, '').trim() : '';

  return `${currencySymbol}${formattedTotal}`;
};
