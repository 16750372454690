import React, { useEffect, useState } from 'react';
import {
  Alert,
  InputGroup,
  InputGroupText,
  Input,
  Button
} from 'reactstrap';

const NewContentForm = ({ content }) => {
  const [contentType, setContentType] = useState('training');
  const [id, setId] = useState('');
  const [day, setDay] = useState(content.theory.en.length + 1);
  const [imageUrl, setImageUrl] = useState('');
  const [text, setText] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (contentType === 'training') {
      setId('');
    } else {
      setId(1);
    }
  }, [contentType]);

  const isFormValid = () => {
    return imageUrl !== '' && text !== '' && title !== '';
  };

  // When sending the system should add: lang and type by default

  return (
    <div className="addContent">
      <InputGroup>
        <InputGroupText addonType="prepend">Type</InputGroupText>
        <Input type="select" name="type" id="typeSelect" value={contentType} onChange={e => setContentType(e.target.value)}>
          <option>training</option>
          <option>theory</option>
        </Input>
      </InputGroup>
      <Alert color="primary" style={{ marginTop: 5, padding:' 5px 15px' }}>
        Content will be added by default to language <b>EN</b>. Later the audio needs to be generated and then translated to all other languages.
      </Alert>
      {contentType === 'theory' && (
        <InputGroup>
          <InputGroupText addonType="prepend">Day</InputGroupText>
          <Input placeholder="Day" value={day} disabled />
        </InputGroup>
      )}
      <InputGroup>
        <InputGroupText addonType="prepend">ID</InputGroupText>
        <Input placeholder="ID" value={id} onChange={e => setId(e.target.value)} disabled={contentType === 'theory'} />
      </InputGroup>
      <InputGroup>
        <InputGroupText addonType="prepend">Image URL</InputGroupText>
        <Input placeholder="Image URL" />
      </InputGroup>
      <InputGroup>
        <InputGroupText addonType="prepend">Title</InputGroupText>
        <Input placeholder="Title" />
      </InputGroup>
      <InputGroup>
        <InputGroupText addonType="prepend">Text</InputGroupText>
        <Input type="textarea" placeholder="Text" onChange={e => setText(e.target.value.split('\n'))} />
      </InputGroup>
      <Button
        style={{ width: '100%', marginTop: 20 }}
        disabled={!isFormValid()}
        onClick={() => {
          setAddingContent(false)}
        }>
          <i className="bx bx-plus"></i>
          Add
      </Button>
    </div>
  );
};

export default NewContentForm;