import React, { useState, useEffect } from 'react';
import Config from '../../config';
import { getApps, getApp } from 'firebase/app';
import ValidationBadge from '../../components/Common/ValidationBadge';
import { langFlags } from '../../common/langFlags';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { translateAllContent, generateAllAudioForOneLanguage } from '../../helpers/API/content/adminTasks';
import { translateNotificationsToOneLanguage, fetchNotificationsByLang } from '../../helpers/API/notifications';
import { db } from '../../helpers/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { getVoices, getContent, getContentWithAudios } from '../../helpers/API/content';
import { translateAssistantMessagesToOneLanguage } from '../../helpers/API/assistant';

const currentApp = getApps()[0] || getApp();
const currentApiKey = currentApp.options.apiKey;

const isUsingFirebaseDev = currentApiKey === Config.firebaseConfig_DEV.apiKey;

const StatusBlock = ({ status, text }) => (
  <div className="status-block">
    <h4>{text}</h4>
    <ValidationBadge
      isValid={status === 'valid'}
      disabled={!text}
      isLoading={status === 'loading'}
    />
  </div>
);

const AddNewLanguage = () => {
  const [languageToAdd, setLanguageToAdd] = useState('');
  const [usingFBDev, setUsingFBDev] = useState(isUsingFirebaseDev);
  const [langFlagExist, setLangFlagExist] = useState(false);
  const [langVoiceExist, setLangVoiceExist] = useState(false);
  const [voices, setVoices] = useState({});
  const [voicesLoading, setVoicesLoading] = useState(true);
  const [validLang, setValidLang] = useState(false);
  const [content, setContent] = useState({});
  const [contentLoading, setContentLoading] = useState(true);
  const [contentWithAudios, setContentWithAudios] = useState({});
  const [contentWithAudiosLoading, setContentWithAudiosLoading] = useState(true);
  const [commercialNotis, setCommercialNotis] = useState({});
  const [commercialNotisEN, setCommercialNotisEN] = useState({});
  const [commercialNotisLoading, setCommercialNotisLoading] = useState(true);

  // Specific actions
  const [translatingContent, setTranslatingContent] = useState(false);
  const [generatingAudios, setGeneratingAudios] = useState(false);
  const [translatingNotis, setTranslatingNotis] = useState(false);
  const [translatingAssistant, setTranslatingAssistant] = useState(false);
  const [contentStatus, setContentStatus] = useState('');
  const [contentText, setContentText] = useState('');
  const [audioStatus, setAudioStatus] = useState('');
  const [audioText, setAudioText] = useState('');
  const [notisStatus, setNotisStatus] = useState('');
  const [notisText, setNotisText] = useState('');
  const [assistantStatus, setAssistantStatus] = useState('');
  const [assistantText, setAssistantText] = useState('');

  const fetchVoices = async () => {
    setVoicesLoading(true);
    const data = await getVoices();
    setVoices(data);
    setVoicesLoading(false);
  };

  const fetchContent = async () => {
    setContentLoading(true);
    const data = await getContent();
    setContent(data);
    setContentLoading(false);
  };

  const fetchContentWithAudios = async () => {
    setContentWithAudiosLoading(true);
    const data = await getContentWithAudios(languageToAdd);
    setContentWithAudios(data);
    setContentWithAudiosLoading(false);
  };

  const fetchCommercialNotis = async () => {
    setCommercialNotisLoading(true);
    const dataEN = await fetchNotificationsByLang('en');
    const data = await fetchNotificationsByLang(languageToAdd);

    console.log('Commercial notifications', dataEN, data);
    setCommercialNotisEN(dataEN);
    setCommercialNotis(data);
    setCommercialNotisLoading(false);
  };

  const refetchAll = () => {
    fetchVoices();
    fetchContent();
  };

  useEffect(() => {
    refetchAll();
  }, []);

  useEffect(() => {
    if (languageToAdd.length === 2) {
      fetchContentWithAudios();
      fetchCommercialNotis();
      setLangFlagExist(!!langFlags[languageToAdd]);
      setLangVoiceExist(voices[languageToAdd]);
      checkTranslatedContent(languageToAdd);
      checkCommercialNotifications(languageToAdd);
      checkAssistantTranslations(languageToAdd);
    } else {
      setLangVoiceExist(false);
      setLangFlagExist(false);
      resetStatuses();
    }
  }, [languageToAdd]);

  useEffect(() => {
    if (usingFBDev && langFlagExist) {
      setValidLang(true);
    } else {
      setValidLang(false);
    }
  }, [usingFBDev, langFlagExist]);

  const checkTranslatedContent = async (lang) => {
    if (content.training && content.theory) {
      const rootTheoryLength = content.theory.en.length;
      const rootTrainingLength = content.training.en.length;

      if (!content.theory[lang] || !content.training[lang]) {
        setContentStatus('invalid');
        setContentText('No content for this language');
        return;
      }

      const theoryLength = content.theory[lang].length;
      const trainingLength = content.training[lang].length;
      const status = theoryLength === rootTheoryLength && trainingLength === rootTrainingLength;
      const statusText = status ? 'valid' : 'invalid';
      const text = `${theoryLength}/${rootTheoryLength} TH, ${trainingLength}/${rootTrainingLength} TR`;

      setContentStatus(statusText);
      setContentText(text);
    }
  };

  const checkCommercialNotifications = (lang) => {
    const status = commercialNotisEN.length === commercialNotis.length ? 'valid' : 'invalid';
    const text = `${commercialNotis.length || 0} of ${commercialNotisEN.length} notifications translated`;

    setNotisStatus(status);
    setNotisText(text);
  };

  const checkAssistantTranslations = async (lang) => {
    const sentencesCollectionRef = collection(db, 'assistant', 'en', 'sentences');
    const sentencesSnapshot = await getDocs(sentencesCollectionRef);

    if (!sentencesSnapshot.empty) {
      const sentenceIds = sentencesSnapshot.docs.map(doc => doc.id);
      let translatedCount = 0;

      for (const id of sentenceIds) {
        const targetDocRef = doc(db, `assistant/${lang}/sentences/${id}`);
        const targetDocSnapshot = await getDoc(targetDocRef);

        if (targetDocSnapshot.exists() && targetDocSnapshot.data().text) {
          translatedCount++;
        }
      }

      const status = translatedCount === sentenceIds.length ? 'valid' : 'invalid';
      const text = `${translatedCount} of ${sentenceIds.length} sentences translated`;

      setAssistantStatus(status);
      setAssistantText(text);
    }
  };

  const resetStatuses = () => {
    setContentStatus('');
    setContentText('');
    setAudioStatus('');
    setAudioText('');
    setNotisStatus('');
    setNotisText('');
    setAssistantStatus('');
    setAssistantText('');
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleItem = (index) => {
    setSelectedItems(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const items = [
    { text: 'Enable firebase DEV' },
    { text: 'Add a flag: flag on the app, and on the admin' },
    {
      text: 'Add a voice for the new language:',
      links: [
        { text: 'create the sampleURL' },
        { text: 'here', url: 'https://speech.microsoft.com/portal/63420f9fb854460b987c5d7151040371/audiocontentcreation/file?voiceId=dce86327-e98a-4995-89e5-d216d456ec6f&languageCode=en-US' },
        { text: 'store it', url: 'https://console.firebase.google.com/u/0/project/nevilize-dev/storage/nevilize-dev.appspot.com/files/~2Fvoices' }
      ]
    },
    { text: 'Translate the app content json file to new lang (use chatGPT API?)' },
    { text: 'Use the controls here to translate all content' }
  ];

  return (
    <>
      <div className="white-block">
        <h2>Instructions</h2>
        <div className="white-block">
          <ListGroup>
            {items.map((item, index) => (
              <ListGroupItem
                key={index}
                onClick={() => toggleItem(index)}
                style={{
                  backgroundColor: selectedItems.includes(index) ? 'green' : 'white',
                  color: selectedItems.includes(index) ? 'white' : 'black',
                  cursor: 'pointer'
                }}>
                {index + 1}. {item.text} {item.links && item.links.map((link, linkIndex) => link.url ? (
                  <a
                    key={linkIndex}
                    href={link.url}
                    style={{
                      color: selectedItems.includes(index) ? 'yellow' : 'blue',
                      margin: '0 5px'
                    }}>{link.text}</a>
                ) : link.text)}
                {selectedItems.includes(index) && (
                  <div className="icon bx bx-check me-2"></div>
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </div>
      <div className="white-block add-language">
        <div className="new-language-config-top">
          <div className="new-language-title">
            <h2>Add New Language</h2>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="language"
                value={languageToAdd}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 2) {
                    setLanguageToAdd(value);
                  }
                }}
              />
              <button
                disabled={voicesLoading || languageToAdd.length !== 2}
                onClick={() => setLanguageToAdd(document.getElementById('language').value)}
                className="btn btn-primary mt-3">
                {voicesLoading ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <>
                    <span className="icon bx bx-plus"></span>
                    Add
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="new-language-block">
            <div className="new-language-config-item">
              <h3>Use firebase DEV</h3>
              <ValidationBadge isValid={usingFBDev} />
            </div>
            <div className="new-language-config-item">
              <h3>Add a voice</h3>
              <ValidationBadge
                isValid={langVoiceExist}
                disabled={!languageToAdd}
              />
            </div>
            <div className="new-language-config-item">
              <h3>Add a flag</h3>
              <ValidationBadge
                isValid={langFlagExist}
                disabled={!languageToAdd}
              />
            </div>
          </div>
        </div>
        <div className={`new-language-actions ${!validLang ? 'disabled' : ''}`}>
          <div className="new-language-block">
            <div className="new-language-action">
              <h3>Translate Theory & Training</h3>
              <StatusBlock
                status={contentLoading ? 'loading' : contentStatus}
                text={contentText}
              />
              <Button
                color="primary"
                outline
                disabled={!validLang || translatingContent || contentStatus === 'valid'}
                onClick={async () => {
                  setTranslatingContent(true);
                  console.log('Translating content', languageToAdd);
                  await translateAllContent(languageToAdd);
                  setTranslatingContent(false);
                  checkTranslatedContent(languageToAdd);
                }}
              >
                {translatingContent ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <>
                    <i className="bx bx-book-content me-2"></i>
                    Translate
                  </>
                )}
              </Button>
            </div>
          </div>
          <div className="new-language-block">
            <div className="new-language-action">
              <h3>Create audios (Theory & Training)</h3>
              <StatusBlock
                status={contentWithAudiosLoading ? 'loading' : audioStatus}
                text={audioText}
              />
              <Button
                color="primary"
                outline
                disabled={!validLang || generatingAudios || audioStatus === 'valid'}
                onClick={async () => {
                  setGeneratingAudios(true);
                  await generateAllAudioForOneLanguage(languageToAdd);
                  setGeneratingAudios(false);
                  await fetchContentWithAudios();
                  checkAudioStatus();
                }}
              >
                {generatingAudios ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <>
                    <i className="bx bx-play me-2"></i>
                    Generate
                  </>
                )}
              </Button>
            </div>
          </div>
          <div className="new-language-block">
            <div className="new-language-action">
              <h3>Translate Commercial notifications</h3>
              <StatusBlock
                status={commercialNotisLoading ? 'loading' : notisStatus}
                text={notisText}
              />
              <Button
                color="primary"
                outline
                disabled={!validLang || translatingNotis || notisStatus === 'valid'}
                onClick={async () => {
                  setTranslatingNotis(true);
                  await translateNotificationsToOneLanguage(languageToAdd);
                  setTranslatingNotis(false);
                  await fetchCommercialNotis();
                  checkCommercialNotifications(languageToAdd);
                }}
              >
                {translatingNotis ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <>
                    <i className="bx bx-comment me-2"></i>
                    Translate
                  </>
                )}
              </Button>
            </div>
          </div>
          <div className="new-language-block">
            <div className="new-language-action">
              <h3>Translate Assistant sentences</h3>
              <StatusBlock
                status={commercialNotisLoading ? 'loading' : assistantStatus}
                text={assistantText}
              />
              <Button
                color="primary"
                outline
                disabled={!validLang || translatingAssistant || assistantStatus === 'valid'}
                onClick={async () => {
                  setTranslatingAssistant(true);
                  await translateAssistantMessagesToOneLanguage(languageToAdd);
                  setTranslatingAssistant(false);
                  checkAssistantTranslations(languageToAdd);
                }}
              >
                {translatingAssistant ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <>
                    <i className="bx bx-comment me-2"></i>
                    Translate
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewLanguage;
