import React from 'react';
import { Input } from 'reactstrap';
import { appLanguages } from '../../common/languages';

const genders = ['male', 'female'];

export const Filter = ({ column }) => (
  <div style={{ marginTop: 5 }}>
    {column.canFilter && column.render('Filter')}
  </div>
);

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows: { length } }
}) => (
  <Input
    value={filterValue || ''}
    onChange={(e) => setFilter(e.target.value)}
    placeholder={`search (${length}) ...`}
  />
);

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, filterOptions }
}) => {
  const options = React.useMemo(() => {
    if (filterOptions) {
      return filterOptions;
    } else if (id === 'language') {
      return appLanguages;
    } else if (id === 'gender') {
      return genders;
    } else {
      const options = new Set();
      preFilteredRows.forEach(row => {
        if (row.values[id] != null) {
          options.add(row.values[id]);
        }
      });
      return [...options.values()];
    }
  }, [id, preFilteredRows]);

  return (
    <select
      id='custom-select'
      className="form-select"
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

/**
 * Filters rows for an exact match.
 * @param {Array} rows - The rows to filter.
 * @param {String} id - The column ID.
 * @param {String} filterValue - The value to filter by.
 * @returns {Array} - The filtered rows.
 */
export const filterForExactMatch = (rows, id, filterValue) =>
  rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue !== undefined ? String(rowValue) === String(filterValue) : true;
  });

export const SelectColumnPositiveFilter = ({ column: { filterValue, setFilter } }) => (
  <select
    id='custom-select'
    className="form-select"
    value={filterValue || ''}
    onChange={(e) => setFilter(e.target.value || undefined)}
  >
    <option value=''>All</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
);

/**
 * Filters rows to show only those with positive numbers.
 * @param {Array} rows - The rows to filter.
 * @param {String} id - The column ID.
 * @param {String} filterValue - The value to filter by.
 * @returns {Array} - The filtered rows.
 */
export const filterForPositiveNumber = (rows, id, filterValue) =>
  rows.filter(row => {
    const rowValue = row.values[id];
    const isPositive = rowValue > 0;
    if (filterValue === "Yes") {
      return isPositive;
    } else if (filterValue === "No") {
      return !isPositive;
    }
    return true;
  });

/**
 * Filters rows to show only those with existing items.
 * @param {Array} rows - The rows to filter.
 * @param {String} id - The column ID.
 * @param {String} filterValue - The value to filter by.
 * @returns {Array} - The filtered rows.
 */
export const filterForExisItem = (rows, id, filterValue) =>
  rows.filter(row => {
    const rowValue = row.values[id];
    const exist = !!rowValue;
    if (filterValue === "Yes") {
      return exist;
    } else if (filterValue === "No") {
      return !exist;
    }
    return true;
  });

  export const filterForCurrentDay = (rows, id, filterValue) => {
    if (!Array.isArray(rows)) {
      console.error('Expected rows to be an array, but got:', rows);
      return [];
    }

    if (filterValue !== "Yes") {
      return rows; // No filtering applied if filterValue is not "Yes"
    }

    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0); // Start of the current day

    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999); // End of the current day

    return rows.filter(row => {
      const rowValue = row.values[id];

      // Convert Firebase Timestamp to JavaScript Date
      const dateValue = rowValue instanceof Object && 'seconds' in rowValue && 'nanoseconds' in rowValue
        ? new Date(rowValue.seconds * 1000 + rowValue.nanoseconds / 1000000)
        : new Date(rowValue);

      // Ensure the date is valid and within the current day
      if (isNaN(dateValue.getTime())) {
        console.warn('Invalid Date:', rowValue);
        return false;
      }

      const isCurrentDay = dateValue >= startOfDay && dateValue <= endOfDay;
      return isCurrentDay;
    });
  };


  export const SelectCurrentDay = ({ column: { filterValue, setFilter } }) => {
    return (
      <select
        id='custom-select'
        className="form-select"
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value === '' ? undefined : e.target.value)}
      >
        <option value=''>All</option>
        <option value="Yes">Current Day</option>
      </select>
    );
  };

