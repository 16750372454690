import React from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom"

const Dashboard = props => {
  document.title = "Dashboard | Nevilize admin"; //meta title

  const links = {
    Business: [{
      title: "Apple store",
      bgColor: "bg-dark",
      iconClass: "mdi-apple-ios",
      description: "iOS application",
      link: "https://developer.apple.com/account"
    },
    {
      title: "Google store",
      bgColor: "bg-success",
      iconClass: "mdi-google-play",
      description: "Android application",
      link: "https://play.google.com/console/u/3/developers/8303295937659750199/app/4973175564244712202/app-dashboard?timespan=thirtyDays"
    },
    {
      title: "Gmail",
      bgColor: "bg-warning",
      iconClass: "mdi-gmail",
      description: "info@nevilize.com mailbox",
      link: "https://mail.google.com/mail/u/3/#inbox"
    },
    {
      title: "Google Drive",
      bgColor: "bg-secondary",
      iconClass: "mdi-google-drive",
      description: "Shared documents",
      link: "https://drive.google.com/drive/u/3/my-drive"
    },
    {
      title: "Google domains",
      bgColor: "bg-success",
      iconClass: "mdi-google",
      description: "nevilize.com",
      link: "https://domains.google.com/registrar/?_ga=2.148658929.711794159.1677599090-1862353219.1677453388&authuser=3"
    }],
    SocialAndMarketing: [{
      title: "Instagram",
      bgColor: "bg-danger",
      iconClass: "mdi-instagram",
      description: "Instagram nevilize profile",
      link: "https://www.instagram.com/nevilize/"
    },
    {
      title: "Buffer",
      bgColor: "bg-dark",
      iconClass: "mdi-instagram",
      description: "Social Media",
      link: "https://publish.buffer.com/profile/63ebbb2766085f615e5e8735/tab/queue"
    }],
    Development: [{
      title: "Google Firebase",
      bgColor: "bg-warning",
      iconClass: "mdi-google",
      description: "Authentication and chats",
      link: "https://console.firebase.google.com/u/0/project/nevilize/overview"
    },
    {
      title: "Google cloud",
      bgColor: "bg-info",
      iconClass: "mdi-google-cloud",
      description: "API keys for notifications",
      link: "https://console.cloud.google.com/apis/credentials?project=nevilize"
    },
    {
      title: "Expo",
      bgColor: "bg-warning",
      iconClass: "mdi-apple",
      description: "Mobile APP development and deploy",
      link: "https://expo.dev/"
    },
    {
      title: "Github",
      bgColor: "bg-info",
      iconClass: "mdi-github",
      description: "Source code for app, admin and web",
      link: "https://github.com/santii5?tab=repositories"
    }],
    Others: [{
      title: "Upwork",
      bgColor: "bg-success",
      iconClass: "mdi-wikipedia",
      description: "BE developer",
      link: "https://www.upwork.com/ab/messages/rooms/?sidebar=true"
    },
    {
      title: "Fiverr",
      bgColor: "bg-success",
      iconClass: "mdi-fedora",
      description: "Designer, copy, content manager...",
      link: "https://es.fiverr.com/"
    }]
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {Object.keys(links).map((category) => (
                <div key={category}>
                  <h3>{category.replace(/([A-Z])/g, ' $1').trim()}</h3>
                  <Row className="mt-4 mb-4">
                    {links[category].map((link, key) => (
                      <Col className="col-12 col-md-4" key={"_li_" + key}>
                        <a href={link.link} rel="noreferrer" target="_blank" className="dashboardLink">
                          <div className="link-source mt-3 flex flex-row">
                            <div className="avatar-xs mb-3 mr-3">
                              <span
                                className={
                                  "avatar-title rounded-circle " +
                                  link.bgColor +
                                  " font-size-16"
                                }
                              >
                                <i
                                  className={"mdi " + link.iconClass + " text-white"}
                                ></i>
                              </span>
                            </div>
                            <div className="ml-6">
                              <h5 className="font-size-15">{link.title}</h5>
                              <p className="text-muted mb-0">{link.description}</p>
                            </div>
                          </div>
                        </a>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Dashboard);
