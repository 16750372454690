import React, { useState, useEffect } from 'react';
import { Button, InputGroup, InputGroupText, Input, FormFeedback } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { appLanguages, localeMap } from '../../common/languages';
import { voiceTypes } from '../../common/voiceTypes';
import { addVoiceToLanguage } from '../../helpers/API/content/voices';

const SelectField = ({ id, label, options, formik, onChange }) => (
  <InputGroup className="inputGroupStyle">
    <InputGroupText addonType="prepend">{label}</InputGroupText>
    <Input
      id={id}
      type="select"
      {...formik.getFieldProps(id)}
      onChange={(e) => {
        formik.setFieldValue(id, e.target.value);
        formik.setFieldTouched(id, true, false);
        if (onChange) onChange(e);
      }}
      invalid={formik.touched[id] && formik.errors[id] ? true : false}
    >
      <option value="">Select {label}</option>
      {options.map((option, index) => (
        <option key={index} value={option.value || option}>
          {option.label || option}
        </option>
      ))}
    </Input>
    {formik.touched[id] && formik.errors[id] ? (
      <FormFeedback>{formik.errors[id]}</FormFeedback>
    ) : null}
  </InputGroup>
);

const NewVoiceForm = ({ fetchVoices }) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const formik = useFormik({
    initialValues: {
      language: '',
      customLanguage: '',
      displayName: '',
      name: '',
      locale: '',
      customLocale: '',
      wordsPerMinute: '',
      sampleAudioURL: '',
      gender: '',
      voiceType: '',
      category: 'FREE',
    },
    validationSchema: Yup.object({
      language: Yup.string().required('Required'),
      customLanguage: Yup.string()
        .when('language', {
          is: 'custom',
          then: Yup.string()
            .length(2, 'Must be exactly 2 characters')
            .matches(/^[a-z]{2}$/i, 'Must be exactly 2 letters')
            .required('Required'),
        }),
      displayName: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      locale: Yup.string().required('Required'),
      customLocale: Yup.string()
        .when('locale', {
          is: 'custom',
          then: Yup.string()
            .length(2, 'Must be exactly 2 characters')
            .matches(/^[a-z]{2}$/i, 'Must be exactly 2 letters')
            .required('Required'),
        }),
      wordsPerMinute: Yup.number().required('Required'),
      sampleAudioURL: Yup.string().url('Invalid URL'),
      gender: Yup.string().required('Required'),
      voiceType: Yup.string().required('Required'),
      category: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const voice = {
        displayName: values.displayName,
        name: values.name,
        locale: values.locale === 'custom' ? values.customLocale : values.locale,
        wordsPerMinute: values.wordsPerMinute,
        sampleAudioURL: values.sampleAudioURL,
        gender: values.gender,
        type: values.voiceType,
        category: values.category,
      };

      try {
        await addVoiceToLanguage(values.language === 'custom' ? values.customLanguage : values.language, voice);
        await fetchVoices();
        resetForm();
        setErrorMessage('');
      } catch (error) {
        console.error("Error adding voice:", error);
        setErrorMessage("Failed to add voice. Please try again.");
      } finally {
        setSubmitting(false); // Ensure this is set to false to re-enable the button
      }
    },
    validateOnBlur: true, // Set to true to validate on blur
    validateOnChange: true, // Set to true to validate on change
  });

  const renderInputGroup = (id, label, type = 'text', placeholder = '', disabled = false) => (
    <InputGroup className="inputGroupStyle">
      <InputGroupText addonType="prepend">{label}</InputGroupText>
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...formik.getFieldProps(id)}
        invalid={formik.touched[id] && formik.errors[id] ? true : false}
      />
      {formik.touched[id] && formik.errors[id] ? (
        <FormFeedback>{formik.errors[id]}</FormFeedback>
      ) : null}
    </InputGroup>
  );

  return (
    <form onSubmit={formik.handleSubmit} className="addVoice">
      <SelectField
        id="language"
        label="Language"
        options={[...appLanguages.map(lang => ({ value: lang, label: lang })), { value: 'custom', label: 'Custom' }]}
        formik={formik}
        onChange={(e) => {
          formik.setFieldValue('locale', '');
          formik.setFieldValue('customLocale', '');
          formik.setFieldValue('customLanguage', '');
        }}
      />
      {formik.values.language === 'custom' && renderInputGroup('customLanguage', 'Custom Language', 'text', 'Custom Language', false)}
      <SelectField
        id="voiceType"
        label="Voice Type"
        options={voiceTypes.map(type => ({ value: type, label: type }))}
        formik={formik}
      />
      {renderInputGroup('displayName', 'Display Name')}
      {renderInputGroup('name', 'Name')}
      <SelectField
        id="locale"
        label="Locale"
        options={localeMap[formik.values.language] ? localeMap[formik.values.language].map(loc => ({ value: loc, label: loc })) : [{ value: 'custom', label: 'Custom' }]}
        formik={formik}
        onChange={(e) => {
          formik.setFieldValue('customLocale', '');
        }}
      />
      {formik.values.locale === 'custom' && renderInputGroup('customLocale', 'Custom Locale', 'text', 'Custom Locale', false)}
      {renderInputGroup('wordsPerMinute', 'Words per minute', 'number')}
      {renderInputGroup('sampleAudioURL', 'Sample Audio URL')}
      <SelectField
        id="gender"
        label="Gender"
        options={[
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
          { value: 'custom', label: 'Custom' }
        ]}
        formik={formik}
      />
      <SelectField
        id="category"
        label="Category"
        options={[
          { value: 'FREE', label: 'FREE' },
          { value: 'PRO', label: 'PRO' }
        ]}
        formik={formik}
      />
      <Button
        type="submit"
        className="addButton"
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        style={{ width: '100%' }}
      >
        <i className="bx bx-plus"></i>
        Add
      </Button>
      {errorMessage && (
        <div className="errorMessage">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default NewVoiceForm;
