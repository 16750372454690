import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, setDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { generateAudioChatGPT } from './content/audio';
import { translateText } from './content/text';
import { textSamples } from '../../constants/voices';
import { ttsGenerate } from './content/googleFunctions';

const storage = getStorage();

export const fetchSentences = async (lang) => {
  const sentences = {};
  const assistantCollection = collection(db, 'assistant');

  const sentencesCollection = collection(assistantCollection, lang, 'sentences');
  const snapshot = await getDocs(sentencesCollection);
  sentences[lang] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  return sentences[lang];
};

// Function to save a new sentence to Firestore
export const saveSentence = async (sentence, lang, sentenceId) => {
  if (!sentenceId) {
    throw new Error('Sentence ID cannot be empty.');
  }

  const sentencesCollection = collection(db, 'assistant', lang, 'sentences');
  const newDocRef = doc(sentencesCollection, sentenceId);

  await setDoc(newDocRef, {
    ...sentence,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    lang: lang,
    id: sentenceId
  });
};

export const generateAndStoreSentenceAudio = async (text, lang, sentenceId) => {
  try {
    const audioBlob = await generateAudioChatGPT(text, lang, 'alloy');
    const storageRef = ref(storage, `assistant/sentences/${lang}/${sentenceId}.mp3`);
    await uploadBytes(storageRef, audioBlob);
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error('Error generating and storing audio:', error);
    throw new Error('Failed to generate and store audio');
  }
};

export const regenerateVoiceSample = async (voice, language) => {
  const normalizedLanguage = language.toUpperCase();
  const voiceSampleText = textSamples[normalizedLanguage]?.replace('[voiceName]', voice.displayName) || `Hey, my name is ${voice.displayName}. It's an honor for me to be your manifesting assistant. I am looking forward to walking with you through the whole process and achieving big things together.`;

  const requestData = {
    inputText: voiceSampleText,
    storeAudioPath: `voices/${language}/${voice.name}/samples`,
    storeAudioURLPath: `voices/${language}/list/${voice.name}`,
    voiceName: voice.name,
    type: 'voiceSample',
    language,
    audioStyle: voice.audioStyle || null, // Adjust this if needed
    voiceType: voice.type, // Ensure the type is in uppercase to match the enum
  };

  try {
    const response = await ttsGenerate(requestData);

    const { fileUrl, duration } = response.data;

    // Update the voice document in Firestore
    const voiceDocRef = doc(db, 'voices', language, 'list', voice.name);
    await updateDoc(voiceDocRef, { sampleAudioURL: fileUrl, duration });

    console.log('Voice sample regenerated successfully');
  } catch (error) {
    console.error('Error regenerating voice sample:', error);
    throw error;
  }
};

export const translateAssistantMessagesToOneLanguage = async (language) => {
  const sentencesCollectionRef = collection(db, 'assistant/en/sentences');
  const sentencesSnapshot = await getDocs(sentencesCollectionRef);

  if (!sentencesSnapshot.empty) {
    const sentenceDocs = sentencesSnapshot.docs;

    for (const sentenceDoc of sentenceDocs) {
      const sentenceData = sentenceDoc.data();
      const sentenceId = sentenceDoc.id;
      const targetDocRef = doc(db, `assistant/${language}/sentences/${sentenceId}`);
      const targetDocSnapshot = await getDoc(targetDocRef);

      if (!targetDocSnapshot.exists() || !targetDocSnapshot.data().text) {
        console.log(`Translating sentence: "${sentenceData.text}"`);
        const translations = await translateText(sentenceData.text, [language]);

        if (!translations[language]) {
          console.error(`Translation failed for sentence ID: ${sentenceId}`);
          continue;
        }

        const translatedSentence = {
          ...sentenceData,
          text: translations[language],
          lang: language,
          createdAt: sentenceData.createdAt || serverTimestamp(),
          updatedAt: serverTimestamp(),
          audioURL: ''
        };

        await setDoc(targetDocRef, translatedSentence);

        // Break after processing the first sentence for testing
        // break;
      } else {
        console.log(`Sentence "${sentenceId}" already exists in ${language}, skipping translation.`);
      }
    }
  } else {
    console.log('Source document does not exist.');
  }
};
