import { db } from '../firebase';
import { collection, doc, getDocs } from 'firebase/firestore';
import { appLanguages } from '../../common/languages';

export const getContent = async () => {
  const contentCollection = collection(db, 'content');

  const theory = {};
  const training = {};

  for (const lang of appLanguages) {
    const theoryLangCollection = collection(doc(contentCollection, 'theory'), lang);
    const trainingLangCollection = collection(doc(contentCollection, 'training'), lang);

    const theoryLangSnapshot = await getDocs(theoryLangCollection);
    const trainingLangSnapshot = await getDocs(trainingLangCollection);

    theory[lang] = theoryLangSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    training[lang] = trainingLangSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  return { theory, training };
}

export const getVoices = async () => {
  const voicesData = {};

  for (const lang of appLanguages) {
    try {
      const langCollection = collection(db, 'voices', lang, 'list');
      const langSnapshot = await getDocs(langCollection);

      voicesData[lang] = langSnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error(`Error fetching voices for language ${lang}:`, error);
      voicesData[lang] = [];
    }
  }

  return voicesData;
};

const fetchAudios = async (section, exerciseId, language) => {
  try {
    const audiosRef = collection(db, 'content', section, language, exerciseId, 'audios');
    const audiosSnapshot = await getDocs(audiosRef);
    if (audiosSnapshot.empty) return [];
    return audiosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error(`Error fetching audios for section=${section}, exerciseId=${exerciseId}, language=${language}:`, error);
    return [];
  }
};

const getAudiosForExercises = async (section, exercises, language) => {
  if (!exercises) return [];
  return Promise.all(exercises.map(async (exercise) => {
    const exerciseId = section === 'theory' ? exercise.day : exercise.id;
    const audios = await fetchAudios(section, exerciseId.toString(), language);
    return { ...exercise, audios };
  }));
};

export const getContentWithAudios = async (type, language) => {
  try {
    const contentCollection = collection(db, `content/${type}/${language}`);
    const contentSnapshot = await getDocs(contentCollection);
    const content = contentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    if (!content) return [];
    return await getAudiosForExercises(type, content, language);
  } catch (error) {
    console.error(`Error fetching content with audios for type=${type}, language=${language}:`, error);
    return [];
  }
};



