import { db } from '../firebase';
import { collection, getDocs, doc } from 'firebase/firestore';
import { appLanguages } from '../../common/languages';
import { fetchAndFilterVoicesByLang } from './voices'; // Assuming voices.js contains the getVoicesList function

export const getRatings = async () => {
  const ratings = {};

  // Loop through each language in the predefined list
  for (const language of appLanguages) {
    const voicesData = {};

    try {
      // Fetch the list of voices for the current language
      const voicesList = await fetchAndFilterVoicesByLang(language);

      // Iterate over each voice in the list
      for (const voice of voicesList) {
        const voiceName = voice.name; // Assuming voiceName is a property in each voice document

        // Skip if voiceName is undefined
        if (!voiceName) {
          console.warn(`Voice name is undefined for language ${language}`);
          continue;
        }

        try {
          // Get the ratings collection for this specific voice
          const voiceRatingRef = collection(db, `ratings/${language}/${voiceName}`);
          const voiceRatingSnapshot = await getDocs(voiceRatingRef);

          // Check if there are documents in the voiceRatingSnapshot
          if (!voiceRatingSnapshot.empty) {
            const voiceRatings = {};
            voiceRatingSnapshot.forEach((doc) => {
              voiceRatings[doc.id] = doc.data();
            });
            voicesData[voiceName] = voiceRatings;
          }
        } catch (error) {
          console.error(`Failed to retrieve ratings for voice ${voiceName} in language ${language}: `, error);
          continue;
        }
      }

      if (Object.keys(voicesData).length > 0) {
        ratings[language] = voicesData;
      }

    } catch (error) {
      console.error(`Failed to retrieve voices for language ${language}: `, error);
      continue;
    }
  }

  return ratings;
};

export const getRatingByLanguage = async (language) => {
  const ratingsRef = collection(db, 'ratings', language, 'voices');
  const ratingsSnapshot = await getDocs(ratingsRef);

  const ratings = {};
  ratingsSnapshot.forEach((doc) => {
    ratings[doc.id] = doc.data();
  });

  return ratings;
};
