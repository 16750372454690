import React from 'react';

export const ColoredCell = ({ showFalse = true, value, color, textColor, style }) => {
  if (!showFalse && (!value || value === 'No' || value === 0)) {
    return null;
  }

  return (
    <div style={{
      backgroundColor: color,
      color: textColor,
      textAlign: 'center',
      borderRadius: '5px',
      padding: '5px',
      fontSize: '12px',
      ...style
    }}>
      {value}
    </div>
  )
};

export const CellSingleLine = ({ value }) => (
  <div className="single-line">{value}</div>
);

export const ShortenedIdCell = ({ value }) => {
  const shortened = value.length > 10 ? `${value.slice(0, 5)}...${value.slice(-5)}` : value;

  const copyToClipboard = () => navigator.clipboard.writeText(value);

  return (
    <div onClick={copyToClipboard} title="Click to copy" style={{ cursor: 'pointer', minWidth: '115px' }}>
      {shortened}
      <span style={{ marginLeft: '5px', color: 'blue' }}>📋</span>
    </div>
  );
};

export const CellThin = ({ value, style = {} }) => (
  <div
    className="thin-cell"
    style={{ overflow: 'hidden', maxWidth: 100, ...style }}
  >
    {value}
  </div>
);
