import React, { useState } from 'react';
import { formatDate } from '../../utils/dates';
import { langFlags } from '../../common/langFlags';
import { timeAgo } from '../../utils/dates';

function capitalize(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

const Realities = ({ user }) => {
  const [openReality, setOpenReality] = useState(user.realities.length === 1 ? 0 : null);

  const toggleReality = (index) => {
    setOpenReality(openReality === index ? null : index);
  };

  const renderIconForCreatedBy = (type) => {
    if (type?.toLowerCase() === 'speaking') {
      return <i className="fas fa-microphone" style={{ color: '#007bff', marginLeft: '0.5rem' }} title="Speaking"></i>;
    }
    if (type?.toLowerCase() === 'writing') {
      return <i className="fas fa-pencil-alt" style={{ color: '#007bff', marginLeft: '0.5rem' }} title="Writing"></i>;
    }
    return null;
  };

  return (
    <div className="third-section">
      <h2>Realities</h2>
      {Object.values(user.realities).map((reality, index) => {
        const isOpen = openReality === index;
        const isCurrentReality = reality.id === user.currentReality;

        return (
          <div key={index} className={`reality-item ${isCurrentReality ? 'current-reality' : ''}`}>
            <div className="reality-header" onClick={() => toggleReality(index)}>
              <strong>{reality.title}</strong>
              <div className="reality-meta">
                <span>{reality.currentDay}</span>
                {reality.createdBy && (
                  <p className="reality-createdBy">{renderIconForCreatedBy(reality.createdBy)}</p>
                )}
                <span className={`bold ${reality.isPaid ? 'purple-badge' : 'green-badge'}`}>{reality.isPaid ? 'PRO' : 'FREE'}</span>
                <span className={isCurrentReality ? 'green-badge' : 'red-badge'}>{isCurrentReality ? 'Current' : 'Inactive'}</span>
              </div>
              <span className={`toggle-icon ${isOpen ? 'open' : ''}`}>
                <i className={`bx ${isOpen ? 'bx-chevron-down' : 'bx-chevron-right'}`}></i>
              </span>
            </div>
            {isOpen && (
              <div className="reality-details">
                <div className="data-row">
                  <strong className="data-key">ID:</strong>
                  <p>{reality.id}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Created by:</strong>
                  <p>{capitalize(reality.createdBy)} {renderIconForCreatedBy(reality.createdBy)}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key" style={{ fontWeight: 'bold' }}>Current Day:</strong>
                  <p>{reality.currentDay}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Created At:</strong>
                  <p>{formatDate(reality.createdAt, true)} - <span className="green-badge">{timeAgo(reality.createdAt)}</span></p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Last Updated:</strong>
                  <p>{formatDate(reality.updatedAt, true)} - <span className="green-badge">{timeAgo(reality.updatedAt)}</span></p>
                </div>
                <div>
                  <strong className="data-key">Prompt 1:</strong>
                  <p>{reality.prompt1}</p>
                </div>
                <div>
                  <strong className="data-key">Prompt 2:</strong>
                  <p>{reality.prompt2}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Voice Display Name:</strong>
                  <p>{reality.voiceDisplayName}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Language:</strong>
                  <img
                    src={langFlags[reality.lang]}
                    alt={reality.lang}
                    title={reality.lang}
                    className="flag"
                  />
                </div>
                <div className="data-row">
                  <strong className="data-key">Is Paid:</strong>
                  <p className={reality.isPaid ? 'green-badge' : 'red-badge'}>{reality.isPaid ? 'Yes' : 'No'}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Reality Number:</strong>
                  <p>{reality.realityNumber}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Start Day:</strong>
                  <p>{reality.startDay}</p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Realities;
