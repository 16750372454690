import { createListQuery } from './firebase';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

export const getFeedback = async (params) => {
  const { data, ...props } = await createListQuery('feedback', params);
  return {
    data,
    ...props,
  };
};

export const editFeedbackStatus = async (feedbackId, newStatus) => {
  try {
    const feedbackDocRef = doc(db, 'feedback', feedbackId);
    await updateDoc(feedbackDocRef, { status: newStatus });
    console.log(`Status updated to ${newStatus} for feedback ID: ${feedbackId}`);
    return { success: true };
  } catch (error) {
    console.error('Error updating feedback status:', error);
    throw error;
  }
};

export const addFeedbackResponse = async (feedbackId, response) => {
  try {
    const feedbackDocRef = doc(db, 'feedback', feedbackId);
    await updateDoc(feedbackDocRef, { response });
    console.log(`Response added for feedback ID: ${feedbackId}`);
    return { success: true };
  } catch (error) {
    console.error('Error adding feedback response:', error);
    throw error;
  }
};

export const editFeedbackResponse = async (feedbackId, response) => {
  try {
    const feedbackDocRef = doc(db, 'feedback', feedbackId);
    await updateDoc(feedbackDocRef, { response });
    console.log(`Response updated for feedback ID: ${feedbackId}`);
    return { success: true };
  } catch (error) {
    console.error('Error editing feedback response:', error);
    throw error;
  }
};
