import { db } from '../firebase';
import { collection, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { appLanguages } from '../../common/languages';
import { sendPromptToChatGPT } from './content/chatGPT';

export const fetchNotifications = async ({
  type = 'commercial',
} = {}) => {
  const notifications = {};
  const notificationsCollection = collection(db, 'notifications');

  for (const lang of appLanguages) {
    const dataDocRef = doc(notificationsCollection, type, lang, 'data');
    const dataDocSnapshot = await getDoc(dataDocRef);

    if (dataDocSnapshot.exists()) {
      const data = dataDocSnapshot.data();
      notifications[lang] = (data.titles || []).map((title, index) => ({
        id: `${lang}-${index}`,
        title,
        body: data.bodies[index] || ''
      }));
    } else {
      notifications[lang] = [];
    }
  }

  return notifications;
};

export const fetchNotificationsByLang = async (lang, type = 'commercial') => {
  const dataDocRef = doc(db, 'notifications', type, lang, 'data');
  const dataDocSnapshot = await getDoc(dataDocRef);

  if (dataDocSnapshot.exists()) {
    const data = dataDocSnapshot.data();
    return (data.titles || []).map((title, index) => ({
      id: `${lang}-${index}`,
      title,
      body: data.bodies[index] || ''
    }));
  } else {
    return [];
  }
};

export const saveNotification = async (notification, lang, type = 'commercial') => {
  const dataDocRef = doc(db, 'notifications', type, lang, 'data');
  const dataDocSnapshot = await getDoc(dataDocRef);

  let titles = [];
  let bodies = [];

  if (dataDocSnapshot.exists()) {
    const data = dataDocSnapshot.data();
    titles = data.titles || [];
    bodies = data.bodies || [];
  }

  titles.push(notification.title);
  bodies.push(notification.body);

  await setDoc(dataDocRef, {
    titles,
    bodies,
    updatedAt: serverTimestamp()
  }, { merge: true });
};


export async function translateNotis(titles, bodies, targetLanguage) {
  const messages = [
    {
      role: "system",
      content: `Translate the following titles and bodies to ${targetLanguage} and format the response as JSON with "titles" and "bodies" as arrays of strings.`,
    },
    {
      role: "user",
      content: `Titles: ${titles.join('\n')}. Bodies:\n${bodies.join('\n')}`,
    }
  ];

  const translatedContent = await sendPromptToChatGPT(messages, "notifications");
  console.log(translatedContent);

  if (!translatedContent.titles || !translatedContent.bodies) {
    throw new Error('ChatGPT did not return the expected response');
  }

  return {
    titles: translatedContent.titles,
    bodies: translatedContent.bodies,
  };
}

const translateNotificationsForLanguage = async (language, type, sourceDocSnapshot) => {
  if (sourceDocSnapshot.exists()) {
    const contentData = sourceDocSnapshot.data();
    const { titles, bodies } = contentData;

    const translatedContent = await translateNotis(titles, bodies, language);

    const updatedContentData = {
      titles: translatedContent.titles,
      bodies: translatedContent.bodies,
      updatedAt: serverTimestamp()
    };

    const targetDocRef = doc(db, `notifications/${type}/${language}/data`);
    const targetDocSnapshot = await getDoc(targetDocRef);

    if (targetDocSnapshot.exists()) {
      const targetData = targetDocSnapshot.data();
      if (
        targetData.titles.length === titles.length &&
        targetData.bodies.length === bodies.length
      ) {
        console.log(`Notification data for ${language} in type ${type} is already up to date. Skipping translation.`);
        return;
      }
    }

    await setDoc(targetDocRef, updatedContentData);
    console.log(`Copied and translated notification to ${language} successfully for type ${type}.`);
  } else {
    console.log('Source document does not exist.');
  }
};

export const translateNotificationsToOneLanguage = async (language, type = 'commercial', sourceDocSnapshot = null) => {
  if (!sourceDocSnapshot) {
    const sourceDocRef = doc(db, `notifications/${type}/en/data`);
    sourceDocSnapshot = await getDoc(sourceDocRef);
  }

  await translateNotificationsForLanguage(language, type, sourceDocSnapshot);
};

export const translateNotifications = async () => {
  const types = ['commercial']; // Add other types as needed
  for (const type of types) {
    const sourceDocRef = doc(db, `notifications/${type}/en/data`);
    const sourceDocSnapshot = await getDoc(sourceDocRef);

    if (sourceDocSnapshot.exists()) {
      for (const language of appLanguages) {
        await translateNotificationsToOneLanguage(language, type, sourceDocSnapshot);
      }
    } else {
      console.log(`Source document for ${type} does not exist.`);
    }
  }
};
