import React, { useState } from 'react';
import LocalButton from '../../components/Common/Button';
import AddNotification from './AddNotification';
import CommercialNotifications from './CommercialNotifications';
import { translateNotifications } from '../../helpers/API/notifications';
import { Spinner } from 'reactstrap';

const Notifications = () => {
  const [addingNotification, setAddingNotification] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [translating, setTranslating] = useState(false);

  const refreshNotifications = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleTranslateAll = async () => {
    setTranslating(true);
    try {
      await translateNotifications();
    } catch (error) {
      console.error('Error translating notifications:', error);
    }
    setTranslating(false);
    refreshNotifications();
  };

  return (
    <div className='page-content'>
      <div className="button-row d-flex justify-content-between align-items-center">
        <LocalButton
          text="Add a Notification"
          icon="bx bx-plus"
          extraClass={`addNotification ${addingNotification ? 'active' : ''}`}
          onClick={() => setAddingNotification(!addingNotification)}
        />
        <LocalButton
          text="Translate All"
          icon="bx bx-globe"
          extraClass={`translateAll ${translating ? 'loading' : ''}`}
          onClick={handleTranslateAll}
          disabled={translating}
        />
      </div>
      <div className="content-row">
        {addingNotification && <AddNotification refreshNotifications={refreshNotifications} />}
        {translating ? (
          <div className="spinner-container">
            <Spinner color="primary" />
          </div>
        ) : (
          <CommercialNotifications key={refreshKey} />
        )}
      </div>
    </div>
  );
};

export default Notifications;
