export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";
export const TOGGLE_LOADING = "TOGGLE_LOADING";

export const EDIT_FEEDBACK_STATUS = "EDIT_FEEDBACK_STATUS";
export const EDIT_FEEDBACK_STATUS_SUCCESS = "EDIT_FEEDBACK_STATUS_SUCCESS";
export const EDIT_FEEDBACK_STATUS_FAIL = "EDIT_FEEDBACK_STATUS_FAIL";

export const ADD_FEEDBACK_RESPONSE = "ADD_FEEDBACK_RESPONSE";
export const ADD_FEEDBACK_RESPONSE_SUCCESS = "ADD_FEEDBACK_RESPONSE_SUCCESS";
export const ADD_FEEDBACK_RESPONSE_FAIL = "ADD_FEEDBACK_RESPONSE_FAIL";

export const EDIT_FEEDBACK_RESPONSE = "EDIT_FEEDBACK_RESPONSE";
export const EDIT_FEEDBACK_RESPONSE_SUCCESS = "EDIT_FEEDBACK_RESPONSE_SUCCESS";
export const EDIT_FEEDBACK_RESPONSE_FAIL = "EDIT_FEEDBACK_RESPONSE_FAIL";
