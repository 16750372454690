import { EXERCISE_TYPE } from '../../../types/exercises';
import { db } from '../../firebase';
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore';
import { appLanguages } from '../../../common/languages';

export const getVoicesList = async (lang) => {
  console.log("Getting voices list for language:", lang);
  try {
    const voicesCollection = collection(db, 'voices', lang, 'list');
    const snapshot = await getDocs(voicesCollection);

    // Check if snapshot is empty
    if (snapshot.empty) {
      console.log(`No voices found for language: ${lang}`);
      return [];
    }

    return snapshot.docs.map(doc => doc.data());
  } catch (error) {
    console.error(`Error getting voices list for language ${lang}:`, error);
    return [];
  }
};

export const getAllVoices = async () => {
  const voices = [];

  for (let lang of appLanguages) {
    const voiceCollection = collection(db, 'voices', lang, 'list');
    const voiceSnapshot = await getDocs(voiceCollection);
    voices.push(...voiceSnapshot.docs.map(doc => doc.data()));
  }

  return voices;
}

export const listVoicesByLanguage = async (lang) => {
  const voiceCollection = collection(db, 'voices', lang, 'list');
  const voiceSnapshot = await getDocs(voiceCollection);
  const voices = voiceSnapshot.docs.map(doc => doc.data());

  return voices;
}

export const getVoiceDetailsByName = async (name) => {
  for (let lang of appLanguages) {
    const voiceCollection = collection(db, 'voices', lang, 'list');
    const voiceQuery = query(voiceCollection, where('name', '==', name));
    const voiceSnapshot = await getDocs(voiceQuery);

    if (!voiceSnapshot.empty) {
      return voiceSnapshot.docs[0].data();
    }
  }
  return null;
}

export const updateVoiceOnExercises = async (userId, realityId, newVoice) => {
  const exercisesRef = collection(db, 'users', userId, 'exercises');
  const exerciseWithRealityIdQuery = query(exercisesRef, where('realityId', '==', realityId));
  const exercisesSnapshot = await getDocs(exerciseWithRealityIdQuery);

  for (const exerciseDoc of exercisesSnapshot.docs) {
    const exerciseToUpdateRef = doc(db, 'users', userId, 'exercises', exerciseDoc.id);
    const exerciseData = exerciseDoc.data();

    if (exerciseData.type !== EXERCISE_TYPE.CRAFTED && exerciseData.type !== EXERCISE_TYPE.CORE) {
      await updateDoc(exerciseToUpdateRef, { voice: newVoice });
    }
  }
};

// Not calling to /days functions to avoid circular dependency issues
export const updateVoiceOnDayTasks = async (userId, realityId, newVoice) => {
  try {
    // Reference to the 'days' subcollection
    const daysCollectionRef = collection(db, 'users', userId, 'realities', realityId, 'days');
    const daysSnapshot = await getDocs(daysCollectionRef);

    if (daysSnapshot.empty) {
      console.warn(`No days found for reality ${realityId} of user ${userId}.`);
      return;
    }

    // Iterate over each day document
    daysSnapshot.forEach(async (dayDoc) => {
      const dayData = dayDoc.data();
      const updatedTasks = dayData.tasks.map((task) => {
        if (task.type !== EXERCISE_TYPE.CRAFTED && task.type !== EXERCISE_TYPE.CORE) {
          return { ...task, voice: newVoice, updatedAt: serverTimestamp() };
        }
        return task;
      });

      // Update the tasks for each day
      const dayRef = doc(db, 'users', userId, 'realities', realityId, 'days', dayDoc.id);
      await updateDoc(dayRef, {
        tasks: updatedTasks,
        updatedAt: serverTimestamp(),
      });
    });

    console.log("Voice updated successfully for all tasks.");
  } catch (error) {
    console.error("Error updating voice on day tasks:", error);
  }
};

export const addVoiceToLanguage = async (lang, voice) => {
  try {
    const voiceCollection = collection(db, 'voices', lang, 'list');
    const voiceDocRef = doc(voiceCollection, voice.name);
    await setDoc(voiceDocRef, voice);
    console.log(`Voice ${voice.name} added successfully to ${lang}.`);
  } catch (error) {
    console.error(`Error adding voice to ${lang}:`, error);
  }
}

export const updateVoiceSampleURL = async (language, voiceName, sampleAudioURL) => {
  const voiceDocRef = doc(db, 'voices', language, 'list', voiceName);
  await updateDoc(voiceDocRef, { sampleAudioURL });
  console.log(`Sample Audio URL for ${voiceName} updated successfully in ${language}.`);
};

export const removeVoice = async (language, voiceName) => {
  const voiceDocRef = doc(db, 'voices', language, 'list', voiceName);
  await deleteDoc(voiceDocRef);
  console.log(`Voice ${voiceName} removed successfully from ${language}.`);
};
