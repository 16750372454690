import React, { useState } from 'react';
import { getUserByEmailOrId, sendNotification, fulfillNotificationWithChatGPT, translateNotificationContent } from '../../helpers/API/pushNotifications';
import { appLanguages } from '../../common/languages';
import { allFlags } from '../../common/allFlags';
import { langFlags } from '../../common/langFlags';

const PushNotifications = () => {
  const [userSearch, setUserSearch] = useState({ id: '', email: '' });
  const [userInfo, setUserInfo] = useState(null);
  const [notificationContent, setNotificationContent] = useState({ title: '', text: '' });
  const [translations, setTranslations] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [loadingState, setLoadingState] = useState({ search: false, fulfill: false, translate: false, send: false });
  const [errorMessages, setErrorMessages] = useState({ search: '', fulfill: '', translate: '', send: '' });
  const [validated, setValidated] = useState(false);

  const handleSearch = async () => {
    setLoadingState(prevState => ({ ...prevState, search: true }));
    try {
      const user = await getUserByEmailOrId(userSearch);
      if (user) {
        setUserInfo(user);
      } else {
        setErrorMessages(prevState => ({ ...prevState, search: 'User not found' }));
        setTimeout(() => setErrorMessages(prevState => ({ ...prevState, search: '' })), 5000);
      }
    } catch (error) {
      setErrorMessages(prevState => ({ ...prevState, search: 'Error fetching user' }));
      setTimeout(() => setErrorMessages(prevState => ({ ...prevState, search: '' })), 5000);
    } finally {
      setLoadingState(prevState => ({ ...prevState, search: false }));
    }
  };

  const handleInputChange = (field, value) => {
    setUserSearch(prevState => ({
      ...prevState,
      [field]: value,
      ...(field === 'id' ? { email: '' } : { id: '' }),
    }));
  };

  const handleNotificationChange = (field, value) => {
    setNotificationContent(prevState => ({ ...prevState, [field]: value }));
    setValidated(false);  // Reset validated state when input changes
  };

  const handleTranslationChange = (lang, value) => {
    setTranslations(prevState => ({ ...prevState, [lang]: value }));
    setValidated(false);  // Reset validated state when input changes
  };

  const validateTranslation = () => {
    if (translations[selectedLanguage]?.title && translations[selectedLanguage]?.text) {
      setIsFormValid(true);
      setValidated(true);  // Set validated state to true
    }
  };

  const handleFulfillWithChatGPT = async () => {
    setLoadingState(prevState => ({ ...prevState, fulfill: true }));
    try {
      const prompt = "Explain the purpose of this notification and provide any additional context.";
      const generatedContent = await fulfillNotificationWithChatGPT(prompt);
      setNotificationContent(generatedContent);
    } catch (error) {
      setErrorMessages(prevState => ({ ...prevState, fulfill: 'Error generating notification' }));
      setTimeout(() => setErrorMessages(prevState => ({ ...prevState, fulfill: '' })), 5000);
    } finally {
      setLoadingState(prevState => ({ ...prevState, fulfill: false }));
    }
  };

  const handleTranslateWithChatGPT = async () => {
    setLoadingState(prevState => ({ ...prevState, translate: true }));
    try {
      if (selectedLanguage && notificationContent.title && notificationContent.text) {
        const translatedContent = await translateNotificationContent(notificationContent.title, notificationContent.text, selectedLanguage);
        handleTranslationChange(selectedLanguage, translatedContent);
        validateTranslation();
      }
    } catch (error) {
      setErrorMessages(prevState => ({ ...prevState, translate: 'Error translating content' }));
      setTimeout(() => setErrorMessages(prevState => ({ ...prevState, translate: '' })), 5000);
    } finally {
      setLoadingState(prevState => ({ ...prevState, translate: false }));
    }
  };

  const handleSendNotification = async () => {
    setLoadingState(prevState => ({ ...prevState, send: true }));
    try {
      if (isFormValid && userInfo) {
        await sendNotification(userInfo.id, userInfo.pushTokens, translations[selectedLanguage]);
        alert('Notification sent successfully!');
      }
    } catch (error) {
      setErrorMessages(prevState => ({ ...prevState, send: 'Failed to send notification' }));
      setTimeout(() => setErrorMessages(prevState => ({ ...prevState, send: '' })), 5000);
    } finally {
      setLoadingState(prevState => ({ ...prevState, send: false }));
    }
  };

  return (
    <div id="push-notifications-page" className="page-content">
      <div className="search-panel">
        <h3>Search User</h3>
        <div className="input-row">
          <input
            type="text"
            placeholder="Search by ID"
            value={userSearch.id}
            onChange={(e) => handleInputChange('id', e.target.value)}
          />
          <input
            type="text"
            placeholder="Search by Email"
            value={userSearch.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </div>
        <div className="button-group">
          <button onClick={handleSearch} disabled={loadingState.search || (!userSearch.id && !userSearch.email)}>
            {loadingState.search ? 'Loading...' : 'Search'}
          </button>
          {errorMessages.search && <p className="error-message">{errorMessages.search}</p>}
        </div>
        {userInfo && (
          <div className="user-info">
            <h4>User Info</h4>
            <p><b>ID:</b> {userInfo.id}</p>
            <p><b>Email:</b> {userInfo.email}</p>
            <p><b>Name:</b> {userInfo.name}</p>
            <p><b>Country:</b> {userInfo.country} - {allFlags[userInfo.country?.toLowerCase()] ? (
              <img src={allFlags[userInfo.country.toLowerCase()]} alt={userInfo.country} className="flag-icon-small" />
            ) : null}</p>
            <p><b>Language:</b> {userInfo.language} - {langFlags[userInfo.language.toLowerCase()] ? (
              <img src={langFlags[userInfo.language.toLowerCase()]} alt={userInfo.language} className="flag-icon-small" />
            ) : null}</p>
            <p><b>Push Token:</b> {userInfo.notifications?.pushTokens?.join(', ')}</p>
          </div>
        )}
      </div>
      <div className="notification-editor">
        <h3>Notification Creator</h3>
        <div className="notification-form">
          <input
            type="text"
            placeholder="Title (English)"
            value={notificationContent.title}
            onChange={(e) => handleNotificationChange('title', e.target.value)}
          />
          <textarea
            placeholder="Text (English)"
            value={notificationContent.text}
            onChange={(e) => handleNotificationChange('text', e.target.value)}
          ></textarea>
          <div className="button-group">
            <button className="blue-button" onClick={handleFulfillWithChatGPT} disabled={loadingState.fulfill}>
              {loadingState.fulfill ? 'Loading...' : 'Fulfill with ChatGPT'}
            </button>
            {errorMessages.fulfill && <p className="error-message">{errorMessages.fulfill}</p>}
            <button className="orange-button" onClick={() => setNotificationContent({ title: '', text: '' })}>Clear</button>
          </div>
        </div>

        <div className="translation-form">
          <h4>Translate Notification</h4>
          <div className="select-flag-wrapper">
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="" disabled>Select Language</option>
              {appLanguages.map(lang => (
                <option key={lang} value={lang}>{lang}</option>
              ))}
            </select>
            {selectedLanguage && (
              <img
                src={langFlags[selectedLanguage.toLowerCase()]}
                alt={selectedLanguage}
                className="flag-icon"
              />
            )}
          </div>

          {selectedLanguage && (
            <>
              <input
                type="text"
                placeholder={`Title (${selectedLanguage})`}
                value={translations[selectedLanguage]?.title || ''}
                onChange={(e) => handleTranslationChange(selectedLanguage, { title: e.target.value, text: translations[selectedLanguage]?.text || '' })}
              />
              <textarea
                placeholder={`Text (${selectedLanguage})`}
                value={translations[selectedLanguage]?.text || ''}
                onChange={(e) => handleTranslationChange(selectedLanguage, { title: translations[selectedLanguage]?.title || '', text: e.target.value })}
              ></textarea>
              <div className="button-group">
                <button className="blue-button" onClick={handleTranslateWithChatGPT} disabled={loadingState.translate}>
                  {loadingState.translate ? 'Loading...' : 'Translate with ChatGPT'}
                </button>
                {errorMessages.translate && <p className="error-message">{errorMessages.translate}</p>}
                <button className="green-button" onClick={validateTranslation}>Validate</button>
                <button className="orange-button" onClick={() => handleTranslationChange(selectedLanguage, { title: '', text: '' })}>Clear</button>
              </div>
            </>
          )}
        </div>
        <div className="submit-section">
          {validated && (
            <p>
              <i className="check-icon" /> Translation validated!
            </p>
          )}
          <button className="green-button" disabled={!isFormValid || loadingState.send} onClick={handleSendNotification}>
            {loadingState.send ? 'Sending...' : 'Send Notification'}
          </button>
        </div>
        {errorMessages.send && <p className="error-message">{errorMessages.send}</p>}
      </div>
    </div>
  );
};

export default PushNotifications;

