import React from 'react';

const RefreshButton = ({
  onClick,
  extraClass = '',
  isLoading = false
}) => {
  return (
    <button
      className={`white-button ${extraClass} refreshData`}
      onClick={onClick}>
      {isLoading ? <div className="spinner"></div> : (
        <>
          <i className="bx bx-refresh me-1"></i>
          Refresh
        </>
      )}
    </button>
  );
}

export default RefreshButton;