import React from 'react';

const ValidationBadge = ({
  isValid,
  disabled,
  isLoading
}) => {
  const icon = isValid ? 'bx bx-check' : 'bx bx-x';
  const status = isValid ? 'Valid' : 'Invalid';
  const classCSS = isLoading ? 'loading' : disabled ? 'disabled' : isValid ? 'valid' : 'invalid';

  return (
    <div className={`validation ${classCSS}`}>
      {isLoading ? (
        <div className="spinner-border spinner-border-sm text-primary me-2"></div>
      ) : (
        <span className={`icon ${icon}`}></span>
      )}
      <span className="status">{status}</span>
    </div>
  );
}

export default ValidationBadge;