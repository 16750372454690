import React, { useState, useEffect, useCallback } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  Spinner,
} from 'reactstrap';
import { appLanguages } from '../../common/languages';
import classnames from 'classnames';
import { generateAndStoreSentenceAudio, saveSentence, fetchSentences } from '../../helpers/API/assistant';
import { langFlags } from '../../common/langFlags';

const CurrentSentences = () => {
  const [sentences, setSentences] = useState({});
  const [activeTab, setActiveTab] = useState('en');
  const [collapse, setCollapse] = useState([]);
  const [loading, setLoading] = useState({});
  const [status, setStatus] = useState({});

  const fetchSentencesForLanguage = useCallback(async (lang) => {
    setLoading((prev) => ({ ...prev, [`${lang}-loading`]: true }));
    const data = await fetchSentences(lang);
    setSentences((prev) => ({ ...prev, [lang]: data }));
    setLoading((prev) => ({ ...prev, [`${lang}-loading`]: false }));
  }, []);

  useEffect(() => {
    if (!sentences[activeTab]) {
      fetchSentencesForLanguage(activeTab);
    }
  }, [activeTab, fetchSentencesForLanguage, sentences]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleCollapse = (index) => {
    setCollapse((collapse) => {
      const newCollapse = [...collapse];
      newCollapse[index] = !newCollapse[index];
      return newCollapse;
    });
  };

  const handleGenerateAudio = async (lang, sentenceId) => {
    setLoading((prev) => ({ ...prev, [sentenceId]: true }));
    setStatus((prev) => ({ ...prev, [sentenceId]: null }));

    try {
      const sentence = sentences[lang].find(sentence => sentence.id === sentenceId);
      if (sentence) {
        const audioURL = await generateAndStoreSentenceAudio(sentence.text, lang, sentenceId);
        await saveSentence({ ...sentence, audioURL }, lang, sentenceId);
        fetchSentencesForLanguage(lang);
        setStatus((prev) => ({ ...prev, [sentenceId]: 'Process successful!' }));
      }
    } catch (error) {
      setStatus((prev) => ({ ...prev, [sentenceId]: `Error: ${error.message}` }));
    } finally {
      setLoading((prev) => ({ ...prev, [sentenceId]: false }));
      setTimeout(() => setStatus((prev) => ({ ...prev, [sentenceId]: null })), 5000);
    }
  };

  const handleGenerateAllAudios = async (sentenceId) => {
    setLoading((prev) => ({ ...prev, [sentenceId]: true }));
    setStatus((prev) => ({ ...prev, [sentenceId]: null }));

    try {
      for (const lang of appLanguages) {
        const sentence = sentences[lang].find(sentence => sentence.id === sentenceId);
        if (sentence) {
          const audioURL = await generateAndStoreSentenceAudio(sentence.text, lang, sentenceId);
          await saveSentence({ ...sentence, audioURL }, lang, sentenceId);
        }
      }
      const data = await fetchSentences(activeTab);
      setSentences(data);
      setStatus((prev) => ({ ...prev, [sentenceId]: 'Process successful!' }));
    } catch (error) {
      setStatus((prev) => ({ ...prev, [sentenceId]: `Error: ${error.message}` }));
    } finally {
      setLoading((prev) => ({ ...prev, [sentenceId]: false }));
      setTimeout(() => setStatus((prev) => ({ ...prev, [sentenceId]: null })), 5000);
    }
  };

  const checkAudioURL = (url) => {
    if (!url) return false;
    const audio = new Audio(url);
    return audio.canPlayType('audio/mpeg') || audio.canPlayType('audio/ogg') || audio.canPlayType('audio/wav');
  };

  return (
    <div className="current-sentences">
      <div className="white-block">
        <Nav tabs>
          {appLanguages.map((lang) => (
            <NavItem key={lang}>
              <NavLink
                className={classnames({ active: activeTab === lang })}
                onClick={() => toggleTab(lang)}
              >
                <img
                  src={langFlags[lang]}
                  alt={`${lang} flag`}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '5px'
                  }}
                />
                {lang.toUpperCase()}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab}>
          {appLanguages.map((lang) => (
            <TabPane key={lang} tabId={lang}>
              {loading[`${lang}-loading`] ? (
                <div className="loading-container">
                  <Spinner color="primary" />
                </div>
              ) : (
                sentences[lang]?.map((sentence, index) => (
                  <Card key={sentence.id}>
                    <CardHeader onClick={() => toggleCollapse(index)}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{sentence.text}</span>
                        <span className="text-muted" style={{ fontSize: '0.8em' }}>
                          ID: {sentence.id}
                        </span>
                      </div>
                    </CardHeader>
                    <Collapse isOpen={collapse[index]}>
                      <CardBody>
                        <div className="d-flex align-items-center">
                          {checkAudioURL(sentence.audioURL) ? (
                            <audio controls src={sentence.audioURL} style={{ marginRight: 'auto' }}></audio>
                          ) : (
                            <p>Audio format not supported</p>
                          )}
                          <Button
                            color="secondary"
                            onClick={() => handleGenerateAudio(lang, sentence.id)}
                            disabled={loading[sentence.id]}
                          >
                            {loading[sentence.id] ? (
                              <Spinner size="sm" />
                            ) : (
                              <>
                                <i className="bx bx-play" style={{ marginRight: '5px' }} />
                                Generate Audio
                              </>
                            )}
                          </Button>
                          {lang === 'en' && (
                            <Button
                              color="primary"
                              onClick={() => handleGenerateAllAudios(sentence.id)}
                              disabled={loading[sentence.id]}
                              className="ml-2"
                              style={{ marginLeft: '10px' }}
                            >
                              {loading[sentence.id] ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <i className="bx bx-play" style={{ marginRight: '5px' }} />
                                  Generate All Audios
                                </>
                              )}
                            </Button>
                          )}
                        </div>
                        {status[sentence.id] && (
                          <div style={{ color: status[sentence.id].startsWith('Error') ? 'red' : 'green', marginTop: '10px' }}>
                            {status[sentence.id]}
                          </div>
                        )}
                      </CardBody>
                    </Collapse>
                  </Card>
                ))
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default CurrentSentences;
