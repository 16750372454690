import axios from 'axios';
import Config from '../../../config';

export const translateText = async (text, targetLangs) => {
  if (!Array.isArray(targetLangs)) {
    targetLangs = [targetLangs];
  }

  const translations = { en: text };

  await Promise.all(targetLangs.map(async lang => {
    if (lang !== 'en') {
      try {
        console.log(`Translating text: "${text}" to language: "${lang}"`);
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          messages: [
            { role: 'system', content: `Translate the following text to ${lang} and make sure the response is in ${lang}.` },
            { role: 'user', content: text }
          ],
          model: 'gpt-4o'
        }, {
          headers: {
            'Authorization': `Bearer ${Config.chatGPTToken}`,
            'Content-Type': 'application/json',
          }
        });

        const translatedText = response.data.choices[0].message.content.trim();
        translations[lang] = translatedText;
        console.log(`Translated text for ${lang}: "${translatedText}"`);

      } catch (error) {
        console.error(`Error translating text to ${lang}:`, error);
      }
    }
  }));

  return translations;
};