import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Collapse, Nav, NavItem, NavLink, Button } from 'reactstrap';
import classnames from 'classnames';
import { appLanguages } from '../../common/languages';
import { langFlags } from '../../common/langFlags';
import RefreshButton from '../../components/Common/RefreshButton';
import LocalButton from '../../components/Common/Button';
import NewVoiceForm from './NewVoiceForm';
import { regenerateVoiceSample } from '../../helpers/API/assistant';
import { removeVoice } from '../../helpers/API/content/voices';

const Voices = ({ voices, fetchVoices, isLoading }) => {
  const [activeTab, setActiveTab] = useState('en');
  const [collapse, setCollapse] = useState([]);
  const [addingVoice, setAddingVoice] = useState(false);
  const [generatingSample, setGeneratingSample] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCollapse([]);
    }
  };

  const toggleCollapse = index => {
    setCollapse(collapse => {
      const newCollapse = [...collapse];
      newCollapse[index] = !newCollapse[index];
      return newCollapse;
    });
  };

  const handleRegenerateAudio = async (voice) => {
    try {
      setGeneratingSample(voice.name);
      const response = await regenerateVoiceSample(voice, activeTab);
      if (response && response.fileUrl) {
        // Update the voice object with the new sampleAudioURL
        voice.sampleAudioURL = response.fileUrl;
        fetchVoices(activeTab);  // Refetch voices to get the updated sample URL
      }
    } catch (error) {
      console.error('Error regenerating voice sample:', error);
      setErrorMessage('Error regenerating voice sample.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setGeneratingSample(null);
    }
  };

  const handleRemoveVoice = async (voice) => {
    if (window.confirm(`Are you sure you want to remove the voice: ${voice.name}?`)) {
      try {
        await removeVoice(activeTab, voice.name);
        fetchVoices(activeTab);  // Refetch voices to get the updated list
      } catch (error) {
        console.error('Error removing voice:', error);
        setErrorMessage('Error removing voice.');
        setTimeout(() => setErrorMessage(''), 5000);
      }
    }
  };

  return (
    <div className="white-block flex-2">
      <h2>Voices</h2>
      <LocalButton
        text="Add Voice"
        icon="bx bx-plus"
        extraClass={addingVoice ? 'active' : ''}
        onClick={() => setAddingVoice(!addingVoice)}
      />
      <RefreshButton
        isLoading={isLoading[activeTab]}
        onClick={() => fetchVoices(activeTab)} />
      <Nav tabs>
        {appLanguages.map(lang => (
          <NavItem key={lang}>
            <NavLink
              className={classnames({ active: activeTab === lang })}
              onClick={() => { toggleTab(lang); }}
            >
              <img
                src={langFlags[lang]}
                alt={`${lang} flag`}
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px'
                }}
              />
              {lang.charAt(0).toUpperCase() + lang.slice(1)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {isLoading[activeTab] ? (
        <div className="loading-container">
          <div className="spinner-border text-primary"></div>
        </div>
      ) : (
        voices[activeTab]?.map((voice, index) => (
          <Card key={index}>
            <CardHeader
              onClick={() => toggleCollapse(index)}
              className={
                collapse[index] ? 'active' : ''
              }
            >
              {voice.name}
            </CardHeader>
            <Collapse isOpen={collapse[index]}>
              <CardBody>
                <p><span>Display Name:</span> {voice.displayName}</p>
                <p><span>Gender:</span> {voice.gender}</p>
                <p><span>Locale:</span> {voice.locale}</p>
                {voice.voiceType && <p><span>Voice Type:</span> {voice.voiceType}</p>}
                <p><span>Words Per Minute:</span> {voice.wordsPerMinute}</p>
                <p><span>Type:</span> {voice.type}</p>
                <p><span>Category:</span> {voice.category}</p>
                <p>
                  <span className="me-2">Sample Audio URL:</span>
                  <button className="button" onClick={() => window.open(voice.sampleAudioURL, '_blank')}>
                    <i className="mdi mdi-play-circle me-1"></i>
                    Listen
                  </button>
                </p>
                <p>
                  <audio controls src={voice.sampleAudioURL} style={{ marginRight: 'auto' }}></audio>
                </p>
                <Button onClick={() => handleRegenerateAudio(voice)} color="primary">
                  {generatingSample === voice.name ? <div className="spinner-border text-light"></div> : 'Regenerate Audio Sample'}
                </Button>
                <Button onClick={() => handleRemoveVoice(voice)} color="danger" style={{ marginLeft: '10px' }}>
                  Remove Voice
                </Button>
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
              </CardBody>
            </Collapse>
          </Card>
        ))
      )}
      {addingVoice && (
        <NewVoiceForm
          fetchVoices={fetchVoices}
          voices={voices}
        />
      )}
    </div>
  );
};

export default Voices;
