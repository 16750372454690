import { getUsers } from './users';
import { isWithinTimeframe, getDateFromTimestamp } from '../../utils/dates';

const formatRatio = (ratio, percentage = true) => {
  return percentage ? `${(ratio * 100).toFixed(2)}%` : ratio.toFixed(2);
};

const purchasesLast24HoursAmount = (usersData) => {
  const now = Date.now();

  const prices = usersData?.flatMap(user =>
    (user.shop?.activity?.itemsAcquired || [])
      .filter(item => {
        const createdAtDate = getDateFromTimestamp(item.createdAt);
        return (now - createdAtDate.getTime()) <= 24 * 60 * 60 * 1000;
      })
      .map(item => item.price)
  ).filter(price => price !== undefined);

  const convertedPrices = convertPrices(prices);

  const sum = convertedPrices.reduce((total, price) => total + price, 0);
  return sum.toFixed(2);
};

const purchasesLastXDaysAmount = (usersData, days) => {
  const now = Date.now();

  const prices = usersData?.flatMap(user =>
    (user.shop?.activity?.itemsAcquired || [])
      .filter(item => {
        const createdAtDate = getDateFromTimestamp(item.createdAt);
        return (now - createdAtDate.getTime()) <= days * 24 * 60 * 60 * 1000;
      })
      .map(item => item.price)
  ).filter(price => price !== undefined);

  const convertedPrices = convertPrices(prices);
  const sum = convertedPrices.reduce((total, price) => total + price, 0);

  return sum.toFixed(2);
};

export const convertPrices = (prices) => {
  const parsePrice = (price) => {
    if (typeof price !== 'string') return 0;

    // Remove spaces and trim the input string
    price = price.replace(/\s/g, '').trim();

    // Replace commas with dots for decimal handling
    price = price.replace(',', '.');

    // Define the regex patterns for currency symbols and codes
    // Added 'US\\$' to the pattern to handle 'US$' specifically
    const currencyPattern = /[$€£₺złS\/R\$kr₩¥]|USD|EUR|TRY|PLN|KRW|INR|GBP|US\$/gi;

    // Identify if the price string includes a currency symbol or code and remove it
    let number = price.replace(currencyPattern, '');

    // Parse the cleaned string as a float number
    number = parseFloat(number);

    // Check if the price was recognized correctly
    if (isNaN(number)) {
      console.log('Failed to parse price:', price);
      return 0;
    }

    return number;
  };

  // Define the exchange rates, including INR and KRW (South Korean Won)
  const exchangeRates = {
    EUR: { EUR: 1, USD: 1.119, GBP: 0.9334, CHF: 1.0226, PLN: 4.6897, TRY: 28, PEN: 4.4, INR: 91.5, KRW: 1310 },
    USD: { EUR: 0.8937, USD: 1, GBP: 0.7993, CHF: 0.9132, PLN: 4.0212, TRY: 34, PEN: 3.7, INR: 75, KRW: 1180 },
    GBP: { EUR: 1.0716, USD: 1.251, GBP: 1, CHF: 1.1433, PLN: 5.0244, TRY: 32, PEN: 4.6, INR: 95, KRW: 1575 },
    CHF: { EUR: 0.9786, USD: 1.0956, GBP: 0.8745, CHF: 1, PLN: 4.4074, TRY: 29, PEN: 4.2, INR: 85, KRW: 1430 },
    PLN: { EUR: 0.2134, USD: 0.2486, GBP: 0.1991, CHF: 0.2269, PLN: 1, TRY: 6, PEN: 0.83, INR: 18.3, KRW: 290 },
    TRY: { EUR: 0.0357, USD: 0.02943, GBP: 0.0313, CHF: 0.0345, PLN: 0.1667, TRY: 1, PEN: 0.13, INR: 2.5, KRW: 40 },
    PEN: { EUR: 0.227, USD: 0.27, GBP: 0.217, CHF: 0.238, PLN: 1.2, TRY: 7.6, PEN: 1, INR: 20, KRW: 300 },
    INR: { EUR: 0.011, USD: 0.013, GBP: 0.0105, CHF: 0.0118, PLN: 0.055, TRY: 0.4, PEN: 0.05, INR: 1, KRW: 15.7 },
    KRW: { EUR: 0.00076, USD: 0.00085, GBP: 0.00065, CHF: 0.0007, PLN: 0.0034, TRY: 0.025, PEN: 0.0033, INR: 0.065, KRW: 1 },
  };

  const convertToCurrency = (price, fromCurrency, toCurrency) => {
    const rate = exchangeRates[fromCurrency][toCurrency];
    return parseFloat((price * rate).toFixed(2));
  };

  const convertedPrices = prices.map(price => {
    if (typeof price === 'string' && price) {
      let fromCurrency = null;

      if (price.includes('€')) fromCurrency = 'EUR';
      else if (price.includes('$') || price.includes('USD') || price.includes('US$')) fromCurrency = 'USD';
      else if (price.includes('£')) fromCurrency = 'GBP';
      else if (price.includes('GBP')) fromCurrency = 'GBP';
      else if (price.includes('₺')) fromCurrency = 'TRY';
      else if (price.includes('zł')) fromCurrency = 'PLN';
      else if (price.includes('S/')) fromCurrency = 'PEN'; // Correctly identifying Peruvian Sol
      else if (price.includes('R$')) fromCurrency = 'USD'; // Assuming R$ is similar to USD
      else if (price.includes('INR')) fromCurrency = 'INR'; // Adding Indian Rupee
      else if (price.includes('kr') || price.includes('₩')) fromCurrency = 'KRW'; // Adding South Korean Won

      if (fromCurrency) {
        let amount = parsePrice(price);
        let convertedAmount = convertToCurrency(amount, fromCurrency, 'USD');

        // Check if the converted price is over $50, and divide by 12 if so
        if (convertedAmount > 110) {
          console.warn('Adjusted high value price:', price, 'Converted:', convertedAmount);
          convertedAmount /= 14;
        }

        return convertedAmount;
      } else {
        console.warn('Unrecognized currency format:', price);
        return 0;
      }
    } else if (typeof price === 'number') {
      return price;
    } else {
      console.log('Unrecognized price format:', price);

      return 0;
    }
  });

  return convertedPrices;
};


const calculatePurchasesStats = (usersData, totalPurchasers) => {
  const prices = usersData?.flatMap(user =>
    (user.shop?.activity?.itemsAcquired?.map(item => item.price) || [])
  ).filter(price => price !== undefined);

  const convertedPrices = convertPrices(prices);
  const averagePrice = parseFloat((convertedPrices.reduce((acc, curr) => acc + curr, 0) / convertedPrices.length).toFixed(2));

  const totalCost = parseFloat((averagePrice * totalPurchasers).toFixed(2));

  return { averagePrice, totalCost };
};


export const getStats = async () => {
  const limit = 10000;
  const { data: usersData } = await getUsers({
    filters: [],
    limit: limit,
    size: limit,
    pageSize: limit
  });

  const totalUsers = usersData.length;
  const totalUsersWithCurrentReality = usersData.filter(user => user.currentReality).length;
  const userWithFreeDemoDone = usersData.filter(user => user.freeDemoCompleted);
  const totalUsersWithFreeDemoCompleted = userWithFreeDemoDone.length;
  const totalUsersWithFreeDemoCompletedLast10Days = userWithFreeDemoDone.filter(user => isWithinTimeframe(user.createdAt, 10 * 24 * 3600)).length;
  const totalUsersWithRealitiesAcquired = usersData.filter(user => user.shop.totalRealitiesAcquired > 0).length;
  const purchasersWithFreeDemoCompleted = usersData.filter(user => user.shop.totalRealitiesAcquired > 0 && user.freeDemoCompleted).length;
  const ratioFreeDemoCompletedPurchasers = formatRatio(purchasersWithFreeDemoCompleted / totalUsersWithFreeDemoCompleted);

  const newUsersLast24Hours = usersData.filter(user => isWithinTimeframe(user.createdAt, 24 * 3600)).length;
  const newUsersLast10Days = usersData.filter(user => isWithinTimeframe(user.createdAt, 10 * 24 * 3600)).length;
  const usersLast30Days = usersData.filter(user => isWithinTimeframe(user.createdAt, 30 * 24 * 3600));
  const newUsersLast30Days = usersLast30Days.length;
  const purchasers = usersData.filter(user => user.shop.totalRealitiesAcquired > 0);

  const totlalUsersWithRealityLast30Days = usersLast30Days.filter(user => user.currentReality).length;
  const totalUsersWithFreeDemoCompletedLast30Days = usersLast30Days.filter(user => user.freeDemoCompleted).length;
  const ratioTotalUsersWithFreeDemoCompletedLast30Days = formatRatio(totalUsersWithFreeDemoCompletedLast30Days / newUsersLast30Days);
  const ratioTotalUsersWithRealityLast30Days = formatRatio(totlalUsersWithRealityLast30Days / newUsersLast30Days);

  const totalPurchasers = purchasers.length;
  const totalPurchases = usersData.reduce((total, user) => total + (user.shop?.activity?.itemsAcquired?.length || 0), 0);
  const totalNonPurchasers = totalUsers - totalPurchasers;
  const totalPurchasersOf3Realities = usersData.filter(user => user.shop.totalRealitiesAcquired >= 3).length;
  const totalRealitiesAcquired = usersData.reduce((acc, user) => acc + user.shop.totalRealitiesAcquired, 0);

  const purchasesLast24Hours = usersData.filter(user => user.shop.activity?.itemsAcquired?.some(item => {
    return isWithinTimeframe(item.createdAt, 24 * 3600);
  })).length;
  const purchasesLast10Days = usersData.filter(user => user.shop.activity?.itemsAcquired?.some(item => isWithinTimeframe(item.createdAt, 10 * 24 * 3600))).length;
  const purchasesLast30Days = usersData.filter(user => user.shop.activity?.itemsAcquired?.some(item => isWithinTimeframe(item.createdAt, 30 * 24 * 3600))).length;

  const amountPurchasesLast24Hours = purchasesLast24HoursAmount(usersData);
  const amountPurchasesLast10Days = purchasesLastXDaysAmount(usersData, 10);
  const amountPurchasesLast30Days = purchasesLastXDaysAmount(usersData, 30);

  const ratioPurchasers = formatRatio(totalPurchasers / totalUsers);
  const ratioPurchasesByPurchasers = formatRatio(totalPurchases / totalPurchasers, false);
  const ratioNonPurchasers = formatRatio(totalNonPurchasers / totalUsers);
  const ratioPurchaseOf3Realities = formatRatio(totalPurchasersOf3Realities / totalPurchasers);
  const ratioUsersWithRealityCreated = formatRatio(totalUsersWithCurrentReality / totalUsers);
  const ratioUsersWithFreeDemoCompleted = formatRatio(totalUsersWithFreeDemoCompleted / totalUsers);
  const ratioUsersWithFreeDemoCompletedLast10Days = formatRatio(totalUsersWithFreeDemoCompletedLast10Days / totalUsers);
  const ratioNewUsersLast24Hours = formatRatio(newUsersLast24Hours / totalUsers);
  const ratioNewUsersLast10Days = formatRatio(newUsersLast10Days / totalUsers);
  const ratioNewUsersLast30Days = formatRatio(newUsersLast30Days / totalUsers);
  const ratioOfPurchaseOf3Realities = formatRatio(totalPurchasersOf3Realities / totalPurchasers);
  const ratioOfRealitiesAcquired = formatRatio(totalRealitiesAcquired / totalUsers);
  const ratioPurchasesLast10Days = formatRatio(purchasesLast10Days / totalUsers);

  // Ads related
  const firstAdsSeenDate = new Date('2024-08-01');

  // Filter users created after August 1st, 2024, and who have ads enabled
  const filteredUsersData = usersData.filter(user => {
    const userCreationDate = getDateFromTimestamp(user.createdAt);
    return userCreationDate >= firstAdsSeenDate;
  });

  // Calculate total users and those who have seen ads, based on the filtered data
  const totalUsersFiltered = filteredUsersData.length;

  // Debugging output
  const totalUsersWithAds = usersData.filter(user => user.adsConsented).length;
  const ratioTotalUsersWithAds = formatRatio(totalUsersWithAds / totalUsersFiltered);
  const totalAdsSeen = usersData.reduce((acc, user) => acc + (user.totalAdsSeen || 0), 0);
  const ratioAdSeenPerUser = formatRatio(totalAdsSeen / totalUsers);
  const avgAdsSeenPerUser = (totalAdsSeen / totalUsersWithAds).toFixed(0);
  const totalUsersWithMarketingConsented = usersData.filter(user => user.emailMarketing).length;
  const ratioUsersWithMarketingConsented = formatRatio(totalUsersWithMarketingConsented / totalUsersFiltered);
  const { averagePrice, totalCost } = calculatePurchasesStats(usersData, totalPurchasers);
  const lastTenPurchasers = purchasers.slice(-20);

  const totalMinutesFromSignupToPurchase = lastTenPurchasers.reduce((acc, user, index) => {
    const signupTimestamp = user.createdAt;
    const purchaseTimestamp = user.shop.activity.itemsAcquired[0].createdAt;

    // Convert Timestamp to Date object
    const signupDate = new Date(signupTimestamp.seconds * 1000 + signupTimestamp.nanoseconds / 1000000);
    const purchaseDate = new Date(purchaseTimestamp.seconds * 1000 + purchaseTimestamp.nanoseconds / 1000000);

    if (isNaN(signupDate) || isNaN(purchaseDate)) {
      return acc;
    }

    const timeFromSignupToPurchase = purchaseDate - signupDate;
    const timeFromSignupToPurchaseInHours = timeFromSignupToPurchase / 1000 / 60 / 60;

    return acc + timeFromSignupToPurchaseInHours;
  }, 0);

  const avgTimeFromSignupToPurchase = totalPurchasers > 0
    ? totalMinutesFromSignupToPurchase / totalPurchasers
    : 0;

  // Ratio per language of user
  let ratioPerLanguage = {};

  usersData.forEach(user => {
    if (!ratioPerLanguage[user.language]) {
      ratioPerLanguage[user.language] = 1;
    } else {
      ratioPerLanguage[user.language]++;
    }
  });

  // lets return something like languages: [{ language: 'en', ratio: '10%', users: 10 }]
  const ratioPerLanguageArray = Object.keys(ratioPerLanguage).map(language => {
    return {
      language,
      ratio: formatRatio(ratioPerLanguage[language] / totalUsers),
      users: ratioPerLanguage[language]
    };
  });

  ratioPerLanguageArray.sort((a, b) => parseFloat(b.ratio) - parseFloat(a.ratio));

  // Calculate total purchases per language
  let purchasesPerLanguage = {};

  usersData.forEach(user => {
    if (user.shop.totalRealitiesAcquired > 0) {
      if (!purchasesPerLanguage[user.language]) {
        purchasesPerLanguage[user.language] = user.shop.activity?.itemsAcquired?.length;
      } else {
        purchasesPerLanguage[user.language] += user.shop.activity?.itemsAcquired?.length;
      }
    }
  });

  // Calculate ratio of purchases per language
  let ratioPurchasesPerLanguage = {};

  Object.keys(purchasesPerLanguage).forEach(language => {
    ratioPurchasesPerLanguage[language] = formatRatio(purchasesPerLanguage[language] / totalPurchases);
  });

  const ratioFreeDemoCompletedPurchasersDecimal = parseFloat(ratioFreeDemoCompletedPurchasers) / 100;
  const expectedPurchasesNext10Days = (totalUsersWithFreeDemoCompletedLast10Days * ratioFreeDemoCompletedPurchasersDecimal).toFixed(2);
  const expectedIncomeNext10Days = (expectedPurchasesNext10Days * averagePrice).toFixed(2);
  const expectedPurchasesMonthly = (totalUsersWithFreeDemoCompleted * ratioFreeDemoCompletedPurchasersDecimal).toFixed(2);
  const expectedIncomeMonthly = (expectedPurchasesMonthly * averagePrice).toFixed(2);

  const femaleUsers = usersData.filter(user => user.gender === 'female');
  const maleUsers = usersData.filter(user => user.gender === 'male');
  const femaleRatio = formatRatio(femaleUsers.length / totalUsers);
  const maleRatio = formatRatio(maleUsers.length / totalUsers);
  const femalePurchasers = femaleUsers.filter(user => user.shop.totalRealitiesAcquired > 0).length;
  const ratioFemalePurchase = formatRatio(femalePurchasers / totalPurchasers);
  const malePurchasers = maleUsers.filter(user => user.shop.totalRealitiesAcquired > 0).length;
  const ratioMalePurchase = formatRatio(malePurchasers / totalPurchasers);
  const totalReferrals = usersData.reduce((acc, user) => acc + (user.referral?.referredUsersTotal || 0), 0);

  return {
    totalUsers,
    totalUsersWithCurrentReality,
    totlalUsersWithRealityLast30Days,
    ratioTotalUsersWithRealityLast30Days,
    totalUsersWithFreeDemoCompletedLast30Days,
    ratioTotalUsersWithFreeDemoCompletedLast30Days,
    totalUsersWithFreeDemoCompleted,
    totalUsersWithFreeDemoCompletedLast10Days,
    totalUsersWithRealitiesAcquired,
    totalPurchasers,
    totalNonPurchasers,
    totalPurchasersOf3Realities,
    totalRealitiesAcquired,
    ratioPurchasers,
    ratioNonPurchasers,
    ratioPurchaseOf3Realities,
    ratioPerLanguage: ratioPerLanguageArray,
    ratioUsersWithRealityCreated,
    ratioUsersWithFreeDemoCompleted,
    ratioUsersWithFreeDemoCompletedLast10Days,
    ratioNewUsersLast24Hours,
    ratioNewUsersLast10Days,
    ratioNewUsersLast30Days,
    newUsersLast24Hours,
    newUsersLast10Days,
    newUsersLast30Days,
    ratioOfPurchaseOf3Realities,
    ratioOfRealitiesAcquired,
    averagePrice,
    totalCost,
    purchasesLast10Days,
    ratioPurchasesLast10Days,
    amountPurchasesLast10Days,
    purchasesPerLanguage,
    ratioPurchasesPerLanguage,
    purchasersWithFreeDemoCompleted,
    ratioFreeDemoCompletedPurchasers,
    expectedPurchasesNext10Days,
    expectedIncomeNext10Days,
    expectedPurchasesMonthly,
    expectedIncomeMonthly,
    femaleUsers,
    maleUsers,
    femaleRatio,
    maleRatio,
    femalePurchasers,
    ratioFemalePurchase,
    malePurchasers,
    ratioMalePurchase,
    totalPurchases,
    ratioPurchasesByPurchasers,
    purchasesLast24Hours,
    amountPurchasesLast24Hours,
    purchasesLast30Days,
    amountPurchasesLast30Days,
    totalUsersWithAds,
    totalAdsSeen,
    totalReferrals,
    ratioTotalUsersWithAds: ratioTotalUsersWithAds,
    ratioAdSeenPerUser,
    avgAdsSeenPerUser,
    avgTimeFromSignupToPurchase,
    totalUsersWithMarketingConsented,
    ratioUsersWithMarketingConsented,
  };
};
