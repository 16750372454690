import React, { useEffect, useState } from "react";
import { getCountriesStats, adPriceTable } from "../../helpers/API/statsCountries";
import { allFlags } from "../../common/allFlags";
import RefreshButton from "../../components/Common/RefreshButton";
import { getCountryCode } from "../../common/countryNames";
import { formatNumber } from "../../utils/numbers";
import { countryNameMap, normalizeCountryName } from '../../utils/countries';

const StatsCountries = () => {
  const [stats, setStats] = useState({});
  const [allData, setAllData] = useState({
    totalUsers: 0,
    totalPurchasers: 0,
    globalSpend: 0,
    avgSpendPerUser: "0.00",
    avgSpendPerPurchaser: "0.00",
    totalAdsConsented: 0,
    totalAdsSeen: 0,
    totalAdEarnings: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getCountriesStats();

    if (!data) {
      setIsLoading(false);
      return;
    }

    const normalizedCountries = {};
    let totalAdsConsented = 0;
    let totalAdsSeen = 0;
    let totalAdEarnings = 0;

    Object.entries(data.countries).forEach(([country, details]) => {
      const normalizedCountry = normalizeCountryName(country);
      const totalSpend = parseFloat(details.totalSpend.replace('$', ''));

      if (!normalizedCountries[normalizedCountry]) {
        normalizedCountries[normalizedCountry] = {
          ...details,
          totalSpend,
          adsConsented: details.adsConsented || 0,
          totalAdsSeen: details.totalAdsSeen || 0,
          estimatedEarnings: details.estimatedEarnings || 0,
        };
      } else {
        normalizedCountries[normalizedCountry].users += details.users;
        normalizedCountries[normalizedCountry].purchasers += details.purchasers;
        normalizedCountries[normalizedCountry].totalSpend += totalSpend;
        normalizedCountries[normalizedCountry].adsConsented += details.adsConsented || 0;
        normalizedCountries[normalizedCountry].totalAdsSeen += details.totalAdsSeen || 0;
        normalizedCountries[normalizedCountry].estimatedEarnings += details.estimatedEarnings || 0;
      }

      totalAdsConsented += details.adsConsented || 0;
      totalAdsSeen += details.totalAdsSeen || 0;
      totalAdEarnings += details.estimatedEarnings || 0;
    });

    // Calculate additional stats for each country
    Object.keys(normalizedCountries).forEach(country => {
      const countryStats = normalizedCountries[country];
      const totalUsers = Object.values(normalizedCountries).reduce((acc, curr) => acc + curr.users, 0);
      const totalPurchasers = Object.values(normalizedCountries).reduce((acc, curr) => acc + curr.purchasers, 0);
      const globalSpend = Object.values(normalizedCountries).reduce((acc, curr) => acc + curr.totalSpend, 0);

      countryStats.userRatio = ((countryStats.users / totalUsers) * 100).toFixed(2) + '%';
      countryStats.purchaseRatio = totalPurchasers > 0 ? ((countryStats.purchasers / totalPurchasers) * 100).toFixed(2) + '%' : '0.00%';
      countryStats.spendRatio = globalSpend > 0 ? ((countryStats.totalSpend / globalSpend) * 100).toFixed(2) + '%' : '0.00%';
      countryStats.avgSpendPerUser = countryStats.users > 0 ? (countryStats.totalSpend / countryStats.users).toFixed(2) : '0.00';
      countryStats.avgSpendPerPurchaser = countryStats.purchasers > 0 ? (countryStats.totalSpend / countryStats.purchasers).toFixed(2) : '0.00';
      countryStats.totalSpend = countryStats.totalSpend.toFixed(2);

      // Calculate ads data only if there are ads consented
      if (countryStats.adsConsented > 0) {
        countryStats.adsRatio = (countryStats.totalAdsSeen / countryStats.adsConsented).toFixed(2);
        countryStats.avgAdPrice = countryStats.totalAdsSeen > 0 ? (countryStats.totalSpend / countryStats.totalAdsSeen).toFixed(2) : '0.00';
      } else {
        countryStats.adsRatio = null;
        countryStats.avgAdPrice = null;
      }
    });

    const totalUsers = Object.values(normalizedCountries).reduce((acc, curr) => acc + curr.users, 0);
    const totalPurchasers = Object.values(normalizedCountries).reduce((acc, curr) => acc + curr.purchasers, 0);

    setAllData({
      ...data,
      totalUsers,
      totalPurchasers,
      totalAdsConsented,
      totalAdsSeen,
      totalAdEarnings: totalAdEarnings.toFixed(2),
    });

    setStats({ countries: normalizedCountries });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading || Object.keys(stats).length === 0) {
    return (
      <div className="page-content">
        <div className="spinner-border text-primary"></div>
      </div>
    );
  }

  const countriesStats = Object.entries(stats.countries).map(([country, data]) => ({
    country: countryNameMap[country]?.[0] || country,
    users: data.users,
    userRatio: data.userRatio,
    purchasers: data.purchasers,
    purchaseRatio: data.purchaseRatio,
    totalSpend: data.totalSpend,
    spendRatio: data.spendRatio,
    avgSpendPerUser: data.avgSpendPerUser,
    avgSpendPerPurchaser: data.avgSpendPerPurchaser,
    adsConsented: data.adsConsented,
    totalAdsSeen: data.totalAdsSeen,
    adsRatio: data.adsRatio,
    avgAdPrice: data.avgAdPrice,
    estimatedEarnings: data.estimatedEarnings,
  }));

  return (
    <div className="page-content">
      <div className="stats-container countries">
        <RefreshButton onClick={fetchData} extraClass="refresh" isLoading={isLoading} />
        <div className="stats-block">
          <div className="stats-item header">
            <label className="stats-label bold">Country</label>
            {allData && (
              <div className="stats-table">
                <div className="stats-column">
                  <div className="stats-title">Users</div>
                  <div className="stats-value">{formatNumber(allData.totalUsers)}</div>
                </div>
                <div className="stats-column">
                  <div className="stats-title">Purchases</div>
                  <div className="stats-value">{formatNumber(allData.totalPurchasers)}</div>
                </div>
                <div className="stats-column">
                  <div className="stats-title">$$ (u/p)</div>
                  <div className="stats-value">${allData.avgSpendPerUser} / ${allData.avgSpendPerPurchaser}</div>
                </div>
                <div className="stats-column">
                  <div className="stats-title">Total Income</div>
                  <div className="stats-value">{allData.globalSpend}</div>
                </div>
                <div className="stats-column">
                  <div className="stats-title">Ads Data</div>
                  <div className="stats-value">
                    {formatNumber(allData.totalAdsConsented)} / {formatNumber(allData.totalAdsSeen)} / ${allData.totalAdEarnings}
                  </div>
                </div>
              </div>
            )}
          </div>
          {countriesStats.map((item, index) => {
            const flagCode = getCountryCode(item.country.toLowerCase());
            if (!flagCode) return null;

            const theFlag = allFlags[flagCode];
            const theName = item.country;

            return (
              <div key={index} className={`stats-item ${index === 0 ? "highlighted green" : ""}`}>
                <label className="stats-label">
                  <img
                    src={theFlag}
                    alt={theName}
                    title={theName}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  <span>{countryNameMap[item.country]?.[0] || item.country}</span>
                </label>
                <p className="stats-value">
                  {item.users} <span className="stats-ratio">({item.userRatio})</span>
                </p>
                {item.purchasers > 0 ? (
                  <p className="stats-value green bold">
                    {item.purchasers} <span className="stats-ratio">({item.purchaseRatio})</span>
                  </p>
                ) : (
                  <p className="stats-value"></p>
                )}
                {item.avgSpendPerUser > 0 ? (
                  <p className="stats-value">
                    ${item.avgSpendPerUser} / <span className="bold green">${item.avgSpendPerPurchaser}</span>
                  </p>
                ) : (
                  <p className="stats-value"></p>
                )}
                {item.totalSpend > 0 ? (
                  <p className="stats-value">
                    ${item.totalSpend}
                  </p>
                ) : (
                  <p className="stats-value"></p>
                )}
                {item.adsConsented > 0 ? (
                  <p className="stats-value">
                    {item.adsConsented} / {item.totalAdsSeen} / {item.adsRatio} / <span className="green bold">${(item.totalAdsSeen * (adPriceTable[flagCode]?.ecpm / 1000) || 0.015).toFixed(2)}</span>
                  </p>
                ) : (
                  <p className="stats-value"></p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatsCountries;
