import axios from "axios";
import { del, get, post, put } from "./api_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
const postJwtLogin = data => {
  console.log("data", data, url.POST_FAKE_JWT_LOGIN);
  return post(url.POST_FAKE_JWT_LOGIN, data);
}

// // Register Method
// const postFakeRegister = data => {
//   return axios
//     .post(url.POST_FAKE_REGISTER, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       let message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// // Login Method
// const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// // Real login
// const postLogin = data => post(url.POST_LOGIN, data);

// // postForgetPwd
// const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// // Edit profile
// const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);
// const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// // Register Method
// const postJwtRegister = (url, data) => {
//   return axios
//     .post(url, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       var message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// // postForgetPwd
// const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// // postSocialLogin
// export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// // Events
// export const getEvents = () => get(url.GET_EVENTS);

// export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);
// export const updateEvent = event => put(`${url.UPDATE_EVENT}/${event._id}`, event);
// export const deleteEvent = eventId => del(`${url.DELETE_EVENT}/${eventId}`);

// // Users
// // export const getUsers = () => get(url.GET_USERS);
// export const getUserProfile = userId => get(`${url.GET_USER_PROFILE}/${userId}`);

// export const addNewUser = user => post(url.ADD_NEW_USER, user);
// export const updateUser = user => put(`${url.UPDATE_USER}/${user._id}`, user);
// export const deleteUser = userId => del(`${url.DELETE_USER}/${userId}`);

// // Reports & Feedback
// export const getReports = () => get(url.GET_REPORTS);
// export const getFeedback = () => get(url.GET_FEEDBACK);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  // postFakeRegister,
  // postFakeLogin,
  // postLogin,
  // postFakeProfile,
  // postFakeForgetPwd,
  // postJwtRegister,
  // postJwtForgetPwd,
  // postJwtProfile
};
