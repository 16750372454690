import {
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS_FAILURE,
} from './actionTypes';

const initialState = {
  loading: false,
  emails: [],
  error: null,
};

const emailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_EMAILS_SUCCESS:
      return { ...state, loading: false, emails: action.payload };
    case FETCH_EMAILS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default emailsReducer;
