import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_FEEDBACK,
  EDIT_FEEDBACK_STATUS,
  ADD_FEEDBACK_RESPONSE,
  EDIT_FEEDBACK_RESPONSE,
} from "./actionTypes";

import {
  getFeedbackSuccess,
  getFeedbackFail,
  toggleFeedbackLoading,
  editFeedbackStatusSuccess,
  editFeedbackStatusFail,
  addFeedbackResponseSuccess,
  addFeedbackResponseFail,
  editFeedbackResponseSuccess,
  editFeedbackResponseFail,
} from "./actions";

import {
  getFeedback,
  editFeedbackStatus,
  addFeedbackResponse,
  editFeedbackResponse,
} from "../../helpers/API/feedback";

function* fetchFeedback() {
  yield put(toggleFeedbackLoading());
  try {
    const response = yield call(getFeedback);
    yield put(getFeedbackSuccess(response));
  } catch (error) {
    yield put(getFeedbackFail(error));
  }
  yield put(toggleFeedbackLoading());
}

function* handleEditFeedbackStatus(action) {
  const { feedbackId, newStatus } = action.payload;
  try {
    yield call(editFeedbackStatus, feedbackId, newStatus);
    yield put(editFeedbackStatusSuccess(feedbackId, newStatus));
  } catch (error) {
    yield put(editFeedbackStatusFail(error));
  }
}

function* handleAddFeedbackResponse(action) {
  const { feedbackId, response } = action.payload;
  try {
    yield call(addFeedbackResponse, feedbackId, response);
    yield put(addFeedbackResponseSuccess(feedbackId, response));
  } catch (error) {
    yield put(addFeedbackResponseFail(error));
  }
}

function* handleEditFeedbackResponse(action) {
  const { feedbackId, response } = action.payload;
  try {
    yield call(editFeedbackResponse, feedbackId, response);
    yield put(editFeedbackResponseSuccess(feedbackId, response));
  } catch (error) {
    yield put(editFeedbackResponseFail(error));
  }
}

function* feedbackSaga() {
  yield takeEvery(GET_FEEDBACK, fetchFeedback);
  yield takeEvery(EDIT_FEEDBACK_STATUS, handleEditFeedbackStatus);
  yield takeEvery(ADD_FEEDBACK_RESPONSE, handleAddFeedbackResponse);
  yield takeEvery(EDIT_FEEDBACK_RESPONSE, handleEditFeedbackResponse);
}

export default feedbackSaga;
