export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
export const TOGGLE_LOADING = "TOGGLE_LOADING"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"
