import { Timestamp, FieldValue } from "firebase/firestore";

export const formatDate = (timestamp: Timestamp | FieldValue | Date | string, showYear: boolean = false): string => {
  let date: Date;

  if (timestamp instanceof Date) {
    date = timestamp;
  } else if (timestamp instanceof Timestamp) {
    date = timestamp.toDate();
  } else if (typeof timestamp === 'object' && 'toDate' in timestamp) {
    date = (timestamp as any).toDate();
  } else if (typeof timestamp === 'string' || typeof timestamp === 'number') {
    date = new Date(parseInt(timestamp.toString(), 10) * 1000); // Convert seconds to milliseconds
  } else {
    throw new Error('Invalid timestamp type');
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const finalDate = `${day}/${month}${showYear ? `/${year}` : ''}`;

  return `${finalDate} - ${hours}:${minutes}`;
};

export const extractTimestampSeconds = (timestamp: Timestamp | FieldValue): number => {
  if (timestamp instanceof Timestamp) {
    return timestamp.seconds;
  }
  throw new Error('Invalid timestamp type');
};

export const getDateFromTimestamp = (timestamp: Timestamp | Date | FieldValue): Date => {
  if (timestamp instanceof Date) {
    return timestamp;
  } else if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  } else if (typeof timestamp === 'object' && 'toDate' in timestamp) {
    return (timestamp as any).toDate();
  } else {
    throw new Error('Invalid timestamp type');
  }
};

export const isWithinTimeframe = (timestamp: Timestamp | Date | FieldValue, timeframeInSeconds: number): boolean => {
  const date = getDateFromTimestamp(timestamp);
  return (Date.now() - date.getTime()) / 1000 <= timeframeInSeconds;
};

export const timeAgo = (timestamp: any) => {
  if (!timestamp) return 'Invalid date';

  // Check if timestamp is in seconds (Firebase Timestamp)
  if (typeof timestamp === 'object' && timestamp.seconds) {
    timestamp = timestamp.seconds * 1000; // Convert to milliseconds
  }

  const now: any = new Date();
  const lastUpdate: any = new Date(timestamp as number);

  // Ensure that the timestamp is valid
  if (isNaN(lastUpdate.getTime())) {
    return 'Invalid date';
  }

  const diffInMs = now - lastUpdate;
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return `${diffInDays} days ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hours ago`;
  } else {
    return 'Just now';
  }
};

