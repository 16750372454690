import React, { useState, useEffect } from 'react';
import { getRatings, getRatingByLanguage } from '../../helpers/API/ratings';
import { appLanguages } from '../../common/languages';
import { langFlags } from '../../common/langFlags';
import { formatNumber } from '../../utils/numbers';

const Ratings = () => {
  const [ratings, setRatings] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchRatings = async () => {
    setIsLoading(true);
    let response;

    if (selectedLanguage) {
      response = await getRatingByLanguage(selectedLanguage);
    } else {
      response = await getRatings();
    }

    if (!response) {
      setIsLoading(false);
      return;
    }
    setRatings(response);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRatings();
  }, [selectedLanguage]);

  const flattenedVoices = Object.entries(ratings).flatMap(([language, voices]) =>
    Object.entries(voices).map(([voiceName, data]) => ({
      language,
      voiceName,
      votesUp: data.votes.votesUp || 0,
      votesDown: data.votes.votesDown || 0,
      totalVotes: (data.votes.votesUp || 0) - (data.votes.votesDown || 0),
      downvoteDetails: (data.votes.votes || [])
        .filter(vote => vote.vote && vote.vote.toLowerCase().includes('down'))
        .map(vote => ({
          exerciseId: vote.exerciseId,
          exerciseName: vote.exerciseName,
          voteType: vote.vote,
        })),
    }))
  );

  const sortedVoices = flattenedVoices.sort((a, b) => b.votesUp - a.votesUp);

  return (
    <div id="ratings-page-content" className="page-content">
      <div className="header">
        <h3>Ratings</h3>
        <select
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="">All Languages</option>
          {appLanguages.map(lang => (
            <option key={lang} value={lang}>{lang.toUpperCase()}</option>
          ))}
        </select>
      </div>

      <div className="ratings-list">
        {isLoading ? (
          <div className="spinner-border text-primary"></div>
        ) : (
          <div className="ratings-table">
            <div className="ratings-header">
              <div className="ratings-column">Language</div>
              <div className="ratings-column">Voice Name</div>
              <div className="ratings-column">Votes Up</div>
              <div className="ratings-column">Votes Down</div>
              <div className="ratings-column">Downvote Details</div>
            </div>
            {sortedVoices.map((voice, index) => (
              <div key={index} className="ratings-row">
                <div className="ratings-column">
                  <img
                    src={langFlags[voice.language]}
                    alt={voice.language}
                    className="flag-icon"
                  />
                </div>
                <div className="ratings-column">{voice.voiceName}</div>
                <div className="ratings-column">{formatNumber(voice.votesUp)}</div>
                <div className="ratings-column">{formatNumber(voice.votesDown)}</div>
                <div className="ratings-column">
                  {voice.downvoteDetails.length > 0 ? (
                    <ul>
                      {voice.downvoteDetails.map((detail, i) => (
                        <li key={i}>
                          {detail.exerciseName} (ID: {detail.exerciseId}, <b>{detail.voteType}</b>)
                        </li>
                      ))}
                    </ul>
                  ) : ''}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Ratings;
