import React, { useMemo, useState } from "react";
import TableContainer from '../../components/Common/TableContainer';
import { formatDate } from '../../utils/dates';
import { SelectColumnFilter, filterForExactMatch } from '../../components/Common/filters';
import useTableData from '../../hooks/useTableData';
import { useDispatch } from 'react-redux';
import {
  getFeedback,
  editFeedbackStatus,
  addFeedbackResponse,
  editFeedbackResponse,
} from "../../store/actions";

const Feedback = () => {
  const dispatch = useDispatch();
  const {
    data,
    totalItems,
    loading,
    filters,
    pageSize,
    pageIndex,
    totalPages,
    sortBy,
    setFilters,
    setPageSize,
    setPageIndex,
    setSortBy,
  } = useTableData(getFeedback, { dataKey: 'feedback', autoRefresh: false });

  const [statusLoading, setStatusLoading] = useState(null);
  const [responseLoading, setResponseLoading] = useState(null);

  // Function to update the local data directly without a full refresh
  const updateLocalData = (feedbackId, key, value) => {
    const updatedData = data.map(item =>
      item.id === feedbackId ? { ...item, [key]: value } : item
    );
    setFilters({ data: updatedData });
  };

  const handleStatusChange = async (feedbackId, newStatus) => {
    setStatusLoading(feedbackId);
    updateLocalData(feedbackId, 'status', newStatus); // Optimistic update
    try {
      await dispatch(editFeedbackStatus(feedbackId, newStatus));
    } catch (error) {
      // Optionally revert optimistic update on error
      updateLocalData(feedbackId, 'status', data.find(item => item.id === feedbackId).status);
    } finally {
      setStatusLoading(null);
    }
  };

  const handleResponseChange = async (feedbackId, response) => {
    setResponseLoading(feedbackId);
    updateLocalData(feedbackId, 'response', response); // Optimistic update
    try {
      if (!response) {
        await dispatch(addFeedbackResponse(feedbackId, response));
      } else {
        await dispatch(editFeedbackResponse(feedbackId, response));
      }
    } catch (error) {
      // Optionally revert optimistic update on error
      updateLocalData(feedbackId, 'response', data.find(item => item.id === feedbackId).response);
    } finally {
      setResponseLoading(null);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'From user',
        accessor: 'userId',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        Cell: ({ row }) => {
          const [status, setStatus] = useState(row.original.status);
          const isLoading = statusLoading === row.original.id;
          const statusColor =
            status === 'solved' ? 'green' : status === 'working on it' ? 'orange' : '#ffd692';
          return (
            <div className="status-cell">
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleStatusChange(row.original.id, e.target.value);
                }}
                className={`status-select ${isLoading ? 'loading' : ''}`}
                disabled={isLoading}
                style={{
                  backgroundColor: statusColor,
                  color: status === 'solved' ? 'white' : '#2a2a2a',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                <option value="new">New</option>
                <option value="wip">WIP</option>
                <option value="solved">Solved</option>
              </select>
              {isLoading && <span className="spinner"></span>}
            </div>
          );
        },
      },
      {
        Header: 'CreatedAt',
        accessor: 'createdAt',
        Filter: () => null,
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Content',
        accessor: 'content',
      },
      {
        Header: 'Response',
        accessor: 'response',
        Cell: ({ row }) => {
          const [response, setResponse] = useState(row.original.response || '');
          const [isEditing, setIsEditing] = useState(false);
          const isLoading = responseLoading === row.original.id;

          return (
            <div className="response-cell">
              {isEditing ? (
                <div className="response-edit-container">
                  <textarea
                    value={response}
                    onChange={(e) => setResponse(e.target.value)}
                    className="response-textarea"
                    disabled={isLoading}
                  />
                  <div className="response-actions">
                    <button
                      onClick={() => {
                        handleResponseChange(row.original.id, response);
                        setIsEditing(false);
                      }}
                      className={`feedback-button save-button ${isLoading ? 'loading' : ''}`}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="feedback-button cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="response-display">
                  <p className="response-text">{response || 'No response yet'}</p>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="feedback-button edit-button"
                  >
                    {response ? <i className="fas fa-edit"></i> : <i className="fas fa-plus"></i>}
                  </button>
                </div>
              )}
              {isLoading && <span className="spinner"></span>}
            </div>
          );
        },
      },
    ],
    [statusLoading, responseLoading]
  );

  return (
    <div className="page-content">
      <TableContainer
        className="custom-header-css"
        columns={columns}
        data={data}
        isLoading={loading}
        isGlobalFilter={true}
        isAddOptions={false}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalItems={totalItems}
        totalPages={totalPages}
        setFilters={setFilters}
        setPageSize={setPageSize}
        setPageIndex={setPageIndex}
        setSortBy={setSortBy}
        sortBy={sortBy}
        filters={filters}
      />
    </div>
  );
};

export default Feedback;
