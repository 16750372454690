import axios from 'axios';
import Config from "../../../config";

export async function generateImage(prompt) {
  const openaiUrl = 'https://api.openai.com/v1/images/generations';
  const headers = { Authorization: `Bearer ${Config.chatGPTToken}` };

  const data = {
    prompt: prompt,
    n: 1,
    size: "1024x1024",
    response_format: "url"
  };

  try {
    const response = await axios.post(openaiUrl, data, { headers });

    return response.data.data[0].url;
  } catch (error) {
    console.error(`Error generating image: ${error}`);
    return null;
  }
}

export async function sendPromptToChatGPT(messages, type) {
  const openaiUrl = 'https://api.openai.com/v1/chat/completions';
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Config.chatGPTToken}`,
  };

  const payload = {
    model: "gpt-4o",
    messages: messages,
    response_format: { type: "json_object" }
  };

  try {
    const response = await axios.post(openaiUrl, payload, { headers });;
    const chatContent = response.data.choices[0].message.content;

    // Assuming the content is a JSON string, parse it to an object
    const contentObject = JSON.parse(chatContent);

    // Extract title and texts
    if (type === 'notifications') {
    const { titles, bodies } = contentObject;
    return { titles, bodies };
    } else if (type === 'exercise') {
      const { title, texts } = contentObject;
      return { title, texts };
    }
  } catch (error) {
    console.error(`Error during communication with ChatGPT: ${error}`);
    throw new Error('ChatGPT communication failed');
  }
}

export async function translateContentWithStructure(
  title,
  texts,
  targetLanguage
) {
  console.log(`Translating content: title=${title}, text length=${texts.length}, languageCode=${targetLanguage}`);

  const messages = [
    {
      role: "system",
      content: `Translate the following title and texts to ${targetLanguage.toUpperCase()} and format the response as JSON with "title" as a single string and "texts" as an array of strings. Ensure the response only contains the JSON object and no additional text.`,
    },
    {
      role: "user",
      content: JSON.stringify({
        title: title,
        texts: texts
      }),
    }
  ];

  try {
    const translatedContent = await sendPromptToChatGPT(messages, "exercise");
    console.log(`Received translated content: ${JSON.stringify(translatedContent)}`);

    // Validate the translated content
    if (translatedContent && typeof translatedContent === 'object' && translatedContent.title && Array.isArray(translatedContent.texts)) {
      return {
        title: translatedContent.title,
        texts: translatedContent.texts,
      };
    } else {
      console.error(`Invalid response format: ${JSON.stringify(translatedContent)}`);
      return null;
    }
  } catch (error) {
    console.error(`Error during translation for title: ${title}`, error);
    return null;
  }
}

