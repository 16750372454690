import { getUsers } from './users';
import { formatNumber } from '../../utils/numbers';
import { normalizeCountryName } from '../../utils/countries';
import { getCountryCode } from '../../common/countryNames';

// Table for average ad prices based on observed eCPM and impressions
export const adPriceTable = {
  ar: { impressions: 268, ecpm: 2.18, earnings: 0.58 }, // Argentina
  es: { impressions: 169, ecpm: 6.00, earnings: 1.01 }, // Spain
  de: { impressions: 120, ecpm: 8.31, earnings: 1.00 }, // Germany
  at: { impressions: 120, ecpm: 8.31, earnings: 1.00 }, // Austria
  it: { impressions: 120, ecpm: 5.25, earnings: 0.63 }, // Italy
  us: { impressions: 111, ecpm: 17.31, earnings: 1.92 }, // United States
  pl: { impressions: 90, ecpm: 7.62, earnings: 0.69 }, // Poland
  co: { impressions: 82, ecpm: 1.84, earnings: 0.15 }, // Colombia
  fr: { impressions: 70, ecpm: 11.20, earnings: 0.78 }, // France
  nl: { impressions: 67, ecpm: 10.23, earnings: 0.69 }, // Netherlands
  gb: { impressions: 46, ecpm: 8.96, earnings: 0.41 }, // United Kingdom
  br: { impressions: 42, ecpm: 3.30, earnings: 0.14 }, // Brazil
  ec: { impressions: 41, ecpm: 1.91, earnings: 0.08 }, // Ecuador
  se: { impressions: 40, ecpm: 6.40, earnings: 0.26 }, // Sweden
  pa: { impressions: 33, ecpm: 10.38, earnings: 0.34 }, // Panama
  tr: { impressions: 30, ecpm: 4.00, earnings: 0.12 }, // Turkey
  ie: { impressions: 29, ecpm: 4.19, earnings: 0.12 }, // Ireland
  pe: { impressions: 26, ecpm: 1.16, earnings: 0.03 }, // Peru
  au: { impressions: 19, ecpm: 18.93, earnings: 0.36 }, // Australia
  ca: { impressions: 19, ecpm: 10.45, earnings: 0.20 }, // Canada
  pt: { impressions: 19, ecpm: 3.40, earnings: 0.06 }, // Portugal
  mx: { impressions: 12, ecpm: 2.18, earnings: 0.03 }, // Mexico
  za: { impressions: 9, ecpm: 12.13, earnings: 0.11 }, // South Africa
  no: { impressions: 5, ecpm: 12.44, earnings: 0.06 }, // Norway
};

const calculateEstimatedEarnings = (countryCode, totalAdsSeen) => {
  let flagcode = countryCode;

  if (adPriceTable[flagcode] === undefined && countryCode) {
    flagcode = getCountryCode(flagcode.toLowerCase());
  }

  const ecpm = adPriceTable[flagcode]?.ecpm || 18;

  return (totalAdsSeen * ecpm) / 1000;
};

export const getCountriesStats = async () => {
  const limit = 10000;
  const { data: usersData } = await getUsers({
    filters: [],
    limit,
    size: limit,
    pageSize: limit,
  });

  const countryStats = {};
  let totalUsers = 0;
  let totalPurchasers = 0;
  let globalSpend = 0;
  let totalAdEarnings = 0; // Track total ad earnings

  usersData.forEach((user) => {
    let { country: theCountry, shop, language, adsConsented, totalAdsSeen } = user;

    const itemsAcquired = shop?.activity?.itemsAcquired || [];
    let country = theCountry || itemsAcquired[0]?.country || user.language || user.deviceLanguage || 'Unknown';
    country = normalizeCountryName(country.toLowerCase()); // Normalize the country name

    // Get the country code
    const countryCode = getCountryCode(country);

    // Handle specific cases like Brazil and Turkey
    if (itemsAcquired?.[0]?.price.includes('R$') || language === 'pt-BR') {
      country = 'Brazil';
    } else if (itemsAcquired?.[0]?.price.includes('₺')) {
      country = 'Turkey';
    }

    if (!countryStats[country]) {
      countryStats[country] = {
        users: 0,
        purchasers: 0,
        totalSpend: 0,
        adsConsented: 0,
        totalAdsSeen: 0,
        estimatedEarnings: 0, // Track estimated ad earnings per country
      };
    }

    countryStats[country].users += 1;
    totalUsers += 1;

    if (adsConsented) {
      countryStats[country].adsConsented += 1;
    }
    countryStats[country].totalAdsSeen += totalAdsSeen || 0;

    // Calculate estimated earnings using the new method
    const estimatedEarnings = calculateEstimatedEarnings(countryCode, totalAdsSeen);
    countryStats[country].estimatedEarnings += estimatedEarnings;
    totalAdEarnings += estimatedEarnings;

    itemsAcquired.forEach((item) => {
      const { usdEquivalent } = getCurrencyAndAmount(item.price, country, user.language);
      if (usdEquivalent > 0) {
        countryStats[country].purchasers += 1;
        countryStats[country].totalSpend += usdEquivalent;
        totalPurchasers += 1;
        globalSpend += usdEquivalent;
      }
    });
  });

  // Calculate ratios and averages
  Object.keys(countryStats).forEach((country) => {
    const stats = countryStats[country];
    stats.userRatio = ((stats.users / totalUsers) * 100).toFixed(2) + '%';
    stats.purchaseRatio = totalPurchasers > 0 ? ((stats.purchasers / totalPurchasers) * 100).toFixed(2) + '%' : '0.00%';
    stats.spendRatio = globalSpend > 0 ? ((stats.totalSpend / globalSpend) * 100).toFixed(2) + '%' : '0.00%';

    stats.avgSpendPerUser = stats.users > 0 ? `$${(stats.totalSpend / stats.users).toFixed(2)}` : '$0.00';
    stats.avgSpendPerPurchaser = stats.purchasers > 0 ? `$${(stats.totalSpend / stats.purchasers).toFixed(2)}` : '$0.00';
    stats.totalSpend = `$${stats.totalSpend.toFixed(2)}`;

    // Use ad price table for avg ad price calculation
    if (adPriceTable[getCountryCode(country.toLowerCase())]) {
      stats.avgAdPrice = `$${adPriceTable[getCountryCode(country.toLowerCase())].ecpm.toFixed(2)}`;
    } else {
      stats.avgAdPrice = 'N/A';
    }

    if (stats.adsConsented > 0) {
      stats.adsRatio = (stats.totalAdsSeen / stats.adsConsented).toFixed(2);
    } else {
      stats.adsRatio = null;
    }
  });

  const avgSpendPerUser = totalUsers > 0 ? (globalSpend / totalUsers).toFixed(2) : 0;
  const avgSpendPerPurchaser = totalPurchasers > 0 ? (globalSpend / totalPurchasers).toFixed(2) : 0;

  const sortedCountries = Object.entries(countryStats).sort(([, a], [, b]) => parseFloat(b.totalSpend.slice(1)) - parseFloat(a.totalSpend.slice(1)));

  return {
    totalUsers,
    totalPurchasers,
    globalSpend: `$${formatNumber(Number(globalSpend))}`,
    countries: Object.fromEntries(sortedCountries),
    avgSpendPerPurchaser,
    avgSpendPerUser,
    totalAdEarnings: totalAdEarnings.toFixed(2), // Return total ad earnings correctly
  };
};


// Function to handle currency conversion and proper parsing of the price string
const getCurrencyAndAmount = (price, country, language) => {
  let currency = 'USD';
  let amount = 0;

  if (typeof price === 'string') {
    // Identify the currency from the string
    if (price.includes('€')) currency = 'EUR';
    else if (price.includes('R$')) {
      currency = 'BRL';  // Brazilian Real
    } else if (price.includes('$') || price.includes('USD')) {
      // Special check if country is Mexico and price symbol is $
      if (['mx', 'mexico'].includes(country?.toLowerCase())) {
        currency = 'MXN';
      } else if (['in', 'india'].includes(country?.toLowerCase())) {
        currency = 'INR';
      } else {
        currency = 'USD';
      }
    } else if (price.includes('£')) currency = 'GBP';
    else if (price.includes('₺')) currency = 'TRY';
    else if (price.includes('zł')) currency = 'PLN';
    else if (price.includes('₹')) currency = 'INR';
    else if (price.includes('¥')) currency = 'JPY';
    else if (price.includes('AU$')) currency = 'AUD';
    else if (price.includes('CA$')) currency = 'CAD';
    else if (price.includes('CHF')) currency = 'CHF';
    else if (price.includes('S/')) currency = 'PEN';
    else if (price.includes('BRL')) currency = 'BRL';
    else if (price.includes('MXN')) currency = 'MXN';
    else if (price.includes('INR')) currency = 'INR';

    // Properly parse the number, considering different formatting
    let sanitizedPrice = price.replace(/[^\d,.-]/g, '');

    // European format correction: If there's a comma and no period
    if (sanitizedPrice.includes(',') && !sanitizedPrice.includes('.')) {
      sanitizedPrice = sanitizedPrice.replace(',', '.');
    }

    sanitizedPrice = sanitizedPrice.replace(/,/g, '');
    amount = parseFloat(sanitizedPrice);
  } else if (typeof price === 'number') {
    amount = price;
  }

  let usdEquivalent = convertToUSD(amount, currency);

  // Special handling for Brazil to ensure prices aren't added to Portugal
  if (currency === 'BRL' && country === 'portugal') {
    usdEquivalent = 0;  // Ignore the amount for incorrect country-price pairings
  }

  // This is specific to Mexico, where the currency symbol is $ but the currency is MXN
  if (amount > 100 && currency === 'USD') {
    usdEquivalent = usdEquivalent * 0.052;
  }

  return { currency, amount, usdEquivalent };
};


// Conversion function with realistic exchange rates
const convertToUSD = (amount, currency) => {
  const rates = {
    EUR: 1.1,    // Euro
    USD: 1,      // US Dollar
    GBP: 1.38,   // British Pound
    TRY: 0.036,  // Turkish Lira
    PLN: 0.26,   // Polish Zloty
    INR: 0.012,  // Indian Rupee
    JPY: 0.0091, // Japanese Yen
    AUD: 0.75,   // Australian Dollar
    CAD: 0.79,   // Canadian Dollar
    CHF: 1.08,   // Swiss Franc
    BRL: 0.18,   // Brazilian Real
    MXN: 0.052,  // Mexican Peso
    PEN: 0.27,   // Peruvian Sol (S/)
  };

  return amount * (rates[currency] || 1);
};
