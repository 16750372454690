import { setDoc, doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { processExerciseTextToAudio } from './audio';
import { listVoicesByLanguage } from './voices';
import { translateContentWithStructure } from './chatGPT';

// Function to upload an exercise if it doesn't already exist
const uploadExercise = async (language, exerciseId, exerciseData) => {
  const exerciseRef = doc(db, `content/training/${language}/${exerciseId}`);
  const docSnap = await getDoc(exerciseRef);

  // If the document does not exist, upload it
  if (!docSnap.exists()) {
    try {
      await setDoc(exerciseRef, {
        ...exerciseData,
        durationInMinutes: 5,
        imgURL: '' // Assuming you want to initialize the image URL as an empty string
      }, { merge: true }); // Using merge true to not overwrite other fields unintentionally
      console.log(`Exercise ${exerciseId} in ${language} uploaded successfully.`);
    } catch (error) {
      console.error("Error uploading exercise: ", error);
    }
  } else {
    console.log(`Exercise ${exerciseId} in ${language} already exists. Skipping upload.`);
  }
};

// Function to loop through all exercises and upload them
export const uploadAllExercises = async (exercises) => {
  for (const language in exercises) {
    for (const exerciseId in exercises[language]) {
      const exerciseData = exercises[language][exerciseId];
      await uploadExercise(language, exerciseData.id, exerciseData);
    }
  }
};

// Function to upload a theory content item if doesn't exist

const uploadTheory = async (
  language,
  contentId,
  contentData
) => {
  const contentRef = doc(db, `content/theory/${language}/${contentId}`);
  const docSnap = await getDoc(contentRef);

  let shouldGenerateAudios = false;

  if (!docSnap.exists()) {
    if (!contentData.title || !contentData.text) {
      console.warn(`Skipping content ${contentId} due to missing title or text.`);
      return;
    }

    try {
      await setDoc(contentRef, {
        ...contentData,
        durationInMinutes: 5,
        imgURL: ''
      });
      console.log(`Theory content ${contentId} in ${language} uploaded successfully.`);
      shouldGenerateAudios = true;
    } catch (error) {
      console.error("Error uploading theory content: ", error);
      return;
    }
  } else {
    console.log(`Theory content ${contentId} in ${language} already exists. Skipping upload.`);
    const audiosCollectionRef = collection(contentRef, 'audios');
    const audiosSnapshot = await getDocs(audiosCollectionRef);
    shouldGenerateAudios = audiosSnapshot.empty;
  }

  if (shouldGenerateAudios) {
    await generateAudiosForTheory(contentRef, contentData, language);
  }
};

const generateAudiosForTheory = async (
  contentRef,
  contentData,
  language
) => {
  const audiosCollectionRef = collection(contentRef, 'audios');
  const voices = await listVoicesByLanguage(language);

  for (const voice of voices) {
    const audioDocRef = doc(audiosCollectionRef, voice.name);
    const audioSnap = await getDoc(audioDocRef);

    if (!audioSnap.exists()) {
      try {
        const success = await processExerciseTextToAudio(
          contentData.text,
          language,
          contentData.day,
          true,
          voice.name,
          'theory',
          audioDocRef,
          voice.type
        );

        console.log(`Audio for ${contentRef.id} in ${language} with voice ${voice.name} created successfully: ${success}`);
      } catch (error) {
        console.error(`Error processing text to audio for voice ${voice.name}:`, error);
      }
    } else {
      console.log(`Audio already exists for ${contentRef.id} in ${language} with voice ${voice.name}. Skipping.`);
    }
  }
};

export const uploadAllTheory = async (theory) => {
  for (const lang in theory) {
    console.log(`Uploading theory for ${lang}`, theory);

    for (const contentKey in theory[lang]) {
      console.log(`Uploading theory content for ${lang}`, contentKey);
      const contentData = theory[lang][contentKey];
      await uploadTheory(lang, contentData.day, contentData);
    }
  }
};

// Helper function to introduce delay
// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const generateAudioForExercises = async (
  type,
  languageOutput,
  exerciseIds
) => {
  let idsToProcess = [];

  if (exerciseIds && exerciseIds.length > 0) {
    // Use the provided exercise IDs if any
    idsToProcess = exerciseIds;
  } else {
    // Fetch all exercise IDs from Firestore if no specific IDs are provided
    const sectionPath = `content/${type}/${languageOutput}`;
    const sectionRef = collection(db, sectionPath);
    const snapshot = await getDocs(sectionRef);
    idsToProcess = snapshot.docs.map(doc => doc.id); // Assuming doc.id is the exercise ID
  }

  for (const exerciseId of idsToProcess) {
    for (const language of [languageOutput]) {
      // Assume db is the root path to your Firestore database
      const exercisePath = `content/${type}/${language}/${exerciseId}`;
      const exerciseRef = doc(db, exercisePath);
      console.log(`Checking document reference: ${exerciseRef.path}`);

      const exerciseSnap = await getDoc(exerciseRef);

      if (exerciseSnap.exists()) {
        const exerciseData = exerciseSnap.data();
        // const voices = allVoices[language];
        const voices = await listVoicesByLanguage(language);

        console.log(`Document reference is valid for: ${exerciseRef.path}`);

        // Generate audio for each voice if it doesn't exist
        await Promise.all(voices.map(async (voice) => {

          // Manually construct the audioDocRef path
          const audioDocPath = `${exerciseRef.path}/audios/${voice.name}`;
          const audioSnap = await getDoc(doc(db, audioDocPath)); // Use doc here just to get the snapshot, not the reference

          if (!audioSnap.exists()) {
            try {
              // Process and create audio for this voice
              const success = await processExerciseTextToAudio(
                exerciseData.text,
                voice.locale,
                exerciseId.toString(),
                true,
                voice.name,
                type,
                audioDocPath, // Pass the path as a string
                voice.type
              );

              if (success) {
                console.log(`Audio URL generated for voice ${voice.name}.`);
              }

              // Save audio URL to the new subcollection
              // await setDoc(doc(db, audioDocPath), { audioURL }); // Use doc here to set the document
              console.log(`Audio for ${exerciseId} in ${language} with voice ${voice.name} created successfully.`);
            } catch (error) {
              console.error(`Error processing text to audio for voice ${voice.name}:`, error);
            }
          } else {
            console.log(`Audio already exists for ${exerciseId} in ${language} with voice ${voice.name}. Skipping.`);
          }
        }));
      } else {
        console.log(`Exercise ${exerciseId} in ${language} does not exist.`);
      }
    }
  }
};

export const translateAllContent = async (languageCode) => {
  const contentTypes = ['theory', 'training'];

  for (const contentType of contentTypes) {
    const sourceCollectionRef = collection(db, `content/${contentType}/en`);
    const sourceDocsSnapshot = await getDocs(sourceCollectionRef);
    const documentsToTranslate = sourceDocsSnapshot.docs;

    for (const docSnapshot of documentsToTranslate) {
      const contentData = docSnapshot.data();

      const { title, text } = contentData;
      console.log(`Translating content: title=${title}, text length=${text.length}, languageCode=${languageCode}`);

      // Check if the document already exists in the target language with non-empty title and text
      const targetDocRef = doc(db, `content/${contentType}/${languageCode}/${docSnapshot.id}`);
      const targetDocSnapshot = await getDoc(targetDocRef);
      if (targetDocSnapshot.exists()) {
        const targetData = targetDocSnapshot.data();
        if (targetData.title && targetData.text) {
          console.log(`Skipping document ${docSnapshot.id} in ${contentType} for ${languageCode} as it already exists with non-empty title and text.`);
          continue;
        }
      }

      // Skip the document if title or text is missing in the source language
      if (!title || !text) {
        console.warn(`Skipping document ${docSnapshot.id} in ${contentType} due to missing title or text in the source language.`);
        continue;
      }

      // Translate the content
      const translatedContent = await translateContentWithStructure(title, text, languageCode);
      console.log('translatedContent', translatedContent);

      // Ensure the translated content is valid
      if (!translatedContent || !translatedContent.title || !translatedContent.texts) {
        console.error(`Translation failed for document ${docSnapshot.id} in ${contentType}.`);
        continue;
      }

      const updatedContentData = {
        ...contentData,
        lang: languageCode,
        title: translatedContent.title,
        text: translatedContent.texts,
      };

      try {
        console.log(`Attempting to set document: ${targetDocRef.path}`);
        await setDoc(targetDocRef, updatedContentData);
        console.log(`Copied and translated ${contentType} content ${docSnapshot.id} to ${languageCode} successfully.`);
      } catch (error) {
        console.error(`Error setting document for ${docSnapshot.id} in ${contentType}:`, error);
      }
    }
  }
};

const types = ['theory', 'training'];
let languages = ['pt', 'de', 'ca', 'fr'];

export const generateAllAudioForOneLanguage = async (language) => {
  console.log(`Generating audio for all types in ${language}...`);
  for (const type of types) {
    try {
      console.log(`Generating audio for ${type} in ${language}...`);
      await generateAudioForExercises(type, language);
      console.log(`Completed audio generation for ${type} in ${language}.`);
    } catch (error) {
      console.error(`Error generating audio for ${type} in ${language}:`, error);
    }
  }
}

export const generateAudioForAllLanguagesAndTypes = async () => {
  for (const language of languages) {
    generateAllAudioForOneLanguage(language);
  }
};