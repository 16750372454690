import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, where } from 'firebase/firestore';
import { createListQuery } from './firebase';

export const getUsers = async (params) => {
  const formattedFilters = Object.entries(params.filters || {}).map(([key, value]) => {
    if (key === 'freeDemoCompleted') {
      if (value === 'Yes') {
        return where(key, '==', true);
      } else if (value === 'No') {
        return where(key, '==', false);
      }
    } else if (key === 'shop.totalRealitiesAcquired' || key === 'shop.realitiesAvailable') {
      if (value === 'Yes') {
        return where(key, '>', 0);
      } else if (value === 'No') {
        return where(key, '==', 0);
      }
    } else if (key === 'currentReality') {
      if (value === 'Yes') {
        return where(key, '!=', '');
      } else if (value === 'No') {
        return where(key, '==', '');
      }
    } else if (Array.isArray(value)) {
      return where(key, 'in', value);
    } else {
      return where(key, '==', value);
    }
  });
  const { data, ...props } = await createListQuery('users', { ...params, filters: formattedFilters });

  // Loop through data and add currentRealityDay and currentRealityLastUpdate
  const updatedData = await Promise.all(data.map(async (user) => {
    if (user.currentReality) {
      const realityData = await getRealityData(user.id, user.currentReality);
      if (realityData) {
        return {
          ...user,
          currentRealityDay: realityData.currentDay,
          currentRealityLastUpdate: realityData.updatedAt,
        };
      }
    }
    return user;
  }));

  return {
    data: updatedData,
    ...props,
  };
};

export const getRealityData = async (userId, realityId) => {
  const realityRef = doc(db, 'users', userId, 'realities', realityId);
  const realitySnapshot = await getDoc(realityRef);

  if (realitySnapshot.exists()) {
    return realitySnapshot.data();
  } else {
    return null;
  }
};

export const getUserData = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists()) {
    return null;
  }

  const realitiesRef = collection(userRef, 'realities');
  const realitiesSnapshot = await getDocs(realitiesRef);
  const realities = realitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  const userData = { id: userSnapshot.id, ...userSnapshot.data(), realities };

  return userData;
};