import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Screens
import Dashboard from "../pages/Dashboard/index"
import Users from "../pages/Users/Users"
import User from "../pages/Users/User"
import Stats from "../pages/Stats/Stats"
import StatsVoices from "../pages/StatsVoices/StatsVoices"
import StatsCountries from "../pages/StatsCountries/StatsCountries"
import Ratings from "../pages/Ratings/Ratings"
import Feedback from "../pages/Feedback/Feedback"
import Content from "../pages/Content/Content"
import Assistant from "../pages/Assistant/Assistant"
import Notifications from "../pages/Notifications/Notifications"
import PushNotifications from "../pages/PushNotifications/PushNotifications"
import Emails from "../pages/Emails/Emails"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },
  { path: "/profile", component: <UserProfile/> },
  { path: "/users", component: <Users/> },
  { path: "/user/:id", component: <User />},
  { path: "/stats", component: <Stats/> },
  { path: "/statsVoices", component: <StatsVoices />},
  { path: "/statsCountries", component: <StatsCountries />},
  { path: "/ratings", component: <Ratings /> },
  { path: "/feedback", component: <Feedback/> },
  { path: "/content", component: <Content/> },
  { path: "/assistant", component: <Assistant/> },
  { path: "/notifications", component: <Notifications/> },
  { path: "/pushNotifications", component: <PushNotifications /> },
  { path: "/emails", component: <Emails/>},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: <Navigate to="/stats" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }

