import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend, initFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import { firebaseConfig } from "../../../helpers/firebase";

// TODO: can be this removed? is on App.tsx already
initFirebaseBackend(firebaseConfig);

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      if (!fireBaseBackend) {
        throw new Error("Firebase backend is not initialized.");
      }

      const response = yield call(
        fireBaseBackend.loginUser,
        user.username,
        user.password
      );
      if (!response) {
        yield put(apiError("Invalid username or password"));
      } else {
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      let response = yield call(postJwtLogin, {
        username: user.username,
        password: user.password,
      });
      if (!response) {
        yield put(apiError("Invalid username or password"));
      } else {
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
      }

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // Fake auth logic here
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      if (!fireBaseBackend) {
        throw new Error("Firebase backend is not initialized.");
      }

      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      if (!fireBaseBackend) {
        throw new Error("Firebase backend is not initialized.");
      }

      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      if (!response) {
        yield put(apiError("Invalid username or password"));
      } else {
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
      }
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
