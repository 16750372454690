import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { appLanguages } from '../../common/languages';

export async function fetchAndFilterVoicesByLang(lang) {
  const voiceCollection = collection(db, `voices/${String(lang)}/list`);
  const voiceSnapshot = await getDocs(voiceCollection);
  const allVoices = voiceSnapshot.docs.map(doc => doc.data());
  // const enabledVoices = allVoices.filter(voice => voice.isEnabled !== false);
  return allVoices;
}

export const getVoicesList = async () => {
  const voices = {};

  for (let lang of appLanguages) {
    const enabledVoicesByLang = await fetchAndFilterVoicesByLang(lang);
    voices[String(lang)] = enabledVoicesByLang;
  }

  return voices;
};