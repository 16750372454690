import React, { useState, useEffect, useCallback } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Spinner,
} from 'reactstrap';
import { appLanguages } from '../../common/languages';
import classnames from 'classnames';
import { fetchNotificationsByLang } from '../../helpers/API/notifications';
import { langFlags } from '../../common/langFlags';

const CommercialNotifications = ({ refreshKey }) => {
  const [notifications, setNotifications] = useState({});
  const [activeTab, setActiveTab] = useState('en');
  const [collapse, setCollapse] = useState([]);
  const [isLoading, setIsLoading] = useState({});

  const fetchNotifications = useCallback(async (lang) => {
    setIsLoading(prev => ({ ...prev, [lang]: true }));
    try {
      const data = await fetchNotificationsByLang(lang, 'commercial');
      setNotifications(prev => ({ ...prev, [lang]: data }));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
    setIsLoading(prev => ({ ...prev, [lang]: false }));
  }, []);

  useEffect(() => {
    if (!notifications[activeTab]) {
      fetchNotifications(activeTab);
    }
  }, [activeTab, fetchNotifications, refreshKey]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleCollapse = (index) => {
    setCollapse(collapse => {
      const newCollapse = [...collapse];
      newCollapse[index] = !newCollapse[index];
      return newCollapse;
    });
  };

  return (
    <div className="current-notifications">
      <div className="white-block">
        <Nav tabs>
          {appLanguages.map((lang) => (
            <NavItem key={lang}>
              <NavLink
                className={classnames({ active: activeTab === lang })}
                onClick={() => toggleTab(lang)}
              >
                <img
                  src={langFlags[lang]}
                  alt={`${lang} flag`}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '5px'
                  }}
                />
                {lang.toUpperCase()}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab}>
          {appLanguages.map((lang) => (
            <TabPane key={lang} tabId={lang}>
              {isLoading[lang] ? (
                <div className="spinner-container">
                  <Spinner color="primary" />
                </div>
              ) : (
                notifications[lang]?.map((notification, index) => (
                  <Card key={notification.id}>
                    <CardHeader onClick={() => toggleCollapse(index)}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{notification.title}</span>
                        <span className="text-muted" style={{ fontSize: '0.8em' }}>
                          ID: {notification.id}
                        </span>
                      </div>
                    </CardHeader>
                    <Collapse isOpen={collapse[index]}>
                      <CardBody>
                        <div className="notification-body">{notification.body}</div>
                      </CardBody>
                    </Collapse>
                  </Card>
                ))
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default CommercialNotifications;
