export const textSamples = {
  CA: "Ola, el meu nom és [voiceName]. És un honor per a mi ser el teu assistent de manifestació. Espero poder acompanyar-te durant tot el procés i aconseguir grans coses junts.",
  ES: "Hola, mi nombre es [voiceName]. Es un honor para mí ser tu asistente de manifestación. Espero acompañarte durante todo el proceso y lograr grandes cosas juntos.",
  EN: "Hey, my name is [voiceName]. It's an honor for me to be your manifesting assistant. I am looking forward to walk with you through the whole process and achieving big things together.",
  FR: "Bonjour, je m'appelle [voiceName]. C'est un honneur pour moi d'être votre assistant de manifestation. J'ai hâte de vous accompagner tout au long du processus et de réaliser de grandes choses ensemble.",
  PT: "Olá, meu nome é [voiceName]. É uma honra para mim ser seu assistente de manifestação. Estou ansioso para caminhar com você durante todo o processo e alcançar grandes coisas juntos.",
  IT: "Ciao, mi chiamo [voiceName]. È un onore per me essere il tuo assistente di manifestazione. Non vedo l'ora di accompagnarti durante tutto il processo e raggiungere grandi obiettivi insieme.",
  DE: "Hallo, mein Name ist [voiceName]. Es ist mir eine Ehre, Ihr Manifestationsassistent zu sein. Ich freue mich darauf, Sie durch den gesamten Prozess zu begleiten und gemeinsam große Dinge zu erreichen.",
  NL: "Hallo, mijn naam is [voiceName]. Het is een eer voor mij om uw manifestatie-assistent te zijn. Ik kijk ernaar uit om u door het hele proces te begeleiden en samen grote dingen te bereiken.",
  NN: "Hei, navnet mitt er [voiceName]. Det er en ære for meg å være din manifesteringsassistent. Jeg ser frem til å følge deg gjennom hele prosessen og oppnå store ting sammen.",
  FI: "Hei, nimeni on [voiceName]. On kunnia olla manifestaatioavustajasi. Odotan innolla, että voimme kävellä yhdessä koko prosessin läpi ja saavuttaa suuria asioita yhdessä.",
  JA: "こんにちは、私の名前は[voiceName]です。あなたのマニフェストのアシスタントを務めることは私にとって光栄です。プロセス全体を通じて一緒に大きなことを成し遂げることを楽しみにしています。",
  TR: "Merhaba, benim adım [voiceName]. Sizin gerçekleştirme asistanınız olmak benim için bir onurdur. Tüm süreç boyunca sizinle yürümeyi ve birlikte büyük şeyler başarmayı dört gözle bekliyorum.",
  RU: "Привет, меня зовут [voiceName]. Для меня большая честь быть вашим помощником по проявлению. Я с нетерпением жду возможности пройти с вами весь процесс и достичь вместе больших успехов.",
  ZH: "嗨，我的名字是[voiceName]。作为你的显现助手，我感到非常荣幸。我期待着与你一起走完整个过程，共同取得伟大的成就。",
  DA: "Hej, mit navn er [voiceName]. Det er en ære for mig at være din manifesteringsassistent. Jeg ser frem til at gå med dig gennem hele processen og opnå store ting sammen.",
  PL: "Cześć, nazywam się [voiceName]. To dla mnie zaszczyt być twoim asystentem manifestacji. Cieszę się, że mogę towarzyszyć Ci w całym procesie i wspólnie osiągać wielkie rzeczy.",
  SV: "Hej, mitt namn är [voiceName]. Det är en ära för mig att vara din manifestationsassistent. Jag ser fram emot att gå med dig genom hela processen och uppnå stora saker tillsammans.",
  AR: "مرحبًا، اسمي [voiceName]. يشرفني أن أكون مساعدك في التجلي. أتطلع إلى السير معك خلال العملية بأكملها وتحقيق أشياء عظيمة معًا."
};
