import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getFirebaseBackend } from '../helpers/firebase_helper';

const Authmiddleware = (props) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const backend = getFirebaseBackend();
    console.log('backend', backend);

    const authUser = backend.getAuthenticatedUser();
    console.log('authUser', authUser);
    if (authUser) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return authenticated ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  );
};

export default Authmiddleware;