import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"

const languages = {
  sp: {
    label: "Spanish",
    flag: spain,
  },
  gr: {
    label: "German",
    flag: germany,
  },
  it: {
    label: "Italian",
    flag: italy,
  },
  rs: {
    label: "Russian",
    flag: russia,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export const appLanguages = ['en', 'es', 'ca', 'pt', 'fr', 'de', 'fi', 'it', 'tr', 'nn', 'ru', 'nl', 'da', 'pl', 'ar', 'sv', 'zh', 'ja'];

export const localeMap = {
  en: ['en-US', 'en-GB'],
  es: ['es-ES', 'es-MX', 'es-AR', 'es-CO'],
  ca: ['ca-ES'],
  pt: ['pt-PT', 'pt-BR'],
  fr: ['fr-FR', 'fr-CA'],
  de: ['de-DE'],
  zh: ['zh-CN', 'zh-TW'],
  ja: ['ja-JP'],
  it: ['it-IT'],
  ru: ['ru-RU'],
  tr: ['tr-TR'],
  nl: ['nl-NL'],
  da: ['da-DK'],
  nn: ['nn-NO'],
  fi: ['fi-FI'],
  pl: ['pl-PL'],
  ar: ['ar-SA'],
  sv: ['sv-SE'],
};

// export const appLanguages = ['en', 'es', 'ca', 'pt', 'fr', 'de', 'zh', 'ja', 'it', 'ru', 'tr', 'nl', 'da', 'nn', 'fi', 'pl'];
export default languages;

const languageMap = {
  en: "English",
  es: "Spanish",
  ca: "Catalan",
  pt: "Portuguese",
  fr: "French",
  de: "German",
  zh: "Chinese",
  ja: "Japanese",
  it: "Italian",
  ru: "Russian",
  tr: "Turkish",
  nl: "Dutch",
  da: "Danish",
  nn: "Norwegian",
  fi: "Finnish",
  pl: "Polish",
  ar: "Arabic",
  sv: "Swedish",
};

export const getLanguageName = (langCode) => languageMap[langCode] || langCode;
