import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  TOGGLE_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CLEAR_ERROR,
} from "./actionTypes"
import { tableDefaultState } from "../common/misc.js"

const INIT_STATE = {
  tableData: tableDefaultState,
  error: {},
  loading: false,
  user: {},
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        tableData: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      }

    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      }

    default:
      return state
  }
}

export default users
