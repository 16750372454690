import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  TOGGLE_LOADING,
  GET_USER_BY_ID,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CLEAR_ERROR,
} from "./actionTypes"

// Params contains the filters, sortBy, pageSize, pageIndex, etc.
export const getUsers = params => ({
  type: GET_USERS,
  payload: params,
})

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const toggleUsersLoading = () => ({
  type: TOGGLE_LOADING,
})

export const getUser = userId => ({
  type: GET_USER_BY_ID,
  payload: userId,
})

export const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: data,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

// export const addNewUser = user => ({
//   type: ADD_NEW_USER,
//   payload: user,
// })

// export const addUserSuccess = user => ({
//   type: ADD_USER_SUCCESS,
//   payload: user,
// })

// export const addUserFail = error => ({
//   type: ADD_USER_FAIL,
//   payload: error,
// })



// export const getUserProfile = () => ({
//   type: GET_USER_PROFILE,
// })

// export const getUserProfileSuccess = userProfile => ({
//   type: GET_USER_PROFILE_SUCCESS,
//   payload: userProfile,
// })

// export const getUserProfileFail = error => ({
//   type: GET_USER_PROFILE_FAIL,
//   payload: error,
// })

// export const updateUser = user => ({
//   type: UPDATE_USER,
//   payload: user,
// })

// export const updateUserSuccess = user => ({
//   type: UPDATE_USER_SUCCESS,
//   payload: user,
// })

// export const updateUserFail = error => ({
//   type: UPDATE_USER_FAIL,
//   payload: error,
// })

// export const deleteUser = user => ({
//   type: DELETE_USER,
//   payload: user,
// })

// export const deleteUserSuccess = user => ({
//   type: DELETE_USER_SUCCESS,
//   payload: user,
// })

// export const deleteUserFail = error => ({
//   type: DELETE_USER_FAIL,
//   payload: error,
// })


