import React from 'react';
import firebaseLogo from '../../assets/images/firebase.svg';

const GoToFirebase = ({ userId }) => {
  const firebaseUrl = `https://console.firebase.google.com/u/0/project/nevilize-prod/firestore/databases/-default-/data/~2Fusers~2F${userId}`;

  return (
    <div className="firebase-link-container">
      <a href={firebaseUrl} target="_blank" rel="noopener noreferrer">
        <img src={firebaseLogo} alt="Firebase" className="firebase-logo" />
      </a>
    </div>
  );
};

export default GoToFirebase;
