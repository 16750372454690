import React, { useState, useEffect } from 'react';
import { Button, InputGroup, InputGroupText, Input, FormFeedback, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { appLanguages } from '../../common/languages';
import { saveNotification } from '../../helpers/API/notifications';

const notificationTypes = [
  { value: 'commercial', label: 'Commercial' },
  // Add more notification types here as needed
];

const SelectField = ({ id, label, options, formik, onChange }) => (
  <InputGroup className="inputGroupStyle">
    <InputGroupText addonType="prepend">{label}</InputGroupText>
    <Input
      id={id}
      type="select"
      {...formik.getFieldProps(id)}
      onChange={(e) => {
        formik.setFieldValue(id, e.target.value);
        formik.setFieldTouched(id, true, false);
        if (onChange) onChange(e);
      }}
      invalid={formik.touched[id] && formik.errors[id] ? true : false}
    >
      <option value="">Select {label}</option>
      {options.map((option, index) => (
        <option key={index} value={option.value || option}>
          {option.label || option}
        </option>
      ))}
    </Input>
    {formik.touched[id] && formik.errors[id] ? (
      <FormFeedback>{formik.errors[id]}</FormFeedback>
    ) : null}
  </InputGroup>
);

const AddNotification = ({ refreshNotifications }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const formik = useFormik({
    initialValues: {
      type: 'commercial',
      title: '',
      body: '',
      lang: 'en',
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      body: Yup.string().required('Required'),
      lang: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const notification = {
        title: values.title,
        body: values.body,
      };

      setIsSubmitting(true);
      try {
        await saveNotification(notification, values.lang, values.type);
        refreshNotifications();
        resetForm();
        setErrorMessage('');
      } catch (error) {
        console.error("Error saving notification:", error);
        setErrorMessage("Failed to save notification. Please try again.");
      }
      setIsSubmitting(false);
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const renderInputGroup = (id, label, type = 'text', placeholder = '') => (
    <InputGroup className="inputGroupStyle">
      <InputGroupText addonType="prepend">{label}</InputGroupText>
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        {...formik.getFieldProps(id)}
        invalid={formik.touched[id] && formik.errors[id] ? true : false}
      />
      {formik.touched[id] && formik.errors[id] ? (
        <FormFeedback>{formik.errors[id]}</FormFeedback>
      ) : null}
    </InputGroup>
  );

  return (
    <form onSubmit={formik.handleSubmit} className="addNotificationForm">
      <SelectField
        id="type"
        label="Type"
        options={notificationTypes}
        formik={formik}
      />
      {renderInputGroup('title', 'Title')}
      <InputGroup className="inputGroupStyle">
        <InputGroupText addonType="prepend">Body</InputGroupText>
        <Input
          id="body"
          type="textarea"
          {...formik.getFieldProps('body')}
          invalid={formik.touched.body && formik.errors.body ? true : false}
        />
        {formik.touched.body && formik.errors.body ? (
          <FormFeedback>{formik.errors.body}</FormFeedback>
        ) : null}
      </InputGroup>
      <SelectField
        id="lang"
        label="Language"
        options={appLanguages.map(lang => ({ value: lang, label: lang.toUpperCase() }))}
        formik={formik}
      />
      <Button
        type="submit"
        className="addButton"
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting || isSubmitting}
        style={{ width: '100%' }}
      >
        {isSubmitting ? (
          <Spinner size="sm" color="light" />
        ) : (
          <>
            <i className="bx bx-plus"></i>
            Save Notification
          </>
        )}
      </Button>
      {errorMessage && (
        <div className="errorMessage">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default AddNotification;
