import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const useTableData = (actionCreator, initialState) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const urlUserId = query.get('userId');
  const urlRealityId = query.get('realityId');

  const { loading, tableData } = useSelector(state => state[initialState.dataKey]);
  const {
    data,
    totalItems,
    totalPages,
    pageSize,
    pageIndex,
    filters,
    lastVisible,
  } = tableData;
  const sortBy = tableData.sortBy;

  const fetchNewData = useCallback((props = {}) => {
    let params = attachFilters({ ...props, filters: props.filters || [] });

    if (params.filters) {
      let newFilters = {};
      Object.keys(params.filters).forEach((key) => {
        newFilters[key] = params.filters[key];
      });
      params.filters = newFilters;
    }

    dispatch(actionCreator(params));
  }, [dispatch, actionCreator]);

  const setFilters = (filters = []) => {
    fetchNewData({ filters });
  };

  useEffect(() => {
    fetchNewData();
  }, []);

  useEffect(() => {
    if (urlUserId) {
      updateFilters('userId', urlUserId);
    }
  }, [urlUserId]);

  useEffect(() => {
    if (urlRealityId) {
      updateFilters('realityId', urlRealityId);
    }
  }, [urlRealityId]);

  const setPageSize = (size) => {
    fetchNewData({ pageSize: size, pageIndex: 0 });
  }

  const setPageIndex = (index) => {
    fetchNewData({ pageIndex: index, lastVisible });
  }

  const setSortBy = (sort) => {
    let sortOrder =  -1;
    let newSortProps = { 'createdAt': -1 };

    if (sort[0]) {
      sortOrder = sort[0] && sort[0].desc ? -1 : 1;
      newSortProps = { [sort[0].id]: sortOrder };
    } else if (filters.length > 0) {
      console.log();
      newSortProps = {};
    }

    fetchNewData({ sortBy: newSortProps });
  }

  const debouncedSetFilters = debounce((filters) => setFilters(filters), 400);

  const processDebounce = (filters) => {
    // if last added filter is email, name or id, then debounce, if not, not
    if (filters.length > 0) {
      const lastFilter = filters[filters.length - 1];
      const filterId = lastFilter && lastFilter.id;
      const filterBounced = ['email', 'firstName', 'id', 'lastName'];

      if (filterBounced.includes(filterId)) {
        debouncedSetFilters(filters);
      } else {
        setFilters(filters);
      }
    } else {
      setFilters([]);
    }
  }

  const attachFilters = (params) => {
    const { pageSize, pageIndex, sortBy, filters = [] } = params; // Default to an empty array
    const newFilters = {};

    if (Array.isArray(filters) && filters.length > 0) {
      filters.forEach((filter) => {
        newFilters[filter.id] = filter.value;
      });
    }

    return {
      ...params,
      ...(Object.keys(newFilters).length > 0 && { filters: newFilters }),
      ...(pageSize !== undefined && { pageSize }),
      ...(pageIndex >= 0 && { pageIndex }),
      ...(sortBy && sortBy.length > 0 && { sortBy }),
    };
  };

  const updateFilters = (columnId, value) => {
    const newFilters = filters.map((filter) => {
      if (filter.id === columnId) {
        return { id: columnId, value };
      }
      return filter;
    });
    setFilters(newFilters);
  };

  return {
    data,
    totalItems,
    loading,
    filters,
    pageSize,
    pageIndex,
    totalPages,
    sortBy,
    setFilters: processDebounce,
    setPageSize,
    setPageIndex,
    setSortBy,
    refreshData: fetchNewData,
    updateFilters,
    lastVisible
  };
};

export default useTableData;