const Config = {
  chatGPTToken: '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',
  chatGPTModel: 'gpt-4o',
  revenueCatProjectId: '01b8cc68',
  revenueCatAPIKey: 'sk_pFTekqcACJDvAtisJmlIJkSTLkGIz',
  google: {
    API_KEY: "AIzaSyCVZIx8hKLSlUjL4TpYrH5VkUqrmMgUopM",
    CLIENT_ID: "780716686945-u3bt47mfq062o6ocepcvvphn23mbjb67.apps.googleusercontent.com",
    SECRET: "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",
  },
  googleProd: {
    API_KEY: "AIzaSyCNE6bLJeYl5ttFsm7f__GhURpxeifpsLc",
    CLIENT_ID: "240623701762-mqf8b922v5rb6heu5dobu0rt7ohca7u3.apps.googleusercontent.com",
    SECRET: "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",
  },
  firebaseRegion: "europe-west3",
  firebaseConfig_DEV: {
    apiKey: "AIzaSyCVZIx8hKLSlUjL4TpYrH5VkUqrmMgUopM",
    authDomain: "nevilize-dev.firebaseapp.com",
    projectId: "nevilize-dev",
    storageBucket: "nevilize-dev.appspot.com",
    messagingSenderId: "780716686945",
    appId: "1:780716686945:web:fbe80f604411054a643420",
    measurementId: "G-RTRHR7NS1Q"
  },
  firebaseConfig_PROD: {
    apiKey: "AIzaSyCNE6bLJeYl5ttFsm7f__GhURpxeifpsLc",
    authDomain: "nevilize-prod.firebaseapp.com",
    projectId: "nevilize-prod",
    storageBucket: "nevilize-prod.appspot.com",
    messagingSenderId: "240623701762",
    appId: "1:240623701762:web:477c3aacc5e223b1bbfc1b",
    measurementId: "G-KM3SQKMPZ0"
  }
};

export default Config;