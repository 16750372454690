import React, { useState } from 'react';

const CopyToClipboard = ({ textToCopy, label }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch(err => console.error('Failed to copy text:', err));
  };

  return (
    <div className="copy-container">
      {label && <span className="copy-label">{label}:</span>}
      <span className="copy-text">{textToCopy}</span>
      <button className="copy-button" onClick={handleCopy}>
        <i className="fa fa-copy copy-icon"></i> {/* Using Font Awesome icon */}
      </button>
      {isCopied && <span className="copy-tooltip">Copied!</span>}
    </div>
  );
};

export default CopyToClipboard;
