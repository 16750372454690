import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchEmailsSuccess,
  fetchEmailsFailure,
} from './actions';
import { FETCH_EMAILS_REQUEST } from './actionTypes';
import { fetchEmailsFromFirestore } from '../../helpers/API/emails';

function* fetchEmailsSaga({ payload }) {
  try {
    const users = yield call(fetchEmailsFromFirestore, payload);
    yield put(fetchEmailsSuccess(users));
  } catch (error) {
    yield put(fetchEmailsFailure(error));
  }
}

function* emailsSaga() {
  yield takeEvery(FETCH_EMAILS_REQUEST, fetchEmailsSaga);
}

export default emailsSaga;
