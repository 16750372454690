import flag_af from '../assets/images/allFlags/af.svg';
import flag_ax from '../assets/images/allFlags/ax.svg';
import flag_al from '../assets/images/allFlags/al.svg';
import flag_dz from '../assets/images/allFlags/dz.svg';
import flag_as from '../assets/images/allFlags/as.svg';
import flag_ad from '../assets/images/allFlags/ad.svg';
import flag_ao from '../assets/images/allFlags/ao.svg';
import flag_ai from '../assets/images/allFlags/ai.svg';
import flag_aq from '../assets/images/allFlags/aq.svg';
import flag_ag from '../assets/images/allFlags/ag.svg';
import flag_ar from '../assets/images/allFlags/ar.svg';
import flag_am from '../assets/images/allFlags/am.svg';
import flag_aw from '../assets/images/allFlags/aw.svg';
import flag_au from '../assets/images/allFlags/au.svg';
import flag_at from '../assets/images/allFlags/at.svg';
import flag_az from '../assets/images/allFlags/az.svg';
import flag_bs from '../assets/images/allFlags/bs.svg';
import flag_bh from '../assets/images/allFlags/bh.svg';
import flag_bd from '../assets/images/allFlags/bd.svg';
import flag_bb from '../assets/images/allFlags/bb.svg';
import flag_by from '../assets/images/allFlags/by.svg';
import flag_be from '../assets/images/allFlags/be.svg';
import flag_bz from '../assets/images/allFlags/bz.svg';
import flag_bj from '../assets/images/allFlags/bj.svg';
import flag_bm from '../assets/images/allFlags/bm.svg';
import flag_bt from '../assets/images/allFlags/bt.svg';
import flag_bo from '../assets/images/allFlags/bo.svg';
import flag_bq from '../assets/images/allFlags/bq.svg';
import flag_ba from '../assets/images/allFlags/ba.svg';
import flag_bw from '../assets/images/allFlags/bw.svg';
import flag_bv from '../assets/images/allFlags/bv.svg';
import flag_br from '../assets/images/allFlags/br.svg';
import flag_io from '../assets/images/allFlags/io.svg';
import flag_bn from '../assets/images/allFlags/bn.svg';
import flag_bg from '../assets/images/allFlags/bg.svg';
import flag_bf from '../assets/images/allFlags/bf.svg';
import flag_bi from '../assets/images/allFlags/bi.svg';
import flag_cv from '../assets/images/allFlags/cv.svg';
import flag_kh from '../assets/images/allFlags/kh.svg';
import flag_cm from '../assets/images/allFlags/cm.svg';
import flag_ca from '../assets/images/allFlags/ca.svg';
import flag_ky from '../assets/images/allFlags/ky.svg';
import flag_cf from '../assets/images/allFlags/cf.svg';
import flag_td from '../assets/images/allFlags/td.svg';
import flag_cl from '../assets/images/allFlags/cl.svg';
import flag_cn from '../assets/images/allFlags/cn.svg';
import flag_cx from '../assets/images/allFlags/cx.svg';
import flag_cc from '../assets/images/allFlags/cc.svg';
import flag_co from '../assets/images/allFlags/co.svg';
import flag_km from '../assets/images/allFlags/km.svg';
import flag_ck from '../assets/images/allFlags/ck.svg';
import flag_cr from '../assets/images/allFlags/cr.svg';
import flag_hr from '../assets/images/allFlags/hr.svg';
import flag_cu from '../assets/images/allFlags/cu.svg';
import flag_cw from '../assets/images/allFlags/cw.svg';
import flag_cy from '../assets/images/allFlags/cy.svg';
import flag_cz from '../assets/images/allFlags/cz.svg';
import flag_ci from '../assets/images/allFlags/ci.svg';
import flag_cd from '../assets/images/allFlags/cd.svg';
import flag_dk from '../assets/images/allFlags/dk.svg';
import flag_dj from '../assets/images/allFlags/dj.svg';
import flag_dm from '../assets/images/allFlags/dm.svg';
import flag_do from '../assets/images/allFlags/do.svg';
import flag_ec from '../assets/images/allFlags/ec.svg';
import flag_eg from '../assets/images/allFlags/eg.svg';
import flag_sv from '../assets/images/allFlags/sv.svg';
import flag_gq from '../assets/images/allFlags/gq.svg';
import flag_er from '../assets/images/allFlags/er.svg';
import flag_ee from '../assets/images/allFlags/ee.svg';
import flag_sz from '../assets/images/allFlags/sz.svg';
import flag_et from '../assets/images/allFlags/et.svg';
import flag_fk from '../assets/images/allFlags/fk.svg';
import flag_fo from '../assets/images/allFlags/fo.svg';
import flag_fm from '../assets/images/allFlags/fm.svg';
import flag_fj from '../assets/images/allFlags/fj.svg';
import flag_fi from '../assets/images/allFlags/fi.svg';
import flag_fr from '../assets/images/allFlags/fr.svg';
import flag_gf from '../assets/images/allFlags/gf.svg';
import flag_pf from '../assets/images/allFlags/pf.svg';
import flag_tf from '../assets/images/allFlags/tf.svg';
import flag_ga from '../assets/images/allFlags/ga.svg';
import flag_gm from '../assets/images/allFlags/gm.svg';
import flag_ge from '../assets/images/allFlags/ge.svg';
import flag_de from '../assets/images/allFlags/de.svg';
import flag_gh from '../assets/images/allFlags/gh.svg';
import flag_gi from '../assets/images/allFlags/gi.svg';
import flag_gr from '../assets/images/allFlags/gr.svg';
import flag_gl from '../assets/images/allFlags/gl.svg';
import flag_gd from '../assets/images/allFlags/gd.svg';
import flag_gp from '../assets/images/allFlags/gp.svg';
import flag_gu from '../assets/images/allFlags/gu.svg';
import flag_gt from '../assets/images/allFlags/gt.svg';
import flag_gg from '../assets/images/allFlags/gg.svg';
import flag_gn from '../assets/images/allFlags/gn.svg';
import flag_gw from '../assets/images/allFlags/gw.svg';
import flag_gy from '../assets/images/allFlags/gy.svg';
import flag_ht from '../assets/images/allFlags/ht.svg';
import flag_hm from '../assets/images/allFlags/hm.svg';
import flag_va from '../assets/images/allFlags/va.svg';
import flag_hn from '../assets/images/allFlags/hn.svg';
import flag_hk from '../assets/images/allFlags/hk.svg';
import flag_hu from '../assets/images/allFlags/hu.svg';
import flag_is from '../assets/images/allFlags/is.svg';
import flag_in from '../assets/images/allFlags/in.svg';
import flag_id from '../assets/images/allFlags/id.svg';
import flag_ir from '../assets/images/allFlags/ir.svg';
import flag_iq from '../assets/images/allFlags/iq.svg';
import flag_ie from '../assets/images/allFlags/ie.svg';
import flag_im from '../assets/images/allFlags/im.svg';
import flag_il from '../assets/images/allFlags/il.svg';
import flag_it from '../assets/images/allFlags/it.svg';
import flag_jm from '../assets/images/allFlags/jm.svg';
import flag_jp from '../assets/images/allFlags/jp.svg';
import flag_je from '../assets/images/allFlags/je.svg';
import flag_jo from '../assets/images/allFlags/jo.svg';
import flag_kz from '../assets/images/allFlags/kz.svg';
import flag_ke from '../assets/images/allFlags/ke.svg';
import flag_ki from '../assets/images/allFlags/ki.svg';
import flag_kw from '../assets/images/allFlags/kw.svg';
import flag_kg from '../assets/images/allFlags/kg.svg';
import flag_la from '../assets/images/allFlags/la.svg';
import flag_lv from '../assets/images/allFlags/lv.svg';
import flag_lb from '../assets/images/allFlags/lb.svg';
import flag_ls from '../assets/images/allFlags/ls.svg';
import flag_lr from '../assets/images/allFlags/lr.svg';
import flag_ly from '../assets/images/allFlags/ly.svg';
import flag_li from '../assets/images/allFlags/li.svg';
import flag_lt from '../assets/images/allFlags/lt.svg';
import flag_lu from '../assets/images/allFlags/lu.svg';
import flag_mo from '../assets/images/allFlags/mo.svg';
import flag_mg from '../assets/images/allFlags/mg.svg';
import flag_mw from '../assets/images/allFlags/mw.svg';
import flag_my from '../assets/images/allFlags/my.svg';
import flag_mv from '../assets/images/allFlags/mv.svg';
import flag_ml from '../assets/images/allFlags/ml.svg';
import flag_mt from '../assets/images/allFlags/mt.svg';
import flag_mh from '../assets/images/allFlags/mh.svg';
import flag_mq from '../assets/images/allFlags/mq.svg';
import flag_mr from '../assets/images/allFlags/mr.svg';
import flag_mu from '../assets/images/allFlags/mu.svg';
import flag_yt from '../assets/images/allFlags/yt.svg';
import flag_mx from '../assets/images/allFlags/mx.svg';
import flag_md from '../assets/images/allFlags/md.svg';
import flag_mc from '../assets/images/allFlags/mc.svg';
import flag_mn from '../assets/images/allFlags/mn.svg';
import flag_me from '../assets/images/allFlags/me.svg';
import flag_ms from '../assets/images/allFlags/ms.svg';
import flag_ma from '../assets/images/allFlags/ma.svg';
import flag_mz from '../assets/images/allFlags/mz.svg';
import flag_mm from '../assets/images/allFlags/mm.svg';
import flag_na from '../assets/images/allFlags/na.svg';
import flag_nr from '../assets/images/allFlags/nr.svg';
import flag_np from '../assets/images/allFlags/np.svg';
import flag_nl from '../assets/images/allFlags/nl.svg';
import flag_nc from '../assets/images/allFlags/nc.svg';
import flag_nz from '../assets/images/allFlags/nz.svg';
import flag_ni from '../assets/images/allFlags/ni.svg';
import flag_ne from '../assets/images/allFlags/ne.svg';
import flag_ng from '../assets/images/allFlags/ng.svg';
import flag_nu from '../assets/images/allFlags/nu.svg';
import flag_nf from '../assets/images/allFlags/nf.svg';
import flag_kp from '../assets/images/allFlags/kp.svg';
import flag_mk from '../assets/images/allFlags/mk.svg';
import flag_mp from '../assets/images/allFlags/mp.svg';
import flag_no from '../assets/images/allFlags/no.svg';
import flag_om from '../assets/images/allFlags/om.svg';
import flag_pk from '../assets/images/allFlags/pk.svg';
import flag_pw from '../assets/images/allFlags/pw.svg';
import flag_pa from '../assets/images/allFlags/pa.svg';
import flag_pg from '../assets/images/allFlags/pg.svg';
import flag_py from '../assets/images/allFlags/py.svg';
import flag_pe from '../assets/images/allFlags/pe.svg';
import flag_ph from '../assets/images/allFlags/ph.svg';
import flag_pn from '../assets/images/allFlags/pn.svg';
import flag_pl from '../assets/images/allFlags/pl.svg';
import flag_pt from '../assets/images/allFlags/pt.svg';
import flag_pr from '../assets/images/allFlags/pr.svg';
import flag_qa from '../assets/images/allFlags/qa.svg';
import flag_cg from '../assets/images/allFlags/cg.svg';
import flag_ro from '../assets/images/allFlags/ro.svg';
import flag_ru from '../assets/images/allFlags/ru.svg';
import flag_rw from '../assets/images/allFlags/rw.svg';
import flag_re from '../assets/images/allFlags/re.svg';
import flag_bl from '../assets/images/allFlags/bl.svg';
import flag_sh from '../assets/images/allFlags/sh.svg';
import flag_kn from '../assets/images/allFlags/kn.svg';
import flag_lc from '../assets/images/allFlags/lc.svg';
import flag_mf from '../assets/images/allFlags/mf.svg';
import flag_pm from '../assets/images/allFlags/pm.svg';
import flag_vc from '../assets/images/allFlags/vc.svg';
import flag_ws from '../assets/images/allFlags/ws.svg';
import flag_sm from '../assets/images/allFlags/sm.svg';
import flag_st from '../assets/images/allFlags/st.svg';
import flag_sa from '../assets/images/allFlags/sa.svg';
import flag_sn from '../assets/images/allFlags/sn.svg';
import flag_rs from '../assets/images/allFlags/rs.svg';
import flag_sc from '../assets/images/allFlags/sc.svg';
import flag_sl from '../assets/images/allFlags/sl.svg';
import flag_sg from '../assets/images/allFlags/sg.svg';
import flag_sx from '../assets/images/allFlags/sx.svg';
import flag_sk from '../assets/images/allFlags/sk.svg';
import flag_si from '../assets/images/allFlags/si.svg';
import flag_sb from '../assets/images/allFlags/sb.svg';
import flag_so from '../assets/images/allFlags/so.svg';
import flag_za from '../assets/images/allFlags/za.svg';
import flag_gs from '../assets/images/allFlags/gs.svg';
import flag_kr from '../assets/images/allFlags/kr.svg';
import flag_ss from '../assets/images/allFlags/ss.svg';
import flag_es from '../assets/images/allFlags/es.svg';
import flag_lk from '../assets/images/allFlags/lk.svg';
import flag_ps from '../assets/images/allFlags/ps.svg';
import flag_sd from '../assets/images/allFlags/sd.svg';
import flag_sr from '../assets/images/allFlags/sr.svg';
import flag_sj from '../assets/images/allFlags/sj.svg';
import flag_se from '../assets/images/allFlags/se.svg';
import flag_ch from '../assets/images/allFlags/ch.svg';
import flag_sy from '../assets/images/allFlags/sy.svg';
import flag_tw from '../assets/images/allFlags/tw.svg';
import flag_tj from '../assets/images/allFlags/tj.svg';
import flag_tz from '../assets/images/allFlags/tz.svg';
import flag_th from '../assets/images/allFlags/th.svg';
import flag_tl from '../assets/images/allFlags/tl.svg';
import flag_tg from '../assets/images/allFlags/tg.svg';
import flag_tk from '../assets/images/allFlags/tk.svg';
import flag_to from '../assets/images/allFlags/to.svg';
import flag_tt from '../assets/images/allFlags/tt.svg';
import flag_tn from '../assets/images/allFlags/tn.svg';
import flag_tm from '../assets/images/allFlags/tm.svg';
import flag_tc from '../assets/images/allFlags/tc.svg';
import flag_tv from '../assets/images/allFlags/tv.svg';
import flag_tr from '../assets/images/allFlags/tr.svg';
import flag_ug from '../assets/images/allFlags/ug.svg';
import flag_ua from '../assets/images/allFlags/ua.svg';
import flag_ae from '../assets/images/allFlags/ae.svg';
import flag_gb from '../assets/images/allFlags/gb.svg';
import flag_um from '../assets/images/allFlags/um.svg';
import flag_us from '../assets/images/allFlags/us.svg';
import flag_uy from '../assets/images/allFlags/uy.svg';
import flag_uz from '../assets/images/allFlags/uz.svg';
import flag_vu from '../assets/images/allFlags/vu.svg';
import flag_ve from '../assets/images/allFlags/ve.svg';
import flag_vn from '../assets/images/allFlags/vn.svg';
import flag_vg from '../assets/images/allFlags/vg.svg';
import flag_vi from '../assets/images/allFlags/vi.svg';
import flag_wf from '../assets/images/allFlags/wf.svg';
import flag_eh from '../assets/images/allFlags/eh.svg';
import flag_ye from '../assets/images/allFlags/ye.svg';
import flag_zm from '../assets/images/allFlags/zm.svg';
import flag_zw from '../assets/images/allFlags/zw.svg';
import flag_sh_ac from '../assets/images/allFlags/sh-ac.svg';
import flag_es_pv from '../assets/images/allFlags/es-pv.svg';
import flag_ic from '../assets/images/allFlags/ic.svg';
import flag_es_ct from '../assets/images/allFlags/es-ct.svg';
import flag_cefta from '../assets/images/allFlags/cefta.svg';
import flag_cp from '../assets/images/allFlags/cp.svg';
import flag_dg from '../assets/images/allFlags/dg.svg';
import flag_eac from '../assets/images/allFlags/eac.svg';
import flag_gb_eng from '../assets/images/allFlags/gb-eng.svg';
import flag_eu from '../assets/images/allFlags/eu.svg';
import flag_es_ga from '../assets/images/allFlags/es-ga.svg';
import flag_xk from '../assets/images/allFlags/xk.svg';
import flag_arab from '../assets/images/allFlags/arab.svg';
import flag_gb_nir from '../assets/images/allFlags/gb-nir.svg';
import flag_pc from '../assets/images/allFlags/pc.svg';
import flag_sh_hl from '../assets/images/allFlags/sh-hl.svg';
import flag_gb_sct from '../assets/images/allFlags/gb-sct.svg';
import flag_sh_ta from '../assets/images/allFlags/sh-ta.svg';
import flag_un from '../assets/images/allFlags/un.svg';
import flag_xx from '../assets/images/allFlags/xx.svg';
import flag_gb_wls from '../assets/images/allFlags/gb-wls.svg';
import flag_bc from '../assets/images/allFlags/bc.svg';

// Exporting all flags in the countryFlags object
export const allFlags = {
  af: flag_af,
  ax: flag_ax,
  al: flag_al,
  dz: flag_dz,
  as: flag_as,
  ad: flag_ad,
  ao: flag_ao,
  ai: flag_ai,
  aq: flag_aq,
  ag: flag_ag,
  ar: flag_ar,
  am: flag_am,
  aw: flag_aw,
  au: flag_au,
  at: flag_at,
  az: flag_az,
  bc: flag_bc,
  bs: flag_bs,
  bh: flag_bh,
  bd: flag_bd,
  bb: flag_bb,
  by: flag_by,
  be: flag_be,
  bz: flag_bz,
  bj: flag_bj,
  bm: flag_bm,
  bt: flag_bt,
  bo: flag_bo,
  bq: flag_bq,
  ba: flag_ba,
  bw: flag_bw,
  bv: flag_bv,
  br: flag_br,
  io: flag_io,
  bn: flag_bn,
  bg: flag_bg,
  bf: flag_bf,
  bi: flag_bi,
  cv: flag_cv,
  kh: flag_kh,
  cm: flag_cm,
  ca: flag_ca,
  ky: flag_ky,
  cf: flag_cf,
  td: flag_td,
  cl: flag_cl,
  cn: flag_cn,
  cx: flag_cx,
  cc: flag_cc,
  co: flag_co,
  km: flag_km,
  ck: flag_ck,
  cr: flag_cr,
  hr: flag_hr,
  cu: flag_cu,
  cw: flag_cw,
  cy: flag_cy,
  cz: flag_cz,
  ci: flag_ci,
  cd: flag_cd,
  dk: flag_dk,
  dj: flag_dj,
  dm: flag_dm,
  do: flag_do,
  ec: flag_ec,
  eg: flag_eg,
  sv: flag_sv,
  gq: flag_gq,
  er: flag_er,
  ee: flag_ee,
  sz: flag_sz,
  et: flag_et,
  fk: flag_fk,
  fo: flag_fo,
  fm: flag_fm,
  fj: flag_fj,
  fi: flag_fi,
  fr: flag_fr,
  gf: flag_gf,
  pf: flag_pf,
  tf: flag_tf,
  ga: flag_ga,
  gm: flag_gm,
  ge: flag_ge,
  de: flag_de,
  gh: flag_gh,
  gi: flag_gi,
  gr: flag_gr,
  gl: flag_gl,
  gd: flag_gd,
  gp: flag_gp,
  gu: flag_gu,
  gt: flag_gt,
  gg: flag_gg,
  gn: flag_gn,
  gw: flag_gw,
  gy: flag_gy,
  ht: flag_ht,
  hm: flag_hm,
  va: flag_va,
  hn: flag_hn,
  hk: flag_hk,
  hu: flag_hu,
  is: flag_is,
  in: flag_in,
  id: flag_id,
  ir: flag_ir,
  iq: flag_iq,
  ie: flag_ie,
  im: flag_im,
  il: flag_il,
  it: flag_it,
  jm: flag_jm,
  jp: flag_jp,
  je: flag_je,
  jo: flag_jo,
  kz: flag_kz,
  ke: flag_ke,
  ki: flag_ki,
  kw: flag_kw,
  kg: flag_kg,
  la: flag_la,
  lv: flag_lv,
  lb: flag_lb,
  ls: flag_ls,
  lr: flag_lr,
  ly: flag_ly,
  li: flag_li,
  lt: flag_lt,
  lu: flag_lu,
  mo: flag_mo,
  mg: flag_mg,
  mw: flag_mw,
  my: flag_my,
  mv: flag_mv,
  ml: flag_ml,
  mt: flag_mt,
  mh: flag_mh,
  mq: flag_mq,
  mr: flag_mr,
  mu: flag_mu,
  yt: flag_yt,
  mx: flag_mx,
  md: flag_md,
  mc: flag_mc,
  mn: flag_mn,
  me: flag_me,
  ms: flag_ms,
  ma: flag_ma,
  mz: flag_mz,
  mm: flag_mm,
  na: flag_na,
  nr: flag_nr,
  np: flag_np,
  nl: flag_nl,
  nc: flag_nc,
  nz: flag_nz,
  ni: flag_ni,
  ne: flag_ne,
  ng: flag_ng,
  nu: flag_nu,
  nf: flag_nf,
  kp: flag_kp,
  mk: flag_mk,
  mp: flag_mp,
  no: flag_no,
  om: flag_om,
  pk: flag_pk,
  pw: flag_pw,
  pa: flag_pa,
  pg: flag_pg,
  py: flag_py,
  pe: flag_pe,
  ph: flag_ph,
  pn: flag_pn,
  pl: flag_pl,
  pt: flag_pt,
  pr: flag_pr,
  qa: flag_qa,
  cg: flag_cg,
  ro: flag_ro,
  ru: flag_ru,
  rw: flag_rw,
  re: flag_re,
  bl: flag_bl,
  sh: flag_sh,
  kn: flag_kn,
  lc: flag_lc,
  mf: flag_mf,
  pm: flag_pm,
  vc: flag_vc,
  ws: flag_ws,
  sm: flag_sm,
  st: flag_st,
  sa: flag_sa,
  sn: flag_sn,
  rs: flag_rs,
  sc: flag_sc,
  sl: flag_sl,
  sg: flag_sg,
  sx: flag_sx,
  sk: flag_sk,
  si: flag_si,
  sb: flag_sb,
  so: flag_so,
  za: flag_za,
  gs: flag_gs,
  kr: flag_kr,
  ss: flag_ss,
  es: flag_es,
  lk: flag_lk,
  ps: flag_ps,
  sd: flag_sd,
  sr: flag_sr,
  sj: flag_sj,
  se: flag_se,
  ch: flag_ch,
  sy: flag_sy,
  tw: flag_tw,
  tj: flag_tj,
  tz: flag_tz,
  th: flag_th,
  tl: flag_tl,
  tg: flag_tg,
  tk: flag_tk,
  to: flag_to,
  tt: flag_tt,
  tn: flag_tn,
  tm: flag_tm,
  tc: flag_tc,
  tv: flag_tv,
  tr: flag_tr,
  ug: flag_ug,
  ua: flag_ua,
  ae: flag_ae,
  gb: flag_gb,
  um: flag_um,
  us: flag_us,
  uy: flag_uy,
  uz: flag_uz,
  vu: flag_vu,
  ve: flag_ve,
  vn: flag_vn,
  vg: flag_vg,
  vi: flag_vi,
  wf: flag_wf,
  eh: flag_eh,
  ye: flag_ye,
  zm: flag_zm,
  zw: flag_zw,
  sh_ac: flag_sh_ac,
  es_pv: flag_es_pv,
  ic: flag_ic,
  es_ct: flag_es_ct,
  cefta: flag_cefta,
  cp: flag_cp,
  dg: flag_dg,
  eac: flag_eac,
  gb_eng: flag_gb_eng,
  eu: flag_eu,
  es_ga: flag_es_ga,
  xk: flag_xk,
  arab: flag_arab,
  gb_nir: flag_gb_nir,
  pc: flag_pc,
  sh_hl: flag_sh_hl,
  gb_sct: flag_gb_sct,
  sh_ta: flag_sh_ta,
  un: flag_un,
  xx: flag_xx,
  gb_wls: flag_gb_wls,
  english: flag_gb,
  spanish: flag_es,
};
