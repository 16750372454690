export enum VoiceTypeEnum {
  ChatGPT = 'chatGPT',
  Azure = 'azure',
  ElevenLabs = 'elevenLabs',
}

export interface Voice {
  name: string;
  displayName: string;
  type: VoiceTypeEnum;
  language: string;
  audioStyle?: string;
  cateogory?: VoiceCategoryEnum;
}

// voice category can be FREE or PRO

enum VoiceCategoryEnum {
  FREE = 'FREE',
  PRO = 'PRO',
}

export interface ChatGPTVoice extends Voice {
  type: VoiceTypeEnum.ChatGPT;
  audioStyle: 'read' | 'meditation';
}

export interface AzureVoice extends Voice {
  type: VoiceTypeEnum.Azure;
  useSSML: boolean;
}

export interface ElevenLabsVoice extends Voice {
  type: VoiceTypeEnum.ElevenLabs;
  stability: number;
  similarity_boost: number;
}

export type VoicesList = {
  // en: Voice[];
  // es: Voice[];
  [key: string]: Voice[];
  // [key in keyof typeof LanguageEnum]?: Voice[];
};

export enum VoiceType {
  Neural = 'Neural'
}

