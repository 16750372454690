import {
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL,
  TOGGLE_LOADING,
  EDIT_FEEDBACK_STATUS_SUCCESS,
  ADD_FEEDBACK_RESPONSE_SUCCESS,
  EDIT_FEEDBACK_RESPONSE_SUCCESS,
} from "./actionTypes";

import { tableDefaultState } from "../common/misc.js";

const INIT_STATE = {
  tableData: tableDefaultState,
  error: {},
  loading: false,
};

const feedback = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        tableData: action.payload,
      };

    case GET_FEEDBACK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };

    case EDIT_FEEDBACK_STATUS_SUCCESS:
      return {
        ...state,
        tableData: state.tableData.map((item) =>
          item.id === action.payload.feedbackId
            ? { ...item, status: action.payload.newStatus }
            : item
        ),
      };

    case ADD_FEEDBACK_RESPONSE_SUCCESS:
    case EDIT_FEEDBACK_RESPONSE_SUCCESS:
      return {
        ...state,
        tableData: state.tableData.map((item) =>
          item.id === action.payload.feedbackId
            ? { ...item, response: action.payload.response }
            : item
        ),
      };

    default:
      return state;
  }
};

export default feedback;
