import {
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS_FAILURE,
} from './actionTypes';

export const fetchEmailsRequest = (criteria) => ({
  type: FETCH_EMAILS_REQUEST,
  payload: criteria,
});

export const fetchEmailsSuccess = (users) => ({
  type: FETCH_EMAILS_SUCCESS,
  payload: users,
});

export const fetchEmailsFailure = (error) => ({
  type: FETCH_EMAILS_FAILURE,
  error,
});
