import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { appLanguages } from '../../common/languages';
import { langFlags } from '../../common/langFlags';
import { saveSentence } from '../../helpers/API/assistant';
import { translateText } from '../../helpers/API/content/text';

const NewSentenceBlock = ({ refreshSentences }) => {
  const [newSentence, setNewSentence] = useState({});
  const [sentenceId, setSentenceId] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const checkChanges = () => {
      for (const lang of appLanguages) {
        if (newSentence[lang]) {
          setHasChanges(true);
          return;
        }
      }
      setHasChanges(false);
    };
    checkChanges();
  }, [newSentence]);

  const handleInputChange = (e, lang) => {
    const { value } = e.target;
    setNewSentence((prevState) => ({
      ...prevState,
      [lang]: value,
    }));
  };

  const handleTranslate = async () => {
    setIsTranslating(true);
    const translations = await translateText(newSentence['en'], appLanguages);
    setNewSentence(translations);
    setIsTranslating(false);
  };

  const handleTranslateSingle = async (lang) => {
    setIsTranslating(true);
    const translations = await translateText(newSentence['en'], [lang]);
    setNewSentence((prevState) => ({
      ...prevState,
      ...translations,
    }));
    setIsTranslating(false);
  };

  const handleSave = async (lang = null) => {
    if (!sentenceId) {
      alert("Sentence ID is required.");
      return;
    }

    setLoading(true);
    setStatus(null);

    try {
      if (lang) {
        if (newSentence[lang]) {
          await saveSentence({ text: newSentence[lang] }, lang, sentenceId);
        }
      } else {
        for (const lang of appLanguages) {
          if (newSentence[lang]) {
            await saveSentence({ text: newSentence[lang] }, lang, sentenceId);
          }
        }
      }
      setNewSentence({});
      setSentenceId('');
      setStatus('Process successful!');
      refreshSentences();
    } catch (error) {
      setStatus(`Error: ${error.message}`);
    } finally {
      setLoading(false);
      setTimeout(() => setStatus(null), 5000);
    }
  };

  const isSaveAllEnabled = appLanguages.every(lang => newSentence[lang]);
  const isSaveEnabled = hasChanges;

  return (
    <div className="white-block">
      <h2>Add a sentence</h2>
      <div className="add-sentence-block">
        <Form>
          {appLanguages.map((lang) => (
            <FormGroup key={lang}>
              <div className="d-flex gap-1 align-items-center">
                <Label for={lang}>
                  <img
                    src={langFlags[lang]}
                    alt={`${lang} flag`}
                    style={{ width: '20px', marginRight: '10px' }}
                  />
                  {lang.toUpperCase()}
                </Label>
              </div>
              {lang === 'en' && (
                <Input
                  type="text"
                  id={`${lang}-id`}
                  placeholder="Enter sentence ID"
                  value={sentenceId}
                  onChange={(e) => setSentenceId(e.target.value)}
                />
              )}
              <Input
                type="textarea"
                id={lang}
                value={newSentence[lang] || ''}
                onChange={(e) => handleInputChange(e, lang)}
              />
              {lang !== 'en' && (
                <div className="btn-container">
                  <Button
                    color="secondary"
                    disabled={!newSentence['en']}
                    onClick={() => handleTranslateSingle(lang)}
                  >
                    <i className="mdi mdi-translate" style={{ marginRight: '5px' }} />
                    Translate from EN
                  </Button>
                </div>
              )}
            </FormGroup>
          ))}
        </Form>
        <div className="d-flex gap-1 justify-content-end">
          <Button
            color="secondary"
            onClick={handleTranslate}
            disabled={!newSentence['en'] || isTranslating}
          >
            <i className="mdi mdi-translate" style={{ marginRight: '5px' }} />
            Translate All from EN
          </Button>
          <Button color="primary" onClick={() => handleSave()} disabled={!isSaveEnabled || loading}>
            {loading ? <div className="spinner"></div> : <i className="fa fa-save" style={{ marginRight: '5px' }} />}
            Save
          </Button>
          <Button color="primary" onClick={() => handleSave()} disabled={!isSaveAllEnabled || loading}>
            {loading ? <div className="spinner"></div> : <i className="fa fa-save" style={{ marginRight: '5px' }} />}
            Save All
          </Button>
        </div>
        {status && (
          <div style={{ color: status.startsWith('Error') ? 'red' : 'green', marginTop: '10px' }}>
            {status}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSentenceBlock;
