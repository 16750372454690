import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const fetchEmailsFromFirestore = async (criteria) => {
  const usersRef = collection(db, 'users');
  let filters = [];

  if (criteria.purchasers) {
    filters.push(where('shop.totalRealitiesAcquired', '>', 0));
  }
  if (criteria.nonPurchasers) {
    filters.push(where('shop.totalRealitiesAcquired', '==', 0));
  }
  if (criteria.realityCreated) {
    filters.push(where('shop.totalRealitiesCreated', '>', 0));
  }
  if (criteria.nonRealityCreated) {
    filters.push(where('shop.totalRealitiesCreated', '==', 0));
  }

  const selectedLanguages = Object.keys(criteria.languages).filter(lang => criteria.languages[lang]);
  if (selectedLanguages.length > 0) {
    filters.push(where('language', 'in', selectedLanguages));
  }

  let q = query(usersRef, ...filters);
  const querySnapshot = await getDocs(q);

  const users = [];
  querySnapshot.forEach((doc) => {
    const user = doc.data();
    users.push({
      userId: doc.id,
      language: user.language,
      email: user.email,
      firstName: user.name || '',
      purchaser: user.shop.totalRealitiesAcquired > 0,
      realityCreated: user.shop.totalRealitiesCreated > 0,
    });
  });

  return users;
};
