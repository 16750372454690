import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../components/Common/Loading';
import { clearError } from '../../store/actions';
import { getUser } from '../../store/users/actions';
import { formatDate, extractTimestampSeconds } from '../../utils/dates';
import { formatNumber } from '../../utils/numbers';
import { langFlags } from '../../common/langFlags';
import { allFlags } from '../../common/allFlags';
import { getCountryCode } from '../../common/countryNames';
import { getTotalSpent } from '../../utils/money';
import Realities from './UserRealities';
import { convertPrices } from '../../helpers/API/stats';
import CopyToClipboard from '../../components/Common/CopyToClipboard';
import GoToFirebase from '../../components/Common/GoToFirebase';

const exchangeRates = {
  EUR: 1.119,
  USD: 1,
  GBP: 1.251,
  CHF: 1.0956,
  PLN: 0.2486,
  TRY: 0.02943
};

const convertToUSD = (amount, currency) => {
  if (currency === 'USD') return amount;
  const rate = exchangeRates[currency];
  return parseFloat((amount * rate).toFixed(2));
};


function capitalize(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1)?.toLowerCase();
}

function User() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, user, error } = useSelector(state => state.users);
  const [formattedData, setFormattedData] = useState({});

  useEffect(() => {
    if (!user.id || user.id !== id) {
      dispatch(getUser(id));
    }
  }, [user, id, dispatch]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setFormattedData(formatData(user));
    }
  }, [user]);

  const formatData = (data) => {
    const totalRealitiesAcquired = data.shop?.totalRealitiesAcquired || 0;
    const itemsAcquired = data.shop?.activity?.itemsAcquired || [];
    const totalSpent = getTotalSpent(itemsAcquired);

    return {
      Information: {
        id: data.id,
        email: data.email,
        name: data.name,
        gender: data.gender,
        country: data.country,
        language: data.language,
        voice: data.voice,
        provider: data.provider,
        device: data.device,
        createdAt: data.createdAt.seconds || extractTimestampSeconds(data.createdAt),
        updatedAt: data.updatedAt.seconds || extractTimestampSeconds(data.updatedAt),
        currentReality: data.currentReality,
        marketingConsented: data.emailMarketing ? 'Yes' : 'No',
      },
      Status: {
        onboarded: data.onboarded ? 'Yes' : 'No',
        freeDemoCompleted: data.freeDemoCompleted ? 'Yes' : 'No',
      },
      Shop: {
        activity: { totalRealitiesAcquired, totalSpent, itemCount: itemsAcquired.length },
        items: itemsAcquired,
        realitiesAvailable: data.shop?.realitiesAvailable || 0,
        totalRealitiesAcquired: data.shop?.totalRealitiesAcquired || 0,
        totalRealitiesCreated: data.shop?.totalRealitiesCreated || 0,
        totalUnpaidRealitiesCreated: data.shop?.totalUnpaidRealitiesCreated || 0,
      },
      Tokens: {
        tokensSpent: data.tokensSpent,
      },
      Ads: {
        adsEnabled: data.adsEnabled ? 'Yes' : 'No',
        totalAdsSeen: data.totalAdsSeen,
      },
    };
  };

  useEffect(() => {
    if (error && Object.keys(error).length > 0 && error.message) {
      setTimeout(() => {
        dispatch(clearError());
      }, 5000);
    }
  }, [error, dispatch]);

  const getCurrencyAndAmount = (price) => {
    let currency = 'USD';
    let amount = 0;

    if (typeof price === 'string') {
      if (price.includes('€')) currency = 'EUR';
      else if (price.includes('$')) currency = 'USD';
      else if (price.includes('£')) currency = 'GBP';
      else if (price.includes('₺')) currency = 'TRY';
      else if (price.includes('zł')) currency = 'PLN';

      // Strip the currency symbols to get the amount
      amount = parseFloat(price.replace(/[^0-9.-]+/g,""));
    } else if (typeof price === 'number') {
      amount = price;
    }

    const usdEquivalent = convertToUSD(amount, currency);

    return { currency, amount, usdEquivalent };
  };

  const totalSpent = formattedData.Shop?.activity?.totalSpent || 0;
  const { currency, amount, usdEquivalent } = getCurrencyAndAmount(totalSpent);

  let convertedAmount = '';
  if (currency !== 'USD') {
    const convertedPrices = convertPrices([amount]);
    if (convertedPrices.length > 0) {
      convertedAmount = `- $${formatNumber(convertedPrices[0])}`;
    }
  }

  if (error && Object.keys(error).length > 0) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || !user.id || !Object.keys(formattedData).length) {
    return <Loading />;
  }

  const UserInfo = () => {
    return (
      <div className="user-info-container">
        <div className="third-section">
          <h2>Information</h2>
          {Object.entries(formattedData.Information).map(([key, value]) => (
            <div key={key} className="data-row">
              <strong className="data-key">{key}:</strong>
              {key === 'language' || key === 'country' ? (
                <img
                  src={key === 'language' ? langFlags[value] : allFlags[getCountryCode(value)]}
                  alt={value}
                  title={value}
                  className="flag"
                />
              ) : key === 'createdAt' || key === 'updatedAt' ? (
                <p>{formatDate(value, true)}</p>
              ) : key === 'device' ? (
                value === 'android' ? (
                  <i className="mdi mdi-android" style={{ fontSize: '15px', color: 'green' }}></i>
                ) : value === 'ios' ? (
                  <i className="fas fa-apple-alt" style={{ fontSize: '19px', color: 'black' }}></i>
                ) : null
              ) : key === 'provider' ? (
                value === 'Google' ? (
                  <i className="fab fa-google" style={{ fontSize: '14px', color: '#DB4437' }}></i>
                ) : value === 'Apple' ? (
                  <i className="fas fa-apple-alt" style={{ fontSize: '14px', color: 'black' }}></i>
                ) : value === 'Email' ? (
                  <i className="fas fa-envelope" style={{ fontSize: '14px', color: '#007bff' }}></i>
                ) : null
              ) : key === 'marketingConsented' ? (
                value === 'Yes' ? (
                  <i className="fas fa-check" style={{ fontSize: '15px', color: 'green' }}></i>
                ) : value === 'No' ? (
                  <i className="fas fa-times" style={{ fontSize: '15px', color: 'red' }}></i>
                ) : null
              ) : key === 'id' || key === 'email' ? (
                <>
                  {key === 'id' && <GoToFirebase userId={value} />}
                  <CopyToClipboard textToCopy={value} />
                </>
              ) : (
                <p>{typeof value === 'object' ? JSON.stringify(value) : value}</p>
              )}
            </div>
          ))}
        </div>
        <div className="third-section">
          <h2>Shop</h2>
          {Object.entries(formattedData.Shop).map(([key, value]) => {
           if (key === 'items') {
              return (
                <div key={key} className="data-row">
                  <strong className="data-key">Items Acquired:</strong>
                  <div className="items-acquired-list">
                    {value.map((item, index) => {
                      const createdAt = new Date(item.createdAt.seconds * 1000);
                      const now = new Date();
                      const timeDifferenceInMs = now - createdAt;
                      const timeAgoInDays = Math.floor(timeDifferenceInMs / (1000 * 60 * 60 * 24));

                      let timeAgo;
                      if (timeAgoInDays === 0) {
                        const timeAgoInHours = Math.floor(timeDifferenceInMs / (1000 * 60 * 60));
                        timeAgo = `${timeAgoInHours} hours ago`;
                      } else {
                        timeAgo = `${timeAgoInDays} days ago`;
                      }

                      return (
                        <div key={index} className="item-acquired">
                          <p>{item.product.identifier} - {timeAgo}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            } else if (key !== 'activity') {
              return (
                <div key={key} className="data-row">
                  <strong className="data-key">{capitalize(key)}:</strong>
                  <p>{typeof value === 'object' ? JSON.stringify(value) : value}</p>
                </div>
              );
            } else {
              return null;
            }
          })}
          <div className="data-row">
            <strong className="data-key">Total Realities Acquired:</strong>
            <p className={formattedData.Shop.activity.totalRealitiesAcquired > 0 ? 'green-badge' : ''}>{formattedData.Shop.activity.totalRealitiesAcquired}</p>
          </div>
          <div className="data-row">
            <strong className="data-key">Total Spent:</strong>
            <p className="green-badge">
              {totalSpent} {currency !== 'USD' && ` - $${formatNumber(usdEquivalent)}`}
            </p>
          </div>
          <div className="data-row">
            <strong className="data-key">Item Count:</strong>
            <p>{formattedData.Shop.activity.itemCount}</p>
          </div>
        </div>
        <Realities user={user} />
      </div>
    );
  };

  return (
    <div className="page-content">
      <UserInfo />
    </div>
  );
}

export default User;

