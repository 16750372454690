import flag_en from '../assets/images/flags/en.svg';
import flag_es from '../assets/images/flags/es.svg';
import flag_fr from '../assets/images/flags/fr.svg';
import flag_de from '../assets/images/flags/de.svg';
import flag_pt from '../assets/images/flags/pt.svg';
import flag_ca from '../assets/images/flags/ca.png';
import flag_zh from '../assets/images/flags/zh.svg';
import flag_ja from '../assets/images/flags/ja.svg';
import flag_it from '../assets/images/flags/it.svg';
import flag_ru from '../assets/images/flags/ru.svg';
import flag_tr from '../assets/images/flags/tr.svg';
import flag_nl from '../assets/images/flags/nl.svg';
import flag_da from '../assets/images/flags/da.svg';
import flag_nn from '../assets/images/flags/nn.svg';
import flag_fi from '../assets/images/flags/fi.svg';
import flag_pl from '../assets/images/flags/pl.svg';
import flag_ar from '../assets/images/flags/ar.svg';
import flag_sv from '../assets/images/flags/sv.svg';

export const langFlags = {
  en: flag_en,
  es: flag_es,
  fr: flag_fr,
  de: flag_de,
  pt: flag_pt,
  ca: flag_ca,
  zh: flag_zh,
  ja: flag_ja,
  it: flag_it,
  ru: flag_ru,
  tr: flag_tr,
  nl: flag_nl,
  da: flag_da,
  nn: flag_nn,
  fi: flag_fi,
  pl: flag_pl,
  ar: flag_ar,
  sv: flag_sv
};