import {
  getDoc,
  doc,
  getDocs,
  getCountFromServer,
  query,
  where,
  collection,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebase';

// const defaultReturn = {
//   data: [],
//   pageSize: 100,
//   pageIndex: 0,
//   sortBy: [],
//   filters: [],
//   totalPages: 0,
//   totalItems: 0,
// };

export const createListQuery = async (collectionName, {
  pageSize = 100,
  pageIndex = 0,
  filters = [],
  sortBy = [],
  lastVisible = null
} = {}) => {
  let queryConstraints = [];

  // Apply filters
  filters.forEach(filter => {
    const fieldPath = filter._field.segments[0];
    const fieldValue = filter._value;
    let filterFormatted = filter;

    if (fieldPath === 'email' || fieldPath === 'name' || fieldPath === 'id') {
      filterFormatted = where(fieldPath, 'array-contains', fieldValue);
    }
    console.log('filter', filter);
    queryConstraints.push(filterFormatted);
  });

  // Apply sorting
  if (sortBy.length > 0) {
    sortBy.forEach(sort => {
      queryConstraints.push(orderBy(sort.field, sort.direction));
    });
  } else if (filters.length > 0) {
    // If there's a filter, add an orderBy for the filtered field
    filters.forEach(filter => {
      const fieldPath = filter._field.segments.join('.');
      queryConstraints.push(orderBy(fieldPath));
    });
  } else {
    // Default sorting
    queryConstraints.push(orderBy('createdAt', 'desc'));
  }

  console.log('queryConstraints', queryConstraints);
  // Apply pagination
  queryConstraints.push(limit(pageSize));

  if (pageIndex > 0 && lastVisible) {
    queryConstraints.push(startAfter(lastVisible));
  }

  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef, ...queryConstraints);
  let countQuery = collectionRef;

  if (filters.length || sortBy.length) {
    // Remove the page size and start after constraints
    countQuery = query(countQuery, ...queryConstraints.slice(0, -2));
  }
  const snapshotCount = await getCountFromServer(countQuery);
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    console.log('No matching documents.');
    return { data: [] };
  }
  const totalItems = snapshotCount.data().count;
  const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  if (sortBy.length === 0) {
    data.sort((a, b) => b.createdAt - a.createdAt);
  }
  lastVisible = snapshot.docs[snapshot.docs.length - 1] || null;

  return {
    data,
    lastVisible,
    pageSize,
    pageIndex,
    sortBy,
    filters,
    totalPages: Math.ceil(totalItems / pageSize),
    totalItems,
  };
};