export const formatNumber = (number: number): string => {
  return Number(number).toLocaleString();
};

export const formatCurrency = (amount: number): string => {
  return `US$${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const estimateSpend = (tokens: number): string => {
  const pricePerMillionTokens = 5.00; // USD price for 1 million tokens, chatGPT API
  const spend = (tokens / 1_000_000) * pricePerMillionTokens;

  return formatCurrency(spend);
}
