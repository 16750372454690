import React, { useEffect, useState, useCallback } from 'react';
import Voices from './Voices';
import ContentBlock from './ContentTable';
import AddNewLanguage from './AddNewLanguage';
import LocalButton from '../../components/Common/Button';
import { getVoicesList } from '../../helpers/API/content/voices';
import { appLanguages } from '../../common/languages';

const Content = () => {
  const [addingLanguage, setAddingLanguage] = useState(false);
  const [voices, setVoices] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const fetchVoices = useCallback(async (lang) => {
    setIsLoading(prev => ({ ...prev, [lang]: true }));
    const data = await getVoicesList(lang);
    setVoices(prev => ({ ...prev, [lang]: data }));
    setIsLoading(prev => ({ ...prev, [lang]: false }));
  }, []);

  useEffect(() => {
    appLanguages.forEach(lang => {
      fetchVoices(lang);
    });
  }, [fetchVoices]);

  return (
    <div className="page-content" style={{ marginTop: 105 }}>
      <LocalButton
        text="Add Language"
        icon="bx bx-plus"
        extraClass={`addLanguage ${addingLanguage ? 'active' : ''}`}
        onClick={() => setAddingLanguage(!addingLanguage)}
      />
      {addingLanguage && <AddNewLanguage />}
      <div className="flex-row">
        <Voices isLoading={isLoading} voices={voices} fetchVoices={fetchVoices} />
        <ContentBlock voices={voices} />
      </div>
    </div>
  );
};

export default Content;
