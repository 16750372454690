import {
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL,
  TOGGLE_LOADING,
  EDIT_FEEDBACK_STATUS,
  EDIT_FEEDBACK_STATUS_SUCCESS,
  EDIT_FEEDBACK_STATUS_FAIL,
  ADD_FEEDBACK_RESPONSE,
  ADD_FEEDBACK_RESPONSE_SUCCESS,
  ADD_FEEDBACK_RESPONSE_FAIL,
  EDIT_FEEDBACK_RESPONSE,
  EDIT_FEEDBACK_RESPONSE_SUCCESS,
  EDIT_FEEDBACK_RESPONSE_FAIL,
} from "./actionTypes";

export const getFeedback = () => ({
  type: GET_FEEDBACK,
});

export const getFeedbackSuccess = (data) => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: data,
});

export const getFeedbackFail = (error) => ({
  type: GET_FEEDBACK_FAIL,
  payload: error,
});

export const toggleFeedbackLoading = () => ({
  type: TOGGLE_LOADING,
});

export const editFeedbackStatus = (feedbackId, newStatus) => ({
  type: EDIT_FEEDBACK_STATUS,
  payload: { feedbackId, newStatus },
});

export const editFeedbackStatusSuccess = (feedbackId, newStatus) => ({
  type: EDIT_FEEDBACK_STATUS_SUCCESS,
  payload: { feedbackId, newStatus },
});

export const editFeedbackStatusFail = (error) => ({
  type: EDIT_FEEDBACK_STATUS_FAIL,
  payload: error,
});

export const addFeedbackResponse = (feedbackId, response) => ({
  type: ADD_FEEDBACK_RESPONSE,
  payload: { feedbackId, response },
});

export const addFeedbackResponseSuccess = (feedbackId, response) => ({
  type: ADD_FEEDBACK_RESPONSE_SUCCESS,
  payload: { feedbackId, response },
});

export const addFeedbackResponseFail = (error) => ({
  type: ADD_FEEDBACK_RESPONSE_FAIL,
  payload: error,
});

export const editFeedbackResponse = (feedbackId, response) => ({
  type: EDIT_FEEDBACK_RESPONSE,
  payload: { feedbackId, response },
});

export const editFeedbackResponseSuccess = (feedbackId, response) => ({
  type: EDIT_FEEDBACK_RESPONSE_SUCCESS,
  payload: { feedbackId, response },
});

export const editFeedbackResponseFail = (error) => ({
  type: EDIT_FEEDBACK_RESPONSE_FAIL,
  payload: error,
});
